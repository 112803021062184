import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {Formulary} from '../../model/Formulary';
import {FormularyService} from '../../service/formulary/formulary.service';
import {AppService} from '../../service/app/app.service';
import {take} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {dateFormatter} from '../../utility/utility';
import {MatButtonToggle} from '@angular/material/button-toggle';
import {MatAccordion} from '@angular/material/expansion';
import {ConfigurationService} from '../../service/configuration/configuration.service';
import {Subscription, throwError} from 'rxjs';
import {CVSBannerService, CVSBannerType} from 'angular-component-library';
import {CLIENT_CONFIGURATION_STATUS} from '../../enum/ClientConfigurationStatus';
import {ClientFormularyMapping} from '../../model/ClientFormularyMapping';
import {FORMULARY_YEAR_TYPE} from '../../enum/FormularyYearType';
import {MatSelectChange} from '@angular/material/select';

@Component({
    selector: 'app-formulary-setup',
    templateUrl: './formulary-setup.component.html',
    styleUrls: ['./formulary-setup.component.scss']
})

export class FormularySetupComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild(MatAccordion) accordion: MatAccordion;
    @ViewChild('toggleButton') toggleButton: MatButtonToggle;
    @Output() isSaveSuccessful = new EventEmitter<boolean>();
    @Output() isDirty = new EventEmitter<boolean>();
    @Output() lobDescription = new EventEmitter<String>();
    @Input() currentStepFromParent;
    viewFormularyToggleText = 'View Formulary in CLR';
    hideFormularyToggleText = 'Hide Formulary in CLR';
    formularies: Formulary[];
    formGroups: { [key: string]: FormGroup } = {};
    panelOpenState = true;
    loadingPanels = false;
    changedFormgroupKeys = new Set<string>();
    subscriptions: Subscription[] = [];
    errorHeaderText = 'Error: Changes have not been saved due to incomplete changes';
    errorMessage = 'All errors must be fixed and saved before moving to the next step.';
    clientFormularyMapping: ClientFormularyMapping[];
    errorFormgroupKeys = new Set<string>();
    formularyYearTypeArr = [
        {code: 0, description: 'Rolling Year Formulary'},
        {code: 1, description: 'Annual Lists'},
        {code: 2, description: 'Single Year'}
    ];

    protected readonly dateFormatter = dateFormatter;

    constructor(private _formularyService: FormularyService,
        private _appService: AppService,
        private _formBuilder: FormBuilder,
        private _configurationService: ConfigurationService,
        private _bannerService: CVSBannerService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentStepFromParent.currentValue === 0) {
            this.populateFormularySetupDataAndCreateControls();
        }
    }

    public populateFormularySetupDataAndCreateControls() {
        this.loadingPanels = true;
        const index = this._appService.getSuperClientContext().id;
        this._formularyService.getFormularyData(index, true).pipe(take(1)).subscribe(
            {
                next: (formularies: Formulary[]) => {
                    this.formularies = formularies;
                    this.loadingPanels = false;
                    this.createFormGroups();
                    this.getAllClientConfigurations(index);
                }, error: (error) => {
                    this.loadingPanels = false;
                }
            });
    }

    ngOnInit(): void {

    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    concatLobDescriptions(formulary: Formulary): string {
        return formulary.linesOfBusiness?.map(lob => {
            if (lob.description === 'Medicare') {
                return lob?.subLob?.description;
            }
            return lob.description;
        }).join(', ');
    }

    createFormGroups(): void {
        if (Object.keys(this.formGroups).length === 0) {
            this.formularies.forEach(formulary => {
                const thruDate: number = +formulary.thruDate.toString().slice(0,4);
                const fromDate: number = +formulary.fromDate.toString().slice(0,4);
                if(thruDate === fromDate){
                    this.formGroups[formulary.formularyNumber] = this._formBuilder.group({
                        [`formularyName`]: new FormControl(formulary.name, [Validators.required, Validators.maxLength(50)]),
                        [`formularyYear`]: new FormControl(thruDate, [Validators.pattern(/^(199[5-9]|20[0-3][0-9])$/)]),
                        [`formularyGroup`]: new FormControl('', [Validators.maxLength(50)]),
                        [`showFormulary`]: new FormControl('', []),
                        [`businessLobCode`]: new FormControl(this.getBusinessLobCodeFromMap(formulary.linesOfBusiness[0].code), []),
                        [`formularyYearType`]: new FormControl( this.formularyYearTypeArr[2].code, []),
                    });
                } else {
                    this.formGroups[formulary.formularyNumber] = this._formBuilder.group({
                        [`formularyName`]: new FormControl(formulary.name, [Validators.required, Validators.maxLength(50)]),
                        [`formularyYear`]: new FormControl({
                            value: '',
                            disabled: true
                        }, [Validators.pattern(/^(199[5-9]|20[0-3][0-9])$/)]),
                        [`formularyGroup`]: new FormControl('', [Validators.maxLength(50)]),
                        [`showFormulary`]: new FormControl('', []),
                        [`businessLobCode`]: new FormControl(this.getBusinessLobCodeFromMap(formulary.linesOfBusiness[0].code), []),
                        [`formularyYearType`]: new FormControl( this.formularyYearTypeArr[0].code, []),
                    });
                }

            });
        }
    }

    public getBusinessLobCodeFromMap(code: string): string {
        const blobCd = Object.keys(this._formularyService.businessLobToFormularyLobMap).find(k =>
            this._formularyService.businessLobToFormularyLobMap[k].includes(code));
        return blobCd;
    }

    subscribeToFormChanges(): void {
        this.errorFormgroupKeys = new Set<string>();
        this.changedFormgroupKeys = new Set<string>();
        Object.keys(this.formGroups).forEach(formGroup => {
            this.formGroups[formGroup].valueChanges.subscribe(changes => {
                this._configurationService.setIsFormularyConfigModified(true);
                if (this.formGroups[formGroup].valid) {
                    this.changedFormgroupKeys.add(formGroup);
                } else {
                    this.errorFormgroupKeys.add(formGroup);
                }
            });
        });
    }

    saveClientConfigurations() {
        let isFormValid = '';
        Array.from(this.changedFormgroupKeys).every(formularyNumber => {
            isFormValid = this.formGroups[formularyNumber].status;
        });
        const isErrorFormgroupKeysUpdatedToValid = Array.from(this.errorFormgroupKeys).every(formularyNumber =>
            this.formGroups[formularyNumber].valid);
        const configurations: ClientFormularyMapping[] = [];
        if (this.changedFormgroupKeys.size > 0 && isFormValid === 'VALID') {
            this.changedFormgroupKeys.forEach(key => {
                const formulary = this.formGroups[key].value;
                formulary.formularyId = key;
                formulary.showFormulary = formulary.showFormulary ? 'Y' : 'N';
                formulary.clientConfigurationStatus = CLIENT_CONFIGURATION_STATUS.DRAFT;
                configurations.push(formulary);
            });
            const index = this._appService.getSuperClientContext().id;

            this._configurationService.saveClientFormularies(configurations, index).pipe(take(1)).subscribe(() => {
                this._bannerService.sendAlert({
                    bannerType: CVSBannerType.Success,
                    headline: 'Success',
                    body: 'Changes have been saved.',
                    outletId: '#successBanner',
                });
                this.changedFormgroupKeys.clear();
                this._configurationService.setIsFormularyConfigModified(false);
                this.isSaveSuccessful.emit(true);
            });
        }
        if (this.errorFormgroupKeys.size !== 0 && !isErrorFormgroupKeysUpdatedToValid) {
            this._bannerService.sendAlert({
                bannerType: CVSBannerType.Error,
                headline: this.errorHeaderText,
                body: this.errorMessage,
                outletId: '#errorBanner'
            });
        }
        if (this.errorFormgroupKeys.size === 0 && this.changedFormgroupKeys.size === 0) {
            this.isSaveSuccessful.emit(true);
        }
    }

    getAllClientConfigurations(superClientIndex: number) {
        this._configurationService.getClientFormularies(superClientIndex).pipe(take(1)).subscribe(data => {
            data?.forEach(item => {
                Object.keys(this.formGroups).forEach(formGroup => {
                    if (formGroup === item.formularyId) {
                        if (item.formularyName) {
                            this.formGroups[formGroup].controls.formularyName.patchValue(item.formularyName);
                        }
                        if (item.formularyYear) {
                            this.formGroups[formGroup].controls.formularyYear.patchValue(item.formularyYear);
                            this.formGroups[formGroup].controls.formularyYear.addValidators(Validators.required);
                            this.formGroups[formGroup].controls.formularyYearType.patchValue(item.formularyYearType);
                            this.formGroups[formGroup].controls.formularyYear.enable();
                        } else {
                            this.formGroups[formGroup].controls.formularyYear.disable();
                            this.formGroups[formGroup].controls.formularyYearType.patchValue(item.formularyYearType);
                        }
                        if (item.formularyGroup) {
                            this.formGroups[formGroup].controls.formularyGroup.patchValue(item.formularyGroup);
                        }
                        if (item.showFormulary) {
                            this.formGroups[formGroup].controls.showFormulary.patchValue(item.showFormulary === 'Y');
                        }
                    }
                });
            });
            this.clientFormularyMapping = data;
            this.subscribeToFormChanges();
        });
    }

    updateForm(formulary: Formulary) {
        this.isDirty.emit(true);
        this.lobDescription.emit(formulary.linesOfBusiness[0].description);
    }

    onFormularyYearTypeChange(event: MatSelectChange, formGroup: FormGroup<any>) {
        if (event.value === 2) {
            formGroup.get('formularyYear')?.enable();
            formGroup.controls.formularyYear.patchValue('');
            formGroup.controls.formularyYear.addValidators(Validators.required);
        } else {
            formGroup.get('formularyYear')?.disable();
            formGroup.controls.formularyYear.patchValue('N/A');
            formGroup.controls.formularyYear.removeValidators(Validators.required);
        }
        formGroup.controls.formularyYear.updateValueAndValidity();
    }

}

import {Injectable} from '@angular/core';
import {ClientFormularyMapping} from '../../model/ClientFormularyMapping';
import {Observable, Subject} from 'rxjs';
import {ENVIRONMENT} from '../../environments/environment';
import {CVSHttpClient} from 'angular-component-library';
import {ClientLobWorkflow} from '../../model/ClientLobWorkflow';
import {ClientUmProgram} from '../../model/ClientUmProgram';
import {ClientProductDrugCoverage} from '../../model/ClientProductDrugCoverage';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    private _isFormularyConfigModified = false;
    private _isLobConfigModified = false;
    private _currentStepSubject = new Subject<number>();

    constructor(private cvsHttpClient: CVSHttpClient) {
    }

    setIsFormularyConfigModified(val: boolean) {
        this._isFormularyConfigModified = val;
    }

    isFormularyConfigModified(): boolean {
        return this._isFormularyConfigModified;
    }

    setIsLobConfigModified(val: boolean) {
        this._isLobConfigModified = val;
    }

    isLobConfigModified(): boolean {
        return this._isLobConfigModified;
    }

    isClientConfigurationModified(): boolean {
        return this._isFormularyConfigModified || this._isLobConfigModified;
    }

    getCurrentStepSubject(): Subject<number> {
        return this._currentStepSubject;
    }

    saveClientFormularies(clientFormularyMapping: ClientFormularyMapping[], superClientIndex: number): Observable<any> {
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/saveClientFormularyMappings/${superClientIndex}`,
            clientFormularyMapping);
    }

    getClientFormularies(superClientIndex: number): Observable<ClientFormularyMapping[]> {
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/getClientFormularyMappings/${superClientIndex}`);
    }

    saveClientLobWorkflows(clientLobWorkflows: ClientLobWorkflow[], superClientIndex: number): Observable<any> {
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/saveLobWorkflows/${superClientIndex}`,
            clientLobWorkflows);
    }

    getClientLobWorkflows(superClientIndex: number): Observable<ClientLobWorkflow[]> {
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/getLobWorkflows/${superClientIndex}`);
    }

    submitClientSetUpRequest(superClientIndex: number, comment: { comment: string }): Observable<any[]> {
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/submitClientSetup/${superClientIndex}`, comment);
    }

    saveClientProductDrugCoverage(clientProductDrugCoverage: ClientProductDrugCoverage, superClientIndex: number): Observable<any> {
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/saveProductDrugCoverage/${superClientIndex}`,
            clientProductDrugCoverage);
    }

    getClientProductDrugCoverage(superClientIndex: number): Observable<ClientProductDrugCoverage[]> {
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/getProductDrugCoverage/${superClientIndex}`);
    }

    saveClientUmProgram(clientUmProgram: ClientUmProgram, superClientIndex: number): Observable<any> {
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/saveUmProgram/${superClientIndex}`,
            clientUmProgram);
    }

    getClientUmProgram(superClientIndex: number): Observable<ClientUmProgram[]> {
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/getUmProgram/${superClientIndex}`);
    }
}

import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {NumberInput} from '@angular/cdk/coercion';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ColDef, GridOptions} from '@ag-grid-community/core';
import {KabobCellComponent} from '../../common/kabob-cell/kabob-cell.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    CVSConfirmationDialogContentComponent
} from 'angular-component-library';
import {take} from 'rxjs/operators';
import {AppService} from '../../service/app/app.service';
import {ConfigurationService} from '../../service/configuration/configuration.service';
import {ClientUmProgram} from '../../model/ClientUmProgram';
import {dateFormatter} from '../../utility/utility';
import {ClientProductDrugCoverage} from '../../model/ClientProductDrugCoverage';
import {PRODUCT_DRUG_COVERAGE_TYPE, UM_PROGRAM_TYPE} from '../../default-values/DefaultValues';
@Component({
    selector: 'app-product-drug-requirements',
    templateUrl: './product-drug-requirements.component.html',
    styleUrls: ['./product-drug-requirements.component.scss']
})
export class ProductDrugRequirementsComponent implements OnInit {
    @ViewChild('productDrugCoverageFormRef') public productDrugCoverageFormRef: TemplateRef<any>;
    @ViewChild('umProgramFormRef') public umProgramFormRef: TemplateRef<any>;
    confirmationDialog: MatDialogRef<CVSConfirmationDialogContentComponent, any>;

    selectedIndex: NumberInput;
    index: number;
    productDrugCoverageRowData: ClientProductDrugCoverage[];
    clientUmProgramRowData: ClientUmProgram[];
    coverageTypes = PRODUCT_DRUG_COVERAGE_TYPE;
    programTypes = UM_PROGRAM_TYPE;

    modules = [SetFilterModule, ColumnsToolPanelModule, ClientSideRowModelModule,
        MenuModule, ClipboardModule];
    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
    };

    umProgramGridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: [
            {headerName: 'Action', field: 'kabob', cellRenderer: KabobCellComponent, pinned: 'left', width: 100},
            {headerName: 'UM Program Type', field: '', filter: 'agSetColumnFilter', pinned: 'left', width: 200,
                valueGetter: (params) => this.getUmProgramType(params)},
            {headerName: 'UM Program Name', field: '', filter: 'agSetColumnFilter', width: 200,
                valueGetter: (params) => params.data?.umProgramName},
            {headerName: 'UM Program Description', field: '', filter: 'agSetColumnFilter', width: 220,
                valueGetter: (params) => params.data?.umProgramDescription},
            {headerName: 'UM Program View Date Range', field: '', filter: 'agSetColumnFilter', width: 220,
                valueFormatter: params => dateFormatter(params.data?.umProgramViewStartDate)
                    .concat(' - ').concat(dateFormatter(params.data?.umProgramViewEndDate))},
            {headerName: 'UM Program Effective Date Range', field: '', filter: 'agSetColumnFilter', width: 220,
                valueFormatter: params => dateFormatter(params.data?.umProgramEffectiveStartDate)
                    .concat(' - ').concat(dateFormatter(params.data?.umProgramEffectiveEndDate))},
            {headerName: 'Default Custom Rejection Message', field: '', filter: 'agSetColumnFilter', width: 220,
                valueGetter: (params) => params.data?.rejectionMessage},
        ],
        enableCellTextSelection: true,
        suppressLoadingOverlay: true,
    };

    productAndDrugCoverageGridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: [
            {headerName: 'Action', field: 'kabob', cellRenderer: KabobCellComponent, pinned: 'left', width: 100},
            {headerName: 'Product & Drug Coverage Type', field: '', filter: 'agSetColumnFilter', pinned: 'left', width: 200,
                valueGetter: (params) => this.getProductDrugCoverageType(params)},
            {headerName: 'Product & Drug Coverage Name', field: '', filter: 'agSetColumnFilter', width: 200,
                valueGetter: (params) => params.data?.productDrugCoverageName},
            {headerName: 'Product & Drug Coverage Description', field: '', filter: 'agSetColumnFilter', width: 220,
                valueGetter: (params) => params.data?.productDrugCoverageDescription},
            {headerName: 'Product & Drug Coverage View Date Range', field: '', filter: 'agSetColumnFilter', width: 220,
                valueFormatter: params => dateFormatter(params.data?.productDrugCoverageViewStartDate)
                    .concat(' - ').concat(dateFormatter(params.data?.productDrugCoverageViewEndDate))},
            {headerName: 'Product & Drug Coverage Effective Date Range', field: '', filter: 'agSetColumnFilter', width: 220,
                valueFormatter: params => dateFormatter(params.data?.productDrugCoverageEffectiveStartDate)
                    .concat(' - ').concat(dateFormatter(params.data?.productDrugCoverageEffectiveEndDate))},
            {headerName: 'Default Custom Rejection Message', field: '', filter: 'agSetColumnFilter', width: 220,
                valueGetter: (params) => params.data?.rejectionMessage},
        ],
        enableCellTextSelection: true,
        suppressLoadingOverlay: true,
    };

    overlayNoRowsTemplate = `<span><img src="/assets/error-f--s.svg" width="25px" height="25px"></br><b>No results found.</b></span>`;
    popupParent: HTMLElement | null = document.querySelector('body');

    constructor(
        private matDialog: MatDialog,
        private _appService: AppService,
        private _configurationService: ConfigurationService,
    ) {}

    ngOnInit(): void {
        this.selectedIndex = 0;
        this.index = this._appService.getSuperClientContext().id;
        this.getClientUmProgramData(this.index);
        this.getClientProductDrugCoverageData(this.index);
    }

    onBusinessLobTabSelectionChange() {
        this.selectedIndex = 0;
    }

    onSecondaryTabChange(event: MatTabChangeEvent) {
        this.selectedIndex = event.index;
    }

    openDialog(modalRef: TemplateRef<any>) {
        this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: this.selectedIndex===0 ? 'Create UM Program' : 'Create Product or Drug Coverage',
                templatePortalContent: modalRef
            },
            disableClose: true,
        });

        this.confirmationDialog.componentInstance.onCancelClick.subscribe(() => {
            this.confirmationDialog.close();
        });
    }

    onModalCancel() {
        this.confirmationDialog.close();
    }

    onModalSubmit() {
        if (this.selectedIndex === 0) {
            this.confirmationDialog.close();
            this.getClientUmProgramData(this.index);
        } else {
            this.confirmationDialog.close();
            this.getClientProductDrugCoverageData(this.index);
        }
    }

    getClientUmProgramData(superClientIndex: number) {
        this._configurationService.getClientUmProgram(superClientIndex).pipe(take(1)).subscribe(data => {
            this.clientUmProgramRowData = data;
        });
    }

    getClientProductDrugCoverageData(superClientIndex: number) {
        this._configurationService.getClientProductDrugCoverage(superClientIndex).pipe(take(1)).subscribe(data => {
            this.productDrugCoverageRowData = data;
        });
    }

    getUmProgramType(params) {
        return this.programTypes[this.programTypes.findIndex(i => i.code === params.data.umProgramTypeCd)].description;
    }

    getProductDrugCoverageType(params) {
        return this.coverageTypes[this.coverageTypes.findIndex(i => i.code === params.data.productDrugCoverageTypeCd)].description;
    }
}

import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ICellEditorAngularComp} from '@ag-grid-community/angular';
import {ICellEditorParams, ICellRenderer, ICellRendererParams} from '@ag-grid-community/core';
import {FormControl} from '@angular/forms';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatSelect} from '@angular/material/select';
import {DefaultCodeDescription} from '../../default-values/DefaultValues';

@Component({
    selector: 'app-custom-multiselect-dropdown',
    templateUrl: './custom-multiselect-dropdown.component.html',
    styleUrls: ['./custom-multiselect-dropdown.component.scss']
})
export class CustomMultiselectDropdownComponent implements OnInit, ICellEditorAngularComp, ICellRenderer {

    @ViewChild('masterCheckbox') masterCheckbox: MatCheckbox;
    @ViewChild('customMultiSelect') customMultiSelect: MatSelect;
    params;
    selectedValue;
    indeterminate;
    listValues;
    customMultiSelectFC: FormControl;

    constructor() {
    }

    ngAfterViewInit() {
        window.setTimeout(() => {
            if (this.customMultiSelect.focus) {
                this.customMultiSelect.focus();
            }
        });
    }

    ngOnInit(): void {
        this.customMultiSelectFC = new FormControl();
    }

    agInit(params: ICellEditorParams & { listValues?: DefaultCodeDescription[] }): void {
        this.params = params;
        this.listValues = params.listValues.map(v => v.code);
        this.selectedValue = params.value;
    }

    getValue(): any {
        this.params = this.selectedValue;
        return this.params;
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }

    setIndeterminate() {
        let selectedList = 0;
        selectedList = this.customMultiSelect.options.filter(opt => opt.selected).length;
        this.indeterminate = (selectedList > 0 && selectedList < this.listValues.length) ? true : false;
    }

    toggleAllSelection() {
        if (this.masterCheckbox.checked) {
            this.customMultiSelectFC.patchValue([...this.listValues]);
        } else {
            this.customMultiSelectFC.patchValue([]);
        }
    }


    toggleMatOption() {
        this.setIndeterminate();
        let selectedList = 0;
        selectedList = this.customMultiSelect.options.filter(opt => opt.selected).length;
        if ((!this.masterCheckbox.checked && selectedList === this.listValues.length) ||
            (this.masterCheckbox.checked && selectedList < this.listValues.length)) {
            this.masterCheckbox.toggle();
        }
    }
}

import {Component} from '@angular/core';
import {IAfterGuiAttachedParams, ICellRendererParams} from '@ag-grid-community/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';

@Component({
    selector: 'app-grid-select-cell-renderer',
    templateUrl: './grid-select-cell-renderer.component.html',
    styleUrls: ['./grid-select-cell-renderer.component.scss']
})
export class GridSelectCellRendererComponent implements ICellRendererAngularComp {
    value: string;

    constructor() {
    }

    agInit(params: ICellRendererParams): void {
        params.eGridCell.className += ' selectCaret';
        this.value = params.value ? params.value : '';
    }

    refresh(params: ICellRendererParams): boolean {
        return true;
    }

    afterGuiAttached(params?: IAfterGuiAttachedParams): void {
    }
}

import {Injectable, OnDestroy, TemplateRef} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {WorkflowMessage} from '../../model/workflowMessage';
import {RecordService} from '../record/record.service';
import {UserService} from '../user/user.service';
import {take} from 'rxjs/operators';
import {IRecordStatusWithDesc, lookupRecordStatusByDescription, RECORD_STATUS, RECORD_STATUSES} from '../../enum/RecordStatus';
import _ from 'lodash';
import {BannerLink, CVSAlertType, CVSBannerService, CVSBannerType, CVSConfirmationDialogContentComponent} from 'angular-component-library';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {WORKFLOW_MESSAGE_ACTION} from '../../enum/WorkflowMessageAction';
import {TrackerDataService} from '../tracker-data/tracker-data.service';
import {GridOptions, RowNode} from '@ag-grid-community/core';
import {CVSBannerComponentData} from 'angular-component-library/completed/banner/banner.interfaces';
import {NavigationBehaviorOptions, Router} from '@angular/router';
import {NOT_APPLICABLE} from '../../default-values/DefaultValues';
import { WORKFLOW_TABS } from 'src/app/workflow/WorkflowTab';

export interface IWorkflowTab {
    name: string;
    link: string;
    status?: RECORD_STATUS;
    chipValue?: string;
    subTabs?: IWorkflowTab[];
    subTabIndex?: number;
}

export interface DialogData {
    selectedRows: RowNode[];
    item: IRecordStatusWithDesc;
    showForwardStatusDetails: boolean;
    approvalNoteLabel: string;
    approvalDateLabel: string;
    actionLabel: string;
}

export interface DialogDataSubmission {
    selectedRows: RowNode[];
}

interface IMoveFromStatus {
    status: RECORD_STATUS;
    dateApprovalLabel: string;
    noteApprovalLabel: string;
    approvalDate?: Date;
    approvalNotes?: string;
}

@Injectable({
    providedIn: 'root'
})
export class WorkflowService implements OnDestroy {

    currentStatus: string;
    messageProvider = new Subject<WorkflowMessage>();
    statusCount = {};
    statusCountProvider = new Subject<any>();
    modalRecords: IRecordStatusWithDesc[];

    moveInWorkflowModalRef: MatDialogRef<CVSConfirmationDialogContentComponent, any>;
    approveAndSubmitModalRef: MatDialogRef<CVSConfirmationDialogContentComponent, any>;
    subscriptions: Subscription[] = [];
    approvalDetails: { approvalDate: Date; approvalNotes: string } = {approvalDate: new Date(), approvalNotes: undefined};
    dialogData!: DialogData;
    dialogDataSubmission!: DialogDataSubmission;
    selectedRows;
    item;
    mainTabSelection;
    subTabIndex;
    callbackOnConfirm;

    constructor(private dialog: MatDialog,
        private recordService: RecordService,
        private userService: UserService,
        private trackerDataService: TrackerDataService,
        private bannerService: CVSBannerService,
        private router: Router) {
    }

    initStatusCount() {
        this.recordService.getRecordStatusCount(this.userService.getSuperClientContext().id).pipe(take(1))
            .subscribe(statusCount => {
                this.statusCount = statusCount;
                this.statusCountProvider.next(this.statusCount);
            });
    }

    getMessageProvider(): Observable<WorkflowMessage> {
        return this.messageProvider;
    }
    adjustStatusCount(newStatus: number, oldStatus: number, count: number) {
        this.statusCount[newStatus] += count;
        this.statusCount[oldStatus] -= count;
        this.statusCountProvider.next(this.statusCount);
    }

    buildModalRecords(mainTabSelection: string, subTabIndex: number): IRecordStatusWithDesc[] {
        const currentTab = _.find(WORKFLOW_TABS, {name: mainTabSelection});
        let records = null;
        if (currentTab) {
            const recordStatuses = _.cloneDeep(RECORD_STATUSES);
            recordStatuses.forEach(i => i.selected = false);
            records = recordStatuses.filter(i => i.status !== RECORD_STATUS.DEFAULT);
            let nextStatus;
            if (currentTab === WORKFLOW_TABS.TAB4NO_ACTION || currentTab === WORKFLOW_TABS.TAB5ARCHIVE ||
                (currentTab === WORKFLOW_TABS.TAB3OPERATIONS_AND_IMPLEMENTATION &&
                    subTabIndex === WORKFLOW_TABS.TAB3OPERATIONS_AND_IMPLEMENTATION.subTabs.length - 1)) {
                nextStatus = RECORD_STATUSES.find(f => f.status === RECORD_STATUS.IN_RESEARCH);
            } else {
                nextStatus = recordStatuses[recordStatuses.map(f => f.status)
                    .findIndex(i => i === _.find(WORKFLOW_TABS, {name: mainTabSelection}).subTabs[subTabIndex].status) + 1];
            }
            const nextTab = _.flatMapDeep(WORKFLOW_TABS, 'subTabs').concat(_.filter(WORKFLOW_TABS, 'status'))
                .find(f => f.status === nextStatus.status);
            if (currentTab === WORKFLOW_TABS.TAB2APPROVAL_TRACKING  && subTabIndex === 3) {
                records = records.filter( i => i.status !== RECORD_STATUS.OPERATIONS_CHECKPOINT
                    && i.status !== RECORD_STATUS.PENDING_CODING && i.status !== RECORD_STATUS.PENDING_TESTING
                    && i.status !== RECORD_STATUS.PENDING_APPROVAL && i.status !== RECORD_STATUS.ARCHIVE);
            } else {
                records.find(record => record.description === nextTab.name).selected = true;
            }
        }
        return records;
    }

    getCurrentTab(mainTabSelection: string, subTabIndex: number): IWorkflowTab {
        const currentTab = _.find(WORKFLOW_TABS, {name: mainTabSelection});
        return currentTab.subTabs ? currentTab.subTabs[subTabIndex] : currentTab;
    }

    getCurrentStatusName(mainTabSelection: string, subTabIndex: number): string {
        const currentTab = this.getCurrentTab(mainTabSelection, subTabIndex);
        return currentTab.subTabs ? currentTab.subTabs[subTabIndex].name : currentTab.name;
    }

    showMoveInWorkflowDialog(item: IRecordStatusWithDesc,
        selectedRows: RowNode[],
        showForwardStatusDetails: boolean,
        mainTabSelection?: string,
        subTabIndex?: number,
        callbackOnConfirm?: (newStatus: number) => void,
        customDialogFormRef?: TemplateRef<any>) {
        this.modalRecords = this.buildModalRecords(mainTabSelection, subTabIndex);
        this.currentStatus = this.getCurrentStatusName(mainTabSelection, subTabIndex);
        this.selectedRows = selectedRows;
        this.item = item;
        this.mainTabSelection = mainTabSelection;
        this.subTabIndex = subTabIndex;
        this.callbackOnConfirm = callbackOnConfirm;
        this.moveInWorkflowModalRef = this.dialog.open(CVSConfirmationDialogContentComponent, { disableClose: true,
            data: {
                headline: showForwardStatusDetails ? `Move (${selectedRows.length}) to ${item.description}` : `Confirmation`,
                templatePortalContent: customDialogFormRef,
            }
        });
        this.subscriptions.push(this.moveInWorkflowModalRef.componentInstance.onCancelClick.subscribe(() => {
            this.resetApprovalDetails();
        }));
    }

    onConfirmModal(approvalDetails: { approvalDate: Date; approvalNotes: string }) {
        this.approvalDetails = approvalDetails;
        this.doMoveInWorkflow(this.selectedRows, this.item.status, this.mainTabSelection, this.subTabIndex, this.callbackOnConfirm);
        this.moveInWorkflowModalRef.close();
    }

    showApproveAndSubmitDialog(selectedRows: RowNode[],
        approveAndSubmitFormRef?: TemplateRef<any>) {
        this.dialogDataSubmission = {
            selectedRows
        };
        this.approveAndSubmitModalRef = this.dialog.open(CVSConfirmationDialogContentComponent, { disableClose: true,
            data: {
                headline: 'Create a Case for Approval',
                templatePortalContent: approveAndSubmitFormRef,
            }
        });
        this.approveAndSubmitModalRef.componentInstance.onCancelClick.subscribe(() => {
            this.approveAndSubmitModalRef.close();
        });
    }

    workflowMenuClick(item: IRecordStatusWithDesc,
        selectedRows: RowNode[],
        customDialogFormRef: TemplateRef<any>,
        mainTabSelection: string,
        subTabIndex?: number,
        callbackOnConfirm?: (newStatus: number) => void) {
        const specialMoveDetail = this.getSpecialStatusMoveDetail(this.getCurrentTab(mainTabSelection, subTabIndex).status, item.status);
        this.dialogData = {
            selectedRows,
            item,
            showForwardStatusDetails: specialMoveDetail?.isForward,
            approvalNoteLabel: specialMoveDetail?.statusDetail.noteApprovalLabel,
            approvalDateLabel: specialMoveDetail?.statusDetail.dateApprovalLabel,
            actionLabel: specialMoveDetail?.isForward ? 'Save' : 'Yes',
        };
        this.approvalDetails = {
            approvalDate: specialMoveDetail?.statusDetail.approvalDate,
            approvalNotes: specialMoveDetail?.statusDetail.approvalNotes
        };
        this.showMoveInWorkflowDialog(item, selectedRows, specialMoveDetail?.isForward?? false, mainTabSelection, subTabIndex,
            callbackOnConfirm, customDialogFormRef);
    }

    getSpecialStatusMoveDetail(currentStatus: RECORD_STATUS, newStatus: RECORD_STATUS):
    { statusDetail: IMoveFromStatus; isForward: boolean } {
        const moveFromStatusToShowDateNoteInputs: IMoveFromStatus[] = [
            {
                status: RECORD_STATUS.READY_FOR_DISCUSSION,
                dateApprovalLabel: 'Discussion Approval Date', noteApprovalLabel: 'Discussion Approval Notes',
                approvalDate: new Date(),
                approvalNotes: NOT_APPLICABLE
            },
            {
                status: RECORD_STATUS.PENDING_UM,
                dateApprovalLabel: 'UM Approval Date', noteApprovalLabel: 'Regulatory Tracking Notes'
            },
            {
                status: RECORD_STATUS.PENDING_FORMULARY,
                dateApprovalLabel: 'FRC Approval Date', noteApprovalLabel: 'Regulatory Tracking Notes'
            },
            {
                status: RECORD_STATUS.PENDING_PT,
                dateApprovalLabel: 'P&T Approval Date', noteApprovalLabel: 'Regulatory Tracking Notes'
            },
            {
                status: RECORD_STATUS.PENDING_SUBMISSION,
                dateApprovalLabel: 'Submission Approval Date', noteApprovalLabel: 'Regulatory Tracking Notes'
            }
        ];
        const statusDetail = moveFromStatusToShowDateNoteInputs.find(i => i.status === currentStatus);
        const isForward = (statusDetail ?? false) && currentStatus < newStatus;
        return statusDetail ? {statusDetail, isForward} : undefined;
    }

    doMoveInWorkflow(selectedRows: RowNode[],
        nextStatus: number,
        mainTabSelection: string,
        subTabIndex: number,
        callbackOnConfirm?: (newStatus: number) => void) {
        if (selectedRows.length !== 0) {
            const encryptedRecordIds = selectedRows.map(i => i.data.encryptedRecordId);
            this.recordService.updateStatus(
                encryptedRecordIds,
                nextStatus,
                this.approvalDetails.approvalDate ?? undefined,
                this.approvalDetails.approvalNotes === NOT_APPLICABLE ? undefined : this.approvalDetails.approvalNotes,
                lookupRecordStatusByDescription(this.currentStatus)
            ).subscribe(() => {
                this.messageProvider.next({
                    source: `${mainTabSelection}-${subTabIndex}`,
                    newValue: Number(nextStatus),
                    encryptedRecordIds,
                    action: WORKFLOW_MESSAGE_ACTION.STATUS_CHANGE
                });
                this.adjustStatusCount(Number(nextStatus), selectedRows[0].data.statusId, selectedRows.length);
                this.resetApprovalDetails();
                this.showMoveInWorkflowSuccessBanner(selectedRows.length, Number(nextStatus));
                if (callbackOnConfirm) {
                    callbackOnConfirm(Number(nextStatus));
                }
            });
        }
    }

    showMoveInWorkflowSuccessBanner(selectedRowsCount: any, statusId: number) {
        const config = {
            statusId,
            details: [{ type: 'item', selectedRowsCount: selectedRowsCount }]
        };
        this.buildAndShowMoveInWorkflowSuccessBanner(config);
    }

    buildAndShowMoveInWorkflowSuccessBanner({statusId, message = 'moved to', details}: {
        statusId: number;
        message?: string;
        details: { type: string; selectedRowsCount: any }[];
    }) {
        const bannerLinks: BannerLink[] = [];
        let destinationMainTab = null;
        let destinationSubTab = null;
        let destinationLink = null;

        const recordStatus = RECORD_STATUSES.find(rs => rs.status === statusId);
        const workFlowTabsDetails = _.filter(WORKFLOW_TABS, 'name');

        workFlowTabsDetails.forEach(tab => {
            if (tab.subTabs && tab.subTabs.find(subTab => subTab.status === statusId)) {
                destinationMainTab = tab.name;
                destinationSubTab = tab.subTabs.findIndex(subTab => subTab.status === statusId);
                destinationLink = tab.subTabs.find(subTab => subTab.status === statusId).link;
            } else if (tab.status && tab.status === statusId) {
                destinationMainTab = tab.name;
                destinationSubTab = 0;
                destinationLink = tab.link;
            }
        });

        bannerLinks.push({
            linkText: `Go to ${recordStatus.description}`,
            linkFunc: this.doWorkflowNavigationFunction(destinationMainTab, destinationSubTab, destinationLink)
        });

        let body = '';
        details.forEach(detail => {
            if (detail.selectedRowsCount !== 0) {
                if (body.length !== 0) {
                    body += ', ';
                }
                body += `${detail.selectedRowsCount} ${detail.type}(s)`;
            }
        });

        body += ` ${message} "${recordStatus.description}".`;

        const successBannerData = {
            bannerType: CVSBannerType.Success,
            hideX: false,
            outletId: '#successBanner',
            headline: 'Success',
            body,
            alertType: CVSAlertType.Success,
            bannerLinks: bannerLinks
        } as CVSBannerComponentData;
        this.bannerService.sendAlert(successBannerData);
    }

    showAdhocWorkflowErrorBanner(bannerLink) {
        this.bannerService.sendAlert({
            bannerType: CVSBannerType.Error,
            headline: 'System Error: Did not add selected item(s) to Workflow',
            body: 'Please try again',
            outletId: '#errorBanner',
            ...bannerLink
        });
    }

    doWorkflowNavigationFunction(mainTabSelection: string, subTabIndex: number, url: string) {
        return () => {
            const extras: NavigationBehaviorOptions = {state: {mainTabSelection, subTabIndex}};
            this.router.navigateByUrl(url, extras);
        };
    }

    processWorkflowMessage(mainTabName, subTabIndex, tabStatuses: RECORD_STATUS[], gridOptions: GridOptions,
        gridData, workflowMessage: WorkflowMessage, callbackFetchData: () => void) {
        if (workflowMessage.source === `${mainTabName}-${subTabIndex}`) {
            if (workflowMessage.action === WORKFLOW_MESSAGE_ACTION.STATUS_CHANGE) {
                workflowMessage.encryptedRecordIds.forEach(eid => {
                    const idx = gridData.findIndex(r => r.encryptedRecordId === eid);
                    gridData.splice(idx, 1);
                    gridOptions.api.setRowData(gridData);
                });
            }
        }

        if (workflowMessage.source !== `${mainTabName}-${subTabIndex}`) {
            if (workflowMessage.action === WORKFLOW_MESSAGE_ACTION.STATUS_CHANGE) {
                if (workflowMessage.newValue === tabStatuses[subTabIndex]) {
                    callbackFetchData();
                }
            }
        }
        if (workflowMessage.action === WORKFLOW_MESSAGE_ACTION.NOTE_CHANGE) {
            if (gridOptions && gridOptions.api) {
                this.recordService.saveNote(gridData.encryptedRecordId,
                    workflowMessage.newValue.toString(), this.userService.getSuperClientContext().id);
                gridOptions.api.setRowData(gridData);
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    resetApprovalDetails() {
        this.approvalDetails = {
            approvalDate: new Date(new Date().toDateString()),
            approvalNotes: undefined
        };
    }

    buildWorkflowMessage(action: WORKFLOW_MESSAGE_ACTION, newValue: string | number, ndc?: string, updateType?: string, weekDate?: string,
        mainTabName?: string, subTabIndex?: number, gpi?: string) {
        return {
            ndc,
            gpi,
            updateType,
            weekDate,
            source: `${mainTabName}-${subTabIndex}`,
            newValue,
            action
        } as WorkflowMessage;
    }

    refreshWorkflowStatusGrid(status: RECORD_STATUS) {
        this.messageProvider.next(
            this.buildWorkflowMessage(WORKFLOW_MESSAGE_ACTION.STATUS_CHANGE, status)
        );
    }

    closeApproveAndSubmitDialog() {
        this.approveAndSubmitModalRef.close();
    }
}

/* eslint-disable max-len */
import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MedispanTrackerResponse} from '../model/MedispanTrackerResponse';
import {UserService} from '../service/user/user.service';
import {ADHOC_UPDATE_TYPES, TrackerDataService} from '../service/tracker-data/tracker-data.service';
import {DrugListComponent, IAdhocConflictRowData} from '../drug-list/drug-list.component';
import {MasterDrugResponse} from '../model/MasterDrugResponse';
import {take} from 'rxjs/operators';
import {User} from '../model/User';
import {AppService} from '../service/app/app.service';
import {Subscription} from 'rxjs';
import {PRODUCT_OVERVIEW_SOURCE} from '../enum/ProductOverviewSource';
import {WorkflowService} from '../service/workflow/workflow.service';
import {IRecordStatusWithDesc, RECORD_STATUS, RECORD_STATUSES} from '../enum/RecordStatus';
import _ from 'lodash';
import {RowNode} from '@ag-grid-community/core';
import {Title} from '@angular/platform-browser';
import {NA} from '../default-values/DefaultValues';
import {CVSBannerService, CVSBannerType} from 'angular-component-library';
import {RecommendationService} from 'src/app/service/recommendation/recommendation.service';
import {RecordService} from 'src/app/service/record/record.service';
import {Record} from '../model/Record';
import {FormularyService} from 'src/app/service/formulary/formulary.service';
import {BUSINESS_LOB_DESC_CD} from 'src/app/default-values/DefaultValues';
import {Recommendation} from 'src/app/model/Recommendation';
import {WORKFLOW_TABS} from '../workflow/WorkflowTab';
import {DrugUniverseApiService} from '../service/drug-universe/drug-universe-api.service';


export interface HeaderInfoBlock {
    name: string;
    value: { name: string; value: string }[] | { name: string; field: string }[];
};

// eslint-disable-next-line no-shadow
export enum HEADERS {// headers
    GPI = 'GPI',
    GPI_NAME = 'GPI Name',
    NDC = 'NDC',
    LABEL_NAME = 'Label Name',
    PRODUCT_NAME = 'Product Name',
    MULTISOURCE_CODE = 'Multisource Code',
    SOURCE = 'Source',
    BUSINESS_LOB = 'Line of Business',
    PROTECTED_CLASS = 'Protected Class',
    NEW_MOLECULAR_ENTITY = 'New Molecular Entity',
    UPDATE_TYPE = 'Update Type',
    FORMULARY_EFFECTIVE_YEAR = 'Formulary Effective Year',
    EMPTY_STRING = '',
    DRUG_TAG = 'Drug Tag'
};

@Component({
    selector: 'app-product-overview-page',
    templateUrl: './product-overview.component.html',
    styleUrls: ['./product-overview.component.scss']
})
export class ProductOverviewComponent implements OnInit, OnDestroy {
    @ViewChild('drugListComponent') drugListComponent: DrugListComponent;
    @ViewChild('formRef') public formRef: TemplateRef<any>;
    user: User;
    moveInWorkflowButton = false;
    productOverviewData: MasterDrugResponse[];
    showProductInformationSpinner: boolean;
    NDC = {
        name: 'ndcs',
        value: ''
    };
    note: string;
    medispanTrackerResponse: MedispanTrackerResponse;
    showFormularyInformationTab = false;
    showRecommendationsTab = false;
    showTrackingDatesTab = false;
    navigatedFromUrl: string;
    subscriptions: Subscription[] = [];
    isProductInformationTabSelected = true;
    isFormularyInfoTabSelected = false;
    isRecommendationsTabSelected = false;
    isTrackingTabSelected = false;
    fromSource: PRODUCT_OVERVIEW_SOURCE;
    showWorkFlowButton!: boolean;
    statusId: number = undefined;
    hasEditPermission = false;
    selectedLOBS = [];

    mainTabName: string;
    subTabIndex: number;
    RECORD_STATUS = RECORD_STATUS;
    ndcBusinessLob;
    canMoveInWorkFlow!: boolean;
    dropDownMenuItems!: IRecordStatusWithDesc[];
    isUpdateTypeGpiAdHoc!: boolean;
    productRecordDetail!: Record[];
    workFlowSource = [
        PRODUCT_OVERVIEW_SOURCE.WORKFLOW_MEDISPAN,
        PRODUCT_OVERVIEW_SOURCE.WORKFLOW_RESEARCH_AND_RECOMMENDATIONS,
        PRODUCT_OVERVIEW_SOURCE.WORKFLOW_OPERATIONS_AND_IMPLEMENTATION,
        PRODUCT_OVERVIEW_SOURCE.WORKFLOW_REGULATORY_TRACKING,
        PRODUCT_OVERVIEW_SOURCE.WORKFLOW_NO_ACTION,
        PRODUCT_OVERVIEW_SOURCE.WORKFLOW_ARCHIVE,
    ];
    isDisabled = false;

    constructor(
        private _appService: AppService,
        private route: Router,
        private _userService: UserService,
        private trackerService: TrackerDataService,
        public workflowService: WorkflowService,
        private titleService: Title,
        private bannerService: CVSBannerService,
        private _recommendationService: RecommendationService,
        private _recordService: RecordService,
        private _formularyService: FormularyService,
        private _drugUniverseService: DrugUniverseApiService
    ) {
        if (!route.getCurrentNavigation().extras.state) {
            this.backToPreviousPage();
            return;
        }
        this.initialize();
    }

    public get PRODUCT_OVERVIEW_SOURCE() {
        return PRODUCT_OVERVIEW_SOURCE;
    }

    initialize() {
        this.fromSource = this.route.getCurrentNavigation().extras.state.source;
        this.hasEditPermission = this._userService.hasEditPermission();
        this.enableEditing();
        if (this.fromSource === PRODUCT_OVERVIEW_SOURCE.GPI_TO_NDC_ADHOC) {
            this.initializeMedispanTrackerResponseFromAdhocSource();
        } else {
            this.medispanTrackerResponse = this.route.getCurrentNavigation().extras.state.medispanTrackerData;
        }
        this.showWorkFlowButton = [
            PRODUCT_OVERVIEW_SOURCE.WORKFLOW_RESEARCH_AND_RECOMMENDATIONS,
            PRODUCT_OVERVIEW_SOURCE.WORKFLOW_OPERATIONS_AND_IMPLEMENTATION,
            PRODUCT_OVERVIEW_SOURCE.WORKFLOW_REGULATORY_TRACKING,
            PRODUCT_OVERVIEW_SOURCE.WORKFLOW_NO_ACTION,
            PRODUCT_OVERVIEW_SOURCE.GPI_TO_NDC_ADHOC
        ].includes(this.route.getCurrentNavigation().extras.state.source) && this.medispanTrackerResponse.encryptedRecordId !== undefined;
        this.showFormularyInformationTab = this.formularyInformationTabToggle(this.route.getCurrentNavigation().extras.state);
        this.navigatedFromUrl = this.route.getCurrentNavigation().extras.state?.navigationSource?.toString().trim();
        this.mainTabName = this.route.getCurrentNavigation().extras.state.mainTabName;
        this.subTabIndex = this.route.getCurrentNavigation().extras.state.subTabIndex;
        this.showRecommendationsTab = this.showTrackingDatesTab = this.formularyInformationTabToggle(this.route.getCurrentNavigation().extras.state) && (
            this.mainTabName === WORKFLOW_TABS.TAB1RESEARCH_AND_RECOMMENDATIONS.name ||
            this.mainTabName === WORKFLOW_TABS.TAB2APPROVAL_TRACKING.name ||
            this.mainTabName === WORKFLOW_TABS.TAB3OPERATIONS_AND_IMPLEMENTATION.name ||
                this.mainTabName === WORKFLOW_TABS.TAB5ARCHIVE.name
        ) && this.medispanTrackerResponse.encryptedRecordId !== undefined;

        this.isUpdateTypeGpiAdHoc = this.medispanTrackerResponse?.updateType === ADHOC_UPDATE_TYPES.GPI_ADHOC ||
            this.medispanTrackerResponse?.updateType === ADHOC_UPDATE_TYPES.NDC_TO_GPI_ADHOC;
    }

    initializeMedispanTrackerResponseFromAdhocSource() {
        this.medispanTrackerResponse = {};
        const adhocData: IAdhocConflictRowData = this.route.getCurrentNavigation().extras.state.adhocData;
        this.medispanTrackerResponse.ndc = adhocData.adhocDetail.matchingNDC ?? NA;
        this.medispanTrackerResponse.statusId = adhocData.adhocDetail.statusId;
        this.medispanTrackerResponse.gpi = adhocData.adhocDetail.matchingGPI;
        this.medispanTrackerResponse.note = adhocData.adhocDetail.matchingNote;
        this.medispanTrackerResponse.combinedId = adhocData.adhocDetail.matchingCombinedId;
        this.medispanTrackerResponse.businessLob = adhocData.businessLob;
        this.medispanTrackerResponse.weekDate = adhocData.adhocDetail.matchingWeekDate;
        this.medispanTrackerResponse.encryptedRecordId = adhocData.adhocDetail.matchingEncryptedRecordId;
        this.medispanTrackerResponse.updateType = adhocData.adhocDetail.matchingUpdateType;
    }

    ngOnInit(): void {
        if (this.medispanTrackerResponse) {
            const isNdc = this.medispanTrackerResponse.ndc !== 'N/A';
            this.showProductInformationSpinner = isNdc;
            this.productOverviewData = undefined;
            this.note = this.medispanTrackerResponse.note;
            this.NDC.value = this.medispanTrackerResponse.ndc;
            if (isNdc) {
                this._drugUniverseService.getDrug(this.NDC.name, this.NDC.value, 'true', 'true')
                    .pipe(take(1))
                    .subscribe(data => {
                        if (data) {
                            this.productOverviewData = data.filter(i => i.ndc === this.NDC.value);
                            this.showProductInformationSpinner = false;
                        }
                    });
            }
            this.statusId = this.medispanTrackerResponse.statusId;
            this.ndcBusinessLob = this.medispanTrackerResponse.businessLob;
            this.buildDropDownMenuItems();
            this.getProductRecordDetails();
            if (this.isUpdateTypeGpiAdHoc) {
                this.titleService.setTitle('CLR - GPI Overview');
                this.isProductInformationTabSelected = false;
                this.isRecommendationsTabSelected = true;
            }
        }
        this.user = this._userService.getUser();
        this.moveInWorkflowButton = !this.user?.internalUser && this.mainTabName === WORKFLOW_TABS.TAB3OPERATIONS_AND_IMPLEMENTATION.name;
    }

    getProductRecordDetails() {
        if (this.fromSource !== PRODUCT_OVERVIEW_SOURCE.WORKFLOW_MEDISPAN &&
            this.fromSource !== PRODUCT_OVERVIEW_SOURCE.FORMULARY_SEARCH &&
            this.fromSource !== PRODUCT_OVERVIEW_SOURCE.DRUG_SEARCH) {
            this._recordService.getProductRecordDetails(
                this.medispanTrackerResponse.encryptedRecordId,
                this.medispanTrackerResponse.updateType,this._appService.getSuperClientContext().id
            ).pipe(take(1)).subscribe(productRecordDetail => this.productRecordDetail = productRecordDetail);
        }
    }

    backToPreviousPage() {
        if (this.navigatedFromUrl) {
            this.route.navigate([this.navigatedFromUrl]);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => {
            s.unsubscribe();
        });
    }

    enableEditing() {
        this.isDisabled = !this.hasEditPermission;
    }

    formularyInformationTabToggle(state) {
        if (state.navigationSource?.startsWith('/workflow') ||
            this.fromSource === PRODUCT_OVERVIEW_SOURCE.GPI_TO_NDC_ADHOC ||
            this.fromSource === PRODUCT_OVERVIEW_SOURCE.FORMULARY_SEARCH) {
            if (this.fromSource === PRODUCT_OVERVIEW_SOURCE.FORMULARY_SEARCH) {
                this.selectedLOBS = state.selectedLOBS;
            }
            return true;
        }
        return false;
    }

    onTabChange(event) {
        if (this.isUpdateTypeGpiAdHoc) {
            this.isRecommendationsTabSelected = event.index === 0;
            this.isTrackingTabSelected = event.index === 1;
        } else {
            this.isProductInformationTabSelected = event.index === 0;
            this.isFormularyInfoTabSelected = event.index === 1;
            this.isRecommendationsTabSelected = event.index === 2;
            this.isTrackingTabSelected = event.index === 3;
        }
    }

    onMenuItemClick(item: IRecordStatusWithDesc) {
        const selectedNode = [{data: this.medispanTrackerResponse}] as RowNode[];
        this._appService.getInitializationSubject().pipe(take(1)).subscribe(() => {
            const index = this._appService.getSuperClientContext().id;
            this._formularyService.getFormularyData(index).pipe(take(1)).subscribe(formularyResponse => {
                const blob = BUSINESS_LOB_DESC_CD.get(this.medispanTrackerResponse.businessLob);
                const formularies = this._formularyService.getDistinctFormulariesByBusinessLob(blob, formularyResponse);
                const record = this.productRecordDetail?.find(product => product.businessLobCd === blob);
                if (record) {
                    this._recommendationService.getRecommendationGridData(record.encryptedRecordId)
                        .pipe(take(1))
                        .subscribe(
                            {
                                next: (recommendation: Recommendation[]) => this.enableItemMoveInWorkFlow(recommendation, formularies),
                                complete: () => this.displayErrorBannerOrMoveInWorkFlow(item, selectedNode)
                            }
                        );
                } else {
                    this.displayErrorBannerOrMoveInWorkFlow(item, selectedNode);
                }
            });
        });
    }

    displayErrorBannerOrMoveInWorkFlow(item: IRecordStatusWithDesc, selectedNode: RowNode<any>[]) {
        const invalidStatus = [10, 100, 200] as RECORD_STATUS[];
        const isValid = invalidStatus.includes(item.status);
        const showErrorBanner = isValid || !this.canMoveInWorkFlow;

        if (isValid) {
            this.moveItemInWorkFlow(item, selectedNode);
        } else if (showErrorBanner) {
            this.bannerService.sendAlert({
                bannerType: CVSBannerType.Error,
                headline: 'Cannot move forward in workflow',
                body: `Formulary Requirements must be complete and saved to move to ${item.description}.`,
                outletId: '#successBanner'
            });
        } else {
            this.moveItemInWorkFlow(item, selectedNode);
        }
    }

    moveItemInWorkFlow(item: IRecordStatusWithDesc, selectedNode: RowNode<any>[]) {
        this.workflowService.workflowMenuClick(
            item,
            selectedNode,
            this.formRef,
            this.mainTabName,
            this.subTabIndex,
            (newStatus: number) => this.onNewStatus(item.status)
        );
    }

    enableItemMoveInWorkFlow(recommendation: Recommendation[], formularies: any[]) {
        if ((recommendation.length !== formularies.length) || !recommendation.every(item => item.statusCd === '2')) {
            this.canMoveInWorkFlow = false;
        } else {
            this.canMoveInWorkFlow = true;
        }
    }

    onNewStatus(newStatus: number) {
        this.statusId = newStatus;
        this.medispanTrackerResponse.statusId = this.statusId;
        this.findMainTabAndSubTabFromStatus(newStatus);
        this.buildDropDownMenuItems();
    }

    buildDropDownMenuItems() {
        this.dropDownMenuItems = this.workflowService.buildModalRecords(this.mainTabName, this.subTabIndex)
            ?.filter(recordItem => recordItem.status !== this.statusId);
        if (!this._userService.getUser()?.internalUser) {
            if(this.dropDownMenuItems) {
                this.dropDownMenuItems = this.dropDownMenuItems.slice(0,6);
                if (this.mainTabName !=='No Action') {
                    this.dropDownMenuItems.push(RECORD_STATUSES[12]);
                } else {
                    this.dropDownMenuItems.push(RECORD_STATUSES[7]);
                }
                this.dropDownMenuItems = this.dropDownMenuItems?.filter(recordItem => recordItem.status !== this.statusId);
            }
        }
    }

    findMainTabAndSubTabFromStatus(status: number) {
        const workFlowTabsDetails = _.filter(WORKFLOW_TABS, 'name');
        workFlowTabsDetails.forEach(tab => {
            if (tab.subTabs && tab.subTabs.find(subTab => subTab.status === status)) {
                this.mainTabName = tab.name;
                this.subTabIndex = tab.subTabs.findIndex(subTab => subTab.status === status);
            } else if (tab.status && tab.status === status) {
                this.mainTabName = tab.name;
                this.subTabIndex = 0;
            }
        });
    }

    displayStatusChip() {
        return this.fromSource !== PRODUCT_OVERVIEW_SOURCE.DRUG_SEARCH &&
            this.fromSource !== PRODUCT_OVERVIEW_SOURCE.FORMULARY_SEARCH &&
            this.fromSource !== PRODUCT_OVERVIEW_SOURCE.WORKFLOW_MEDISPAN &&
            this.medispanTrackerResponse?.encryptedRecordId !== undefined;
    }
}

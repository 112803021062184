/* eslint-disable eqeqeq */
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {BUSINESS_LOB} from '../../../../enum/BusinessLob';
import {
    AGE_LIMIT,
    AGE_LIMIT_VALUES,
    ATTACHMENT_LEVEL,
    ATTACHMENT_LEVEL_CATEGORIES,
    ATTACHMENT_MONY,
    BUSINESS_LOB_DESC_CD,
    DefaultCodeDescription,
    MED_D,
    NA_LIST_ITEM,
    PA_SELECTION_COM_EX_MED,
    PA_SELECTION_MEDICARE,
    QL_TYPE,
    QL_TYPE_COM_EX_MED,
    QL_TYPE_MEDICARE,
    RX_OTC,
    SEX_EXCLUSIONS,
    STEP_TYPE_MEDICARE,
    STEP_TYPE_OTHERS,
} from '../../../../default-values/DefaultValues';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {MatCheckbox} from '@angular/material/checkbox';
import _ from 'lodash';
import {RecommendationService} from '../../../../service/recommendation/recommendation.service';
import {Recommendation} from 'src/app/model/Recommendation';
import {take} from 'rxjs/operators';
import {CVSBannerService, CVSBannerType} from 'angular-component-library';
import {Observable, Subscription} from 'rxjs';
import {AppService} from '../../../../service/app/app.service';
import { IBlobGroup, IOtherwiseRule, IRecommendationRules, IValueRule, recommendationRules } from '../RecommendationFormRules';
import { MedispanTrackerResponse } from 'src/app/model/MedispanTrackerResponse';

@Component({
    selector: 'app-form-entry',
    templateUrl: './form-entry.component.html',
    styleUrls: ['./form-entry.component.scss'],
})
export class FormEntryComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('attachmentMony') attachmentMony: MatSelect;
    @ViewChild('attachmentMonyCheckbox') attachmentMonyCheckbox: MatCheckbox;
    @Input() medispanTrackerResponse: MedispanTrackerResponse;
    @Input() selectedClientFormularyName: any;
    @Input() formularyNumber: any;
    @Input() blob: string;
    @Input() tierCount: any;
    @Output() disableSaveButton: EventEmitter<boolean> = new EventEmitter(true);
    @Output() isFormSaved: EventEmitter<boolean> = new EventEmitter(false);

    qlTypes = [
        {
            type: 'A',
            selectFormName: 'qlTypeA',
            amountFormName: 'qlAmountA',
            unitFormName: 'qlUnitA',
            quantityFormName: 'qQPPDA'
        },
        {
            type: 'B',
            selectFormName: 'qlTypeB',
            amountFormName: 'qlAmountB',
            unitFormName: 'qlUnitB',
            quantityFormName: 'qQPPDB'
        },
        {
            type: 'C',
            selectFormName: 'qlTypeC',
            amountFormName: 'qlAmountC',
            unitFormName: 'qlUnitC',
            quantityFormName: 'qQPPDC'
        }];
    attachmentTypes = [ATTACHMENT_LEVEL.GPI, ATTACHMENT_LEVEL.NDC];
    attachmentMonyList = ATTACHMENT_MONY;
    indeterminate = false;
    NA = 'N/A';
    NONE = '0';
    formularyTierSelections: string[];
    rxOtcList = RX_OTC;
    medDList = MED_D;
    naListItems = NA_LIST_ITEM;
    paSelection;
    paSelectionNonMedicare = PA_SELECTION_COM_EX_MED;
    bvD = 'B vs D';
    qlTypeOptions!: QL_TYPE[];
    ageLimits = AGE_LIMIT;
    ageLimitValues = AGE_LIMIT_VALUES;
    sexExclusions = SEX_EXCLUSIONS;
    inUpdateBlockSection = false;
    recommendationFormGroup: FormGroup;
    attachmentLevels: [key: string, value: string][] = [];
    rules: IRecommendationRules;
    recommendationObjects = new Map<string, any>();
    recommendationStatus;
    displayMedicarePAError= false;
    protected readonly BUSINESS_LOB = BUSINESS_LOB;
    private subscriptions: Subscription[] = [];

    constructor(
        private _recommendationService: RecommendationService,
        private fb: FormBuilder,
        private bannerService: CVSBannerService,
        private appService: AppService
    ) {
        this.recommendationFormGroup = this.fb.group({
            attachmentType: [{value: null}, [Validators.required]],
            attachmentMonyDD: [[] as string[], [Validators.required]],
            monyCode: [{value: '', disabled: true}, [Validators.required]],
            attachmentLevel: [''],
            stepType: [''],
            stepName: [{value: '', disabled: true}, [Validators.maxLength(100)]],
            stepBehavior: [{value: '', disabled: true}],
            tier: [{value: ''}, [Validators.required]],
            paSelection: [''],
            paGroupName: [{value: '', disabled: true}],
            qlType: [''],
            qlAmount: [{value: '', disabled: true}, [Validators.pattern(/^\d+(\.\d{1,4})?$/)]],
            qlUnit: [{value: '', disabled: true}],
            qQPPD: [{value: '', disabled: true}, [Validators.pattern(/^[0-9]+$/)]],
            qlTypeA: [''],
            qlAmountA: [{value: '', disabled: true}, [Validators.pattern(/^\d+(\.\d{1,4})?$/)]],
            qlUnitA: [{value: '', disabled: true}],
            qQPPDA: [{value: '', disabled: true}, [Validators.pattern(/^[0-9]+$/)]],
            qlTypeB: [{value: '', disabled: true}],
            qlAmountB: [{value: '', disabled: true}, [Validators.pattern(/^\d+(\.\d{1,4})?$/)]],
            qlUnitB: [{value: '', disabled: true}],
            qQPPDB: [{value: '', disabled: true}, [Validators.pattern(/^[0-9]+$/)]],
            qlTypeC: [{value: '', disabled: true}],
            qlAmountC: [{value: '', disabled: true}, [Validators.pattern(/^\d+(\.\d{1,4})?$/)]],
            qlUnitC: [{value: '', disabled: true}],
            qQPPDC: [{value: '', disabled: true}, [Validators.pattern(/^[0-9]+$/)]],
            ageLimit: [''],
            ageLimitMin: [{value: '', disabled: true}, [Validators.pattern(/^[0-9]+$/)]],
            ageLimitMinValue: [{value: this.NA, disabled: true}, []],
            ageLimitMax: [{value: '', disabled: true}, [Validators.pattern(/^[0-9]+$/)]],
            ageLimitMaxValue: [{value: this.NA, disabled: true}, []],
            sexExclusion: [''],
            coverageMiscMedD: [''],
            coverageMiscRxOtc: [''],
            coverageMiscNotCoveredMessageDescription: [{value: this.NA, disabled: true}, []]
        });
    }

    ngOnInit(): void {
        this.init();
    }

    init() {
        this._recommendationService.setRecommendationFormModified(false);
        this.subscriptions.push(this.recommendationFormGroup?.valueChanges.subscribe((value) => {
            this.loadFormControlValues(value);
            this.buildAttachmentLevels(value);
            if (!this.recommendationFormGroup.pristine) {
                this._recommendationService.setRecommendationFormModified(true);
                this.disableSaveButton.emit(false);
            }
        }));
        this.subscriptions.push(this._recommendationService.getTriggerRecommendationsSaveSubject().subscribe(() => {
            this.saveRecommendations();
        }));

        if (this.blob !== BUSINESS_LOB.MEDICARE) {
            this.sexExclusions = this.sexExclusions.filter(exclusion => exclusion.description !== '0 - None');
            this.ageLimits = this.ageLimits.filter(ageLimit => ageLimit.description !== '0 - None');
        } else {
            this.sexExclusions = this.sexExclusions.filter(exclusion => exclusion.description !== 'Remove Gender Edits');
            this.ageLimits = this.ageLimits.filter(ageLimit => ageLimit.description !== 'Remove Age Limits');
        }
    }

    buildAttachmentLevels(value: any) {
        this.attachmentLevels = [];
        if (this.blob) {
            if (value?.attachmentType === ATTACHMENT_LEVEL.NDC) {
                const attachmentLevelsCategories = [
                    ATTACHMENT_LEVEL_CATEGORIES.NDC_11,
                    ATTACHMENT_LEVEL_CATEGORIES.NDC_9,
                    ATTACHMENT_LEVEL_CATEGORIES.NDC_5
                ];

                let attachLevel = ATTACHMENT_LEVEL_CATEGORIES.NDC_11;
                if (this.recommendationFormGroup.controls.attachmentLevel.value) {
                    // eslint-disable-next-line max-len
                    attachLevel = attachmentLevelsCategories.find(lvl => +lvl.split('_')[1] === this.recommendationFormGroup.controls.attachmentLevel.value?.toString().length);
                }

                this.createdAttachmentLevels(attachmentLevelsCategories, attachLevel, 'ndc');
            } else if (value?.attachmentType === ATTACHMENT_LEVEL.GPI) {
                const attachmentLevelsCategories = [
                    ATTACHMENT_LEVEL_CATEGORIES.GPI_14,
                    ATTACHMENT_LEVEL_CATEGORIES.GPI_12,
                    ATTACHMENT_LEVEL_CATEGORIES.GPI_10,
                    ATTACHMENT_LEVEL_CATEGORIES.GPI_8
                ];

                let attachLevel = ATTACHMENT_LEVEL_CATEGORIES.GPI_14;
                if (this.recommendationFormGroup.controls.attachmentLevel.value) {
                    // eslint-disable-next-line max-len
                    attachLevel = attachmentLevelsCategories.find(lvl => +lvl.split('_')[1] === this.recommendationFormGroup.controls.attachmentLevel.value?.toString().length);
                }

                this.createdAttachmentLevels(attachmentLevelsCategories, attachLevel, 'gpi');
            }
        }
    }

    createdAttachmentLevels(
        attachmentLevelCategories: ATTACHMENT_LEVEL_CATEGORIES[],
        attachmentLevelCategory: ATTACHMENT_LEVEL_CATEGORIES,
        attachmentType: string
    ) {
        const gpiOrNdcValue = this.medispanTrackerResponse?.[attachmentType];
        this.attachmentLevels = [];
        if (gpiOrNdcValue !== 'N/A') {
            attachmentLevelCategories.forEach(key => {
                this.attachmentLevels.push([key, gpiOrNdcValue?.substring(0, +key.split('_')[1])]);
            });

            const savedValue = this.attachmentLevels.find(lvl => lvl[0] === attachmentLevelCategory);
            this.recommendationFormGroup.controls.attachmentLevel.patchValue(
                savedValue ? savedValue[1] : this.attachmentLevels[0][1],
                { emitEvent: false }
            );
        }
    }

    formControlInitialization() {
        const stepType = this.getStepType();
        const qlType = this.getQlTypeDropDown();
        this.recommendationFormGroup.controls.coverageMiscMedD.patchValue(this.medDList[2].code, {emitEvent: false});
        this.recommendationFormGroup.controls.coverageMiscRxOtc.patchValue(this.rxOtcList[2].code, {emitEvent: false});
        this.recommendationFormGroup.controls.coverageMiscNotCoveredMessageDescription.patchValue(null, {emitEvent: false});
        this.recommendationFormGroup.controls.paSelection.patchValue(this.paSelectionNonMedicare[0].code, {emitEvent: false});
        this.recommendationFormGroup.controls.paGroupName.patchValue(null, {emitEvent: false});
        this.recommendationFormGroup.controls.stepType.patchValue(stepType[0].code, {emitEvent: true});
        this.recommendationFormGroup.controls.stepName.patchValue(null, {emitEvent: false});
        this.recommendationFormGroup.controls.ageLimit.patchValue(this.ageLimits[0].code, {emitEvent: false});
        this.recommendationFormGroup.controls.ageLimitMin.patchValue(null, {emitEvent: false});
        this.recommendationFormGroup.controls.ageLimitMinValue.patchValue(this.NA, {emitEvent: false});
        this.recommendationFormGroup.controls.ageLimitMax.patchValue(null, {emitEvent: false});
        this.recommendationFormGroup.controls.ageLimitMaxValue.patchValue(this.NA, {emitEvent: false});
        this.recommendationFormGroup.controls.sexExclusion.patchValue(this.sexExclusions[0].code, {emitEvent: false});
        this.recommendationFormGroup.controls.attachmentType.patchValue(null, {emitEvent: false});
        this.recommendationFormGroup.controls.tier.patchValue('No Change', { emitEvent: false });
        if (this.blob !== BUSINESS_LOB.MEDICARE) {
            this.recommendationFormGroup.controls.qlTypeA.patchValue(qlType[0].code, {emitEvent: false});
            this.recommendationFormGroup.controls.qlTypeB.patchValue(qlType[0].code, {emitEvent: false});
            this.recommendationFormGroup.controls.qlTypeC.patchValue(qlType[0].code, {emitEvent: false});
            this.recommendationFormGroup.controls.qlAmountA.patchValue(null, { emitEvent: false });
            this.recommendationFormGroup.controls.qlAmountB.patchValue(null, {emitEvent: false});
            this.recommendationFormGroup.controls.qlAmountC.patchValue(null, {emitEvent: false});
            this.recommendationFormGroup.controls.qlUnitA.patchValue(null, {emitEvent: false});
            this.recommendationFormGroup.controls.qlUnitB.patchValue(null, {emitEvent: false});
            this.recommendationFormGroup.controls.qlUnitC.patchValue(null, {emitEvent: false});
            this.recommendationFormGroup.controls.qQPPDA.patchValue(null, {emitEvent: false});
            this.recommendationFormGroup.controls.qQPPDB.patchValue(null, {emitEvent: false});
            this.recommendationFormGroup.controls.qQPPDC.patchValue(null, {emitEvent: false});
        } else {
            this.recommendationFormGroup.controls.qlType.patchValue(qlType[0].code, {emitEvent: false});
        }
    }

    getStepType(): DefaultCodeDescription[] {
        if (this.blob === BUSINESS_LOB.MEDICARE) {
            return STEP_TYPE_MEDICARE;
        }
        return STEP_TYPE_OTHERS;
    }

    getQlTypeDropDown(): any {
        if (this.blob === BUSINESS_LOB.MEDICARE) {
            return this.qlTypeOptions;
        }
        return QL_TYPE_COM_EX_MED;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.formularyTierSelections = this.buildFormularyTierSelection(this.tierCount, this.blob);
        if (changes.blob) {
            this.blob = changes.blob.currentValue;
            this.rules = recommendationRules(this.blob);
            if (this.blob === BUSINESS_LOB.MEDICARE) {
                this.qlTypes = [{
                    type: '',
                    selectFormName: 'qlType',
                    amountFormName: 'qlAmount',
                    unitFormName: 'qlUnit',
                    quantityFormName: 'qQPPD'
                }];
                this.paSelection = _.map(PA_SELECTION_MEDICARE).map(i => ({description: i.description, code: i.code}));
                this.qlTypeOptions = _.map(QL_TYPE_MEDICARE).map(i => ({description: i.description, code: i.code}));
            }
        }

        if (changes.formularyNumber?.currentValue !== changes.formularyNumber?.previousValue) {
            this._recommendationService.setRecommendationFormModified(false);
            this.disableSaveButton.emit(true);
            this.recommendationFormGroup?.reset({}, {emitEvent: false});
            this.bannerService.close();
            const formularyRecommendation = this.recommendationObjects.get(this.formularyNumber);
            this.formControlInitialization();
            if (formularyRecommendation) {
                if (formularyRecommendation.monyCode !== 'N/A') {
                    formularyRecommendation.attachmentMonyDD = formularyRecommendation.monyCode?.split('');
                }
                formularyRecommendation.tier = formularyRecommendation.tier === undefined ? 'No Change' : formularyRecommendation.tier;
                this.loadFormControlValues(formularyRecommendation);
                this.recommendationFormGroup.patchValue(formularyRecommendation, {emitEvent: false});
                this.recommendationStatus = formularyRecommendation.statusCd;
            } else {
                this._recommendationService.getFormularyByNumber(this.formularyNumber).pipe(take(1)).subscribe(val => {
                    if (val !== undefined) {
                        const data = {...val[0]};
                        this.recommendationStatus = val[0] ? val[0].statusCd : '0';
                        if (val[0]) {
                            if (data.monyCode && data.monyCode !== 'N/A') {
                                data.attachmentMonyDD = data.monyCode?.split('');
                                delete data.monyCode;
                            }
                            this.recommendationFormGroup.patchValue(data, {emitEvent: false});
                        }
                        this.loadFormControlValues(data);
                    }
                });
            }
        }
    }

    loadFormControlValues(data): void {
        this.processFieldRules(data);
    }

    onAttachmentMonyDDChange() {
        const attachmentMonyDDFCLength = this.attachmentMony.options.filter(opt => opt.selected).length;
        this.indeterminate =
            (attachmentMonyDDFCLength > 0 && attachmentMonyDDFCLength < this.attachmentMonyList.length);
        if ((!this.attachmentMonyCheckbox.checked && attachmentMonyDDFCLength === this.attachmentMonyList.length) ||
            (this.attachmentMonyCheckbox.checked && attachmentMonyDDFCLength < this.attachmentMonyList.length)) {
            this.attachmentMonyCheckbox.toggle();
        }
    }

    toggleAllSelection() {
        if (this.attachmentMonyCheckbox?.checked) {
            this.recommendationFormGroup.controls.attachmentMonyDD.patchValue([...this.attachmentMonyList.map(m => m.code)],
                {emitEvent: false});
        } else {
            this.recommendationFormGroup.controls.attachmentMonyDD.patchValue([], {emitEvent: false});
        }
    }

    buildFormularyTierSelection(tierCount: number, businessLOB: string): string[] {
        const values: string[] = [];
        for (let i = 0; i <= tierCount; i++) {
            values.push(`${i}`);
        }
        if (businessLOB === BUSINESS_LOB.MEDICARE) {
            values.push(
                ...['No Change',
                    'Excluded',
                    'Part B Pay at POS',
                    'Part A/B Deny as POS',
                    'Non-Formulary']);
        } else {
            values.push(
                ...['No Change',
                    'Excluded',
                    'Excluded Medical Benefit',
                    'Excluded OTC',
                    'Non-Formulary']);
        }
        return values;
    }

    processFieldRules(val) {
        if (!this.inUpdateBlockSection) {
            this.inUpdateBlockSection = true;
            Object.keys(this.rules).forEach(k => {
                let rule: (IValueRule | IBlobGroup) =
                    this.rules[k].find((a: IValueRule) => a.values && a.values.includes(val[k])) as IValueRule;
                if (rule && rule.bLobs) {
                    if (!rule.bLobs.includes(this.blob)) {
                        rule = undefined;
                    }
                }
                if (!rule) {
                    rule = (this.rules[k].find((a: IOtherwiseRule) => a.otherwise !== undefined) as IOtherwiseRule).otherwise;
                    if (rule && rule.bLobs) {
                        if (!rule.bLobs.includes(this.blob)) {
                            rule = undefined;
                        }
                    }
                }
                if (rule) {
                    rule.groups.forEach(grp => {
                        grp.controls.forEach(ctlName => {
                            const ctl = this.recommendationFormGroup.controls[ctlName];
                            if (grp.opts.disabled !== undefined) {
                                if (grp.opts.disabled) {
                                    ctl.disable({emitEvent: false});
                                }
                                if (!grp.opts.disabled) {
                                    ctl.enable({emitEvent: false});
                                }
                            }
                            if (grp.opts.validators !== undefined) {
                                ctl.clearValidators();
                                ctl.addValidators(grp.opts.validators);
                            }
                            if (grp.opts.value !== undefined && (this.recommendationFormGroup.controls[k] as any)._pendingChange) {
                                if (val[ctlName] !== grp.opts.value || grp.opts.value === null) {
                                    val[ctlName] = grp.opts.value;
                                    ctl.setValue(grp.opts.value);
                                }
                            }
                            if (grp.opts.value !== undefined && !(this.recommendationFormGroup.controls[k] as any)._pendingChange) {
                                if ([null, undefined, 'N/A'].includes(val[ctlName]) && val[ctlName] !== grp.opts.value) {
                                    val[ctlName] = grp.opts.value;
                                    ctl.setValue(grp.opts.value);
                                }
                            }
                            if (grp.opts.validators !== undefined) {
                                ctl.updateValueAndValidity();
                                ctl.markAsTouched();
                            }
                        });
                    });
                }
            });
            this.inUpdateBlockSection = false;
        }
    }

    saveRecommendations() {
        this.markMandatoryFieldsAsTouched();
        // @ts-ignore
        const isAttachmentTypeGpi: boolean = this.recommendationFormGroup.controls.attachmentType.value === 'GPI';
        const recommendationChange: Recommendation = {
            encryptedRecordId: this._recommendationService.getMedispanTrackerResponse().encryptedRecordId,
            formularyNumber: this.formularyNumber,
            attachmentLevel: this.recommendationFormGroup.controls.attachmentLevel.value,
            attachmentType: this.recommendationFormGroup.controls.attachmentType.value,
            monyCode: (isAttachmentTypeGpi) ? this.recommendationFormGroup.controls.attachmentMonyDD.value?.join('') :
                this.recommendationFormGroup.controls.monyCode.value,
            tier: this.recommendationFormGroup.controls.tier.value,
            paSelection: this.recommendationFormGroup.controls.paSelection.value,
            paGroupName: this.recommendationFormGroup.controls.paGroupName.value,
            stepType: this.recommendationFormGroup.controls.stepType.value,
            stepName: this.recommendationFormGroup.controls.stepName.value,
            qlType: this.recommendationFormGroup.controls.qlType.value,
            qlAmount: this.recommendationFormGroup.controls.qlAmount.value,
            qlUnit: this.recommendationFormGroup.controls.qlUnit.value,
            qQPPD: this.recommendationFormGroup.controls.qQPPD.value,
            qlTypeA: this.recommendationFormGroup.controls.qlTypeA.value,
            qlAmountA: this.recommendationFormGroup.controls.qlAmountA.value,
            qlUnitA: this.recommendationFormGroup.controls.qlUnitA.value,
            qQPPDA: this.recommendationFormGroup.controls.qQPPDA.value,
            qlTypeB: this.recommendationFormGroup.controls.qlTypeB.value,
            qlAmountB: this.recommendationFormGroup.controls.qlAmountB.value,
            qlUnitB: this.recommendationFormGroup.controls.qlUnitB.value,
            qQPPDB: this.recommendationFormGroup.controls.qQPPDB.value,
            qlTypeC: this.recommendationFormGroup.controls.qlTypeC.value,
            qlAmountC: this.recommendationFormGroup.controls.qlAmountC.value,
            qlUnitC: this.recommendationFormGroup.controls.qlUnitC.value,
            qQPPDC: this.recommendationFormGroup.controls.qQPPDC.value,
            ageLimit: this.recommendationFormGroup.controls.ageLimit.value,
            ageLimitMin: this.recommendationFormGroup.controls.ageLimitMin.value,
            ageLimitMinValue: this.recommendationFormGroup.controls.ageLimitMinValue.value,
            ageLimitMax: this.recommendationFormGroup.controls.ageLimitMax.value,
            ageLimitMaxValue: this.recommendationFormGroup.controls.ageLimitMaxValue.value,
            sexExclusion: this.recommendationFormGroup.controls.sexExclusion.value,
            coverageMiscMedD: this.recommendationFormGroup.controls.coverageMiscMedD.value,
            coverageMiscRxOtc: this.recommendationFormGroup.controls.coverageMiscRxOtc.value,
            coverageMiscNotCoveredMessageDescription: this.recommendationFormGroup.controls.coverageMiscNotCoveredMessageDescription.value,
            businessLobCd: BUSINESS_LOB_DESC_CD.get(this.blob)
        } as unknown as Recommendation;
        this._recommendationService.setNAOrZerosToUndefinedOnRecommendationChanges(recommendationChange);
        recommendationChange.statusCd = this.recommendationFormGroup.valid ? '2' : '1';
        this.recommendationStatus = recommendationChange.statusCd;
        const saveRecommendation$ = this._recommendationService
            .saveRecommendations([recommendationChange], this.appService.getSuperClientContext().id);
        this.saveData(saveRecommendation$, recommendationChange);
    }

    isNoneOrAgeLimitMin(value) {
        return [this.NA, null, undefined, '1','4', '0'].includes(value);
    }

    isNone(value) {
        return [this.NA, null, undefined].includes(value);
    }

    isNoneOrAgeLimitMax(value) {
        return [this.NA, null, undefined, '2','4','0'].includes(value);
    }

    destroySubscriptions() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
    }

    ngOnDestroy() {
        this.destroySubscriptions();
    }

    markMandatoryFieldsAsTouched() {
        const mandatoryFieldsFormControls = [
            this.recommendationFormGroup.controls['attachmentType'],
            this.recommendationFormGroup.controls['tier']
        ];
        mandatoryFieldsFormControls.forEach(formControl => formControl.markAsTouched());
    }

    onSaveError(saveRecommendation$: Observable<any>, recommendationChange: Recommendation) {
        this.bannerService.sendAlert({
            bannerType: CVSBannerType.Error,
            headline: 'System error: Changes have not been saved',
            body: 'Please try again',
            outletId: '#recommendationsBanner',
            bannerLinks: [
                {
                    linkText: 'Retry',
                    linkFunc: () => {
                        this.saveData(saveRecommendation$, recommendationChange);
                    }
                }
            ]
        });
    }

    saveData(save: Observable<any>, recommendationChange: Recommendation) {
        save
            .pipe(take(1))
            .subscribe({
                next: () => this.onSaveSuccess(recommendationChange),
                error: () => this.onSaveError(save, recommendationChange),
            });
    }

    onSaveSuccess(recommendationChange: Recommendation) {
        this.isFormSaved.emit(true);
        this._recommendationService.setRecommendationFormModified(false);
        this.recommendationObjects.set(this.formularyNumber, recommendationChange);
        if (this.recommendationFormGroup.valid) {
            this.bannerService.sendAlert({
                bannerType: CVSBannerType.Success,
                headline: 'Success',
                body: 'Changes have been saved',
                outletId: '#recommendationsBanner'
            });
        } else {
            this.bannerService.sendAlert({
                bannerType: CVSBannerType.Warning,
                headline: 'Saved with incomplete fields',
                body: 'Form was saved, but there are incomplete fields.',
                outletId: '#recommendationsBanner'
            });
        }
    }

    isValidMedicarePaSelection(){
        const value = this.recommendationFormGroup.controls.paSelection.value;
        return value === PA_SELECTION_MEDICARE.ONE.code ||
            value === PA_SELECTION_MEDICARE.TWO.code ||
            value === PA_SELECTION_MEDICARE.ONEPLUSTHREE.code ||
            value === PA_SELECTION_MEDICARE.TWOPLUSTHREE.code;
    }

}

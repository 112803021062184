import {Router} from '@angular/router';
import {PRODUCT_OVERVIEW_SOURCE} from '../../../enum/ProductOverviewSource';
import {HEADERS} from '../../product-overview.component';
import {Component, Injectable, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AppService} from '../../../service/app/app.service';
import {Subscription} from 'rxjs';
import {FormularyService} from '../../../service/formulary/formulary.service';
import {take} from 'rxjs/operators';
import {RecommendationService} from '../../../service/recommendation/recommendation.service';
import {Title} from '@angular/platform-browser';
import {IDeactivateComponent} from '../../../guards/IDeactivateComponent';
import { MedispanTrackerResponse } from 'src/app/model/MedispanTrackerResponse';
import {ADHOC_UPDATE_TYPES} from '../../../service/tracker-data/tracker-data.service';
import {BUSINESS_LOB_NAME} from '../../../default-values/DefaultValues';

@Injectable({
    providedIn: 'root'
})

@Component({
    selector: 'app-recommendations-form',
    templateUrl: './recommendations-form.component.html',
    styleUrls: ['./recommendations-form.component.scss']
})
export class RecommendationsFormComponent implements OnInit, OnDestroy, IDeactivateComponent {
    @ViewChild('exitRecommendationsModalRef') public exitRecommendationsModalRef: TemplateRef<any>;
    subscriptions: Subscription[] = [];
    formularyDataByBlob;
    selectedFormularyNumber;
    businessLobCD;
    statusId = undefined;
    blob;
    fromSource: PRODUCT_OVERVIEW_SOURCE = PRODUCT_OVERVIEW_SOURCE.RECOMMENDATION_FORM;
    selectedClientFormularyName;
    selectedFormularyTierCount;
    disableSaveButton = true;
    saveRecommendationsTooltip = 'Edit the form to save';
    isSavedButtonClicked = false;
    encryptedRecordId;
    medispanTrackerResponse: MedispanTrackerResponse;
    tabs: string[];
    isUpdateTypeGpiAdHoc!: boolean;

    constructor(
        private _appService: AppService,
        private _formularyService: FormularyService,
        private _router: Router,
        private _recommendationService: RecommendationService,
        private _titleService: Title,
    ) {
        this.initialize();
    }

    initialize() {
        this.businessLobCD = this._router.getCurrentNavigation().extras.state?.businessLobCD;
        this.blob = BUSINESS_LOB_NAME.get(this.businessLobCD);
        this.selectedFormularyNumber = this._router.getCurrentNavigation().extras.state?.formularyNumber;
        this.medispanTrackerResponse = this._router.getCurrentNavigation().extras?.state?.medispanTrackerResponse;
        this.tabs = this._router.getCurrentNavigation().extras?.state?.tabs;
        this.statusId = this.medispanTrackerResponse?.statusId;
        this.encryptedRecordId = this.medispanTrackerResponse?.encryptedRecordId;
        this.isUpdateTypeGpiAdHoc = this.medispanTrackerResponse?.updateType === ADHOC_UPDATE_TYPES.GPI_ADHOC ||
            this.medispanTrackerResponse?.updateType === ADHOC_UPDATE_TYPES.NDC_TO_GPI_ADHOC;
    }

    ngOnInit(): void {
        this._router.navigateByUrl('/recommendationsForm#backToPreviousPage');
        this.setHtmlPageTitle();
        const index = this._appService.getSuperClientContext().id;
        if (this.businessLobCD) {
            this.subscriptions.push(
                this._formularyService.getFormularyData(index).pipe(take(1)).subscribe(formularyResponse => {
                    this.formularyDataByBlob = this._formularyService
                        .getDistinctFormulariesByBusinessLob(this.businessLobCD, formularyResponse);
                }));
        }
        if (this.formularyDataByBlob) {
            if (this.selectedFormularyNumber) {
                const formularyFound =
                    this.formularyDataByBlob.find(formulary => formulary.formularyNumber === this.selectedFormularyNumber);
                this.selectedClientFormularyName = formularyFound.clientFormularyName;
                this.selectedFormularyTierCount = formularyFound.tierCount;
            } else {
                this.selectedClientFormularyName = this.formularyDataByBlob[0].clientFormularyName;
                this.selectedFormularyNumber = this.formularyDataByBlob[0].formularyNumber;
                this.selectedFormularyTierCount = this.formularyDataByBlob[0].tierCount;
            }
        }
    }
    setHtmlPageTitle(): void {
        this._titleService.setTitle(this.isUpdateTypeGpiAdHoc? 'CLR - GPI Recommendation Form' : 'CLR - Product Recommendation Form');
    }
    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    onPanelSelectionChange(event) {
        this.selectedClientFormularyName = event[0];
        this.selectedFormularyNumber = event[1];
        this.selectedFormularyTierCount = event[2];
    }

    navigateToRecommendationsTable() {
        if (!this.disableSaveButton && !this.isSavedButtonClicked) {
            this._recommendationService.setRecommendationFormModified(true);
        }
        if (this.isSavedButtonClicked) {
            this._recommendationService.newGetLoadRecommendationsComponentGridDataEvent(this.encryptedRecordId);
        }
        this._router.navigate(['productOverview'], {queryParams: {back: 'back'}});
    }

    saveRecommendations() {
        this._recommendationService.getTriggerRecommendationsSaveSubject().next(true);
    }

    onDisableSaveButton(event: boolean) {
        this.disableSaveButton = event;
    }

    isFormSaved(event: boolean) {
        this.isSavedButtonClicked = event;
    }

    canDeactivate(): boolean {
        return this._recommendationService.isRecommendationFormModified();
    }

    setDeactivatingState(state: boolean): void {
        this._recommendationService.setRecommendationFormModified(state);
    }
}

// eslint-disable-next-line no-shadow
export enum RECORD_STATUS {
    DEFAULT = 0,
    NO_ACTION = 10,
    IN_RESEARCH = 100,
    READY_FOR_CLINICAL_RECOMMENDATION = 200,
    READY_FOR_DISCUSSION = 300,
    PENDING_UM = 400,
    PENDING_FORMULARY = 500,
    PENDING_PT = 600,
    PENDING_SUBMISSION = 605,
    OPERATIONS_CHECKPOINT = 700,
    PENDING_CODING = 800,
    PENDING_TESTING = 900,
    PENDING_APPROVAL = 1000,
    ARCHIVE = 9999
}
export interface IRecordStatusWithDesc {
    status: RECORD_STATUS;
    description: string;
}
export const RECORD_STATUSES = [
    {
        status: RECORD_STATUS.DEFAULT,
        description: 'Default',
    },
    {
        status: RECORD_STATUS.IN_RESEARCH,
        description: 'In Research',
    },
    {
        status: RECORD_STATUS.READY_FOR_CLINICAL_RECOMMENDATION,
        description: 'Ready for Clinical Recommendation',
    },
    {
        status: RECORD_STATUS.READY_FOR_DISCUSSION,
        description: 'Ready for Discussion',
    },
    {
        status: RECORD_STATUS.PENDING_UM,
        description: 'Pending UM',
    },
    {
        status: RECORD_STATUS.PENDING_FORMULARY,
        description: 'Pending Formulary',
    },
    {
        status: RECORD_STATUS.PENDING_PT,
        description: 'Pending P&T',
    },
    {
        status: RECORD_STATUS.PENDING_SUBMISSION,
        description: 'Pending Submission',
    },
    {
        status: RECORD_STATUS.OPERATIONS_CHECKPOINT,
        description: 'Operations Checkpoint',
    },
    {
        status: RECORD_STATUS.PENDING_CODING,
        description: 'Pending Coding',
    },
    {
        status: RECORD_STATUS.PENDING_TESTING,
        description: 'Pending Testing',
    },
    {
        status: RECORD_STATUS.PENDING_APPROVAL,
        description: 'Pending Approval',
    },
    {
        status: RECORD_STATUS.NO_ACTION,
        description: 'No Action',
    },
    {
        status: RECORD_STATUS.ARCHIVE,
        description: 'Archive',
    }
] as IRecordStatusWithDesc[];

export function lookupRecordStatus(status: number): RECORD_STATUS {
    return RECORD_STATUSES.find(f => f.status.valueOf() === Number(status)).status;
}
export function lookupRecordStatusByDescription(description: string): RECORD_STATUS {
    return RECORD_STATUSES.find(recordStatus => recordStatus.description === description).status;
}

import {
    AfterContentChecked,
    ChangeDetectorRef,
    Component, EventEmitter,
    OnInit, Output,
} from '@angular/core';
import {WorkflowService} from '../../service/workflow/workflow.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FORMULARY_DATE_BOUNDARY} from '../../enum/FormularyDateBoundary';

@Component({
    selector: 'app-approve-and-submit-modal',
    templateUrl: './approve-and-submit-modal.component.html',
    styleUrls: ['./approve-and-submit-modal.component.scss']
})
export class ApproveAndSubmitModalComponent implements OnInit, AfterContentChecked {
    @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
    submissionForm: FormGroup;
    hasTruncNotesError = false;
    startDate = new Date(new Date().setDate(new Date().getDate() + 14));
    maxDate = new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE);
    minDate= new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE);
    notesValue='';

    constructor(public workflowService: WorkflowService, private fb: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.createSubmissionForm();
    }

    ngAfterContentChecked() {
        this.changeDetectorRef.detectChanges();
    }

    public createSubmissionForm() {
        this.submissionForm = this.fb.group({
            name: [''],
            effectiveDate: [this.startDate],
            notes: [''],
        });
        this.resetModalFields();
    }

    saveSubmissionForm() {
        this.addSubmissionModalValidators();
        this.submissionForm.markAllAsTouched();
        if (!this.submissionForm.controls['name'].errors?.required && !this.submissionForm.controls['effectiveDate'].errors?.required) {
            this.onModalApproveAndSubmit();
        }
    }

    resetModalFields() {
        this.submissionForm.reset({effectiveDate: this.startDate});
        this.submissionForm.controls['name'].removeValidators([Validators.required]);
        this.submissionForm.controls['name'].updateValueAndValidity({onlySelf: true});
        this.submissionForm.controls['effectiveDate'].removeValidators([Validators.required]);
        this.submissionForm.controls['effectiveDate'].updateValueAndValidity({onlySelf: true});
    }

    onModalCancel() {
        this.closeEvent.emit(false);
    }

    onModalApproveAndSubmit() {
        this.closeEvent.emit(true);
    }

    onNotesChanges() {
        if (this.submissionForm.controls['notes'].value?.length > 250) {
            if (this.notesValue.length === 0) {
                this.hasTruncNotesError = true;
            }
            this.submissionForm.controls['notes'].setValue(this.submissionForm.controls['notes'].value?.substring(0, 250));
        } else {
            this.hasTruncNotesError = false;
        }
        this.notesValue = this.submissionForm.controls['notes'].value;
    }

    stopWarning() {
        this.hasTruncNotesError = false;
    }

    private addSubmissionModalValidators() {
        this.submissionForm.controls['name'].addValidators([Validators.required]);
        this.submissionForm.controls['name'].updateValueAndValidity({onlySelf: true});
        this.submissionForm.controls['effectiveDate'].addValidators([Validators.required]);
        this.submissionForm.controls['effectiveDate'].updateValueAndValidity({onlySelf: true});
    }
}

import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {CvsAgGridComponent} from '../common/cvs-grid/cvs-ag-grid/cvs-ag-grid.component';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {ColDef, GridOptions} from '@ag-grid-community/core';
import {FiltersToolPanelModule} from '@ag-grid-enterprise/filter-tool-panel';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {LinesOfBusiness} from '../model/LinesOfBusiness';
import {Formulary} from '../model/Formulary';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-formulary-list',
    templateUrl: './formulary-list.component.html',
    styleUrls: ['./formulary-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FormularyListComponent implements OnInit {

    @ViewChild('formularyListGrid', {static: true}) formularyListGrid: CvsAgGridComponent;
    @Input() filteredFormularyData: Formulary[];

    paginationEnabled = false;
    domLayout = 'normal';
    modules = [SetFilterModule, ClientSideRowModelModule, FiltersToolPanelModule, MenuModule, ColumnsToolPanelModule];
    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
        headerClass: 'grid-formulary-Header',
        filter: true,
        menuTabs: ['filterMenuTab', 'columnsMenuTab', 'generalMenuTab']
    };
    columnDefs: ColDef[] = [
        {
            headerName: 'Formulary ID',
            field: 'formularyNumber'
        },
        {headerName: 'Formulary Name', field: 'name'},
        {
            headerName: 'Line of Business', valueGetter: params => this.displayLob(params?.data?.linesOfBusiness),
            field: 'linesOfBusiness'
        }
    ];

    gridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: this.columnDefs,
        pagination: false,
    };

    constructor() {
    }

    ngOnInit(): void {
    }

    displayLob(linesOfBusinesses: LinesOfBusiness[]): string[] {
        const lob: string[] = [];
        linesOfBusinesses?.forEach(data => {
            lob.push(data.description);
        });
        return lob;
    }
}



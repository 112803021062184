import {Component} from '@angular/core';
import {ITooltipAngularComp} from '@ag-grid-community/angular';
import {ICLRTooltipParams} from '../../custom-tooltip/custom-tooltip.component';
import {REPORT_STATUS} from '../../enum/ReportStatus';

@Component({
    selector: 'app-report-custom-tooltip',
    templateUrl: './report-custom-tooltip.component.html',
    styleUrls: ['./report-custom-tooltip.component.scss']
})
export class ReportCustomTooltipComponent implements ITooltipAngularComp {
    tooltipTextToDisplay = null;

    constructor() {
    }

    agInit(params: ICLRTooltipParams): void {
        if(params.data.status === REPORT_STATUS.ERROR){
            this.tooltipTextToDisplay = 'Please request a new report';
        }
    }
}

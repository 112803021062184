import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {ConfigurationService} from '../service/configuration/configuration.service';
import {MatStepper} from '@angular/material/stepper';
import {ReportService} from '../service/report/report.service';
import {AppService} from '../service/app/app.service';
import {saveAs} from 'file-saver';
import {finalize, take} from 'rxjs/operators';
import {FormularySetupComponent} from './formulary-setup/formulary-setup.component';
import {LobWorkflowComponent} from './lob-workflow/lob-workflow.component';
import {Router, RoutesRecognized} from '@angular/router';
import {CVSConfirmationDialogContentComponent} from 'angular-component-library';
import {MatDialog} from '@angular/material/dialog';
import {IDeactivateComponent} from '../guards/IDeactivateComponent';
import {ReviewPageComponent} from './review-page/review-page.component';
import {CacheService} from '../service/HttpDataCache';
import {ICLRRouteReuseStrategy} from '../ICLRRouteReuseStrategy';
import {CLIENT_CONFIGURATION_STEPPER} from '../enum/ClientConfigurationStepper';

@Component({
    selector: 'app-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.scss']
})

export class ConfigurationComponent implements OnInit, IDeactivateComponent {
    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild(FormularySetupComponent) childFormularyComponent: FormularySetupComponent;
    @ViewChild(LobWorkflowComponent) childLobWorkflowComponent: LobWorkflowComponent;
    @ViewChild(ReviewPageComponent) childReviewComponent: ReviewPageComponent;
    @ViewChild('confirmReviewFormRef') public confirmReviewFormRef: TemplateRef<any>;
    showStepper = false;
    currentStep = 0;
    displaySaveAndContinueButton = false;
    displayExport = false;
    showSpinner = false;
    comments='';
    isUpdated = false;
    lobLastTouched = '';
    clientConfigurationStepper = CLIENT_CONFIGURATION_STEPPER;

    constructor(public _configurationService: ConfigurationService,
        private _reportService: ReportService,
        private _appService: AppService,
        private _router: Router,
        private _matDialog: MatDialog) {
    }

    ngOnInit(): void {
        this.showSaveAndContinue();
        this.showExport();
    }

    editClientSetUpClick() {
        this.showStepper = true;
        this.showExport();
    }

    showExport() {
        if (this.showStepper === false) {
            this.displayExport = true;
        } else {
            this.displayExport = !(this.currentStep >= CLIENT_CONFIGURATION_STEPPER.FORMULARY_NAME
                && this.currentStep < CLIENT_CONFIGURATION_STEPPER.REVIEW);
        }
    }

    showSaveAndContinue() {
        this.displaySaveAndContinueButton = (this.currentStep >= CLIENT_CONFIGURATION_STEPPER.FORMULARY_NAME
            && this.currentStep < CLIENT_CONFIGURATION_STEPPER.REVIEW);
    }

    onStepChange(event: StepperSelectionEvent) {
        this.currentStep = event.selectedIndex;
        this.showSaveAndContinue();
        this.showExport();
    }

    saveChanges() {
        if (this.currentStep === 0) {
            this.childFormularyComponent.saveClientConfigurations();
            this.childFormularyComponent.isSaveSuccessful.asObservable().subscribe(isSaved => {
                if (isSaved) {
                    this.isUpdated = true;
                    CacheService.instance().clearCache();
                    (this._router.routeReuseStrategy as ICLRRouteReuseStrategy).delete('formularyformularySearch');
                }
            });
        } else if (this.currentStep === 1) {
            this.stepper.next();
        } else if (this.currentStep === 2) {
            this.stepper.next();
        } else if (this.currentStep === 3) {
            this.childLobWorkflowComponent.saveLobWorkflows();
        }
    }

    isSaveSuccessful(event: boolean) {
        if (event) {
            this.stepper.next();
        }
    }

    downloadClientSetupReport() {
        this.showSpinner = true;
        this._reportService.getClientSetupReport(this._appService
            .getSuperClientContext().id)
            .pipe(take(1), finalize(() => {
                this.showSpinner = false;
            })).subscribe((file) => {
                this.saveAs(file, 'Client Setup Export Report');
            });
    }

    saveAs(file: Blob, fileName: string) {
        return saveAs(new Blob([file], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}), fileName);
    }

    openNavigateWithoutSaveDialog(): any {
        return this._matDialog.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: 'Exit without saving changes?',
                body: 'Any changes made will not be saved when navigating out of this page.',
                actionLabel: 'Exit Without Saving',
                cancelLabel: 'Continue Editing',
            },
            disableClose: true
        });
    }

    navigateToPreviousPage() {
        if (this.currentStep === CLIENT_CONFIGURATION_STEPPER.FORMULARY_NAME) {
            if (this._configurationService.isFormularyConfigModified()) {
                this.showNavigationModal();
            } else {
                this.showMainPage();
            }
        } else if (this.currentStep === CLIENT_CONFIGURATION_STEPPER.PROGRAM_PRODUCT_DRUG_REQUIREMENTS) {
            this.showMainPage();
        } else if (this.currentStep === CLIENT_CONFIGURATION_STEPPER.LOB_WORKFLOW) {
            if (this._configurationService.isLobConfigModified()) {
                this.showNavigationModal();
            } else {
                this.showMainPage();
            }
        }
    }

    showNavigationModal() {
        const dialog = this.openNavigateWithoutSaveDialog();
        dialog.componentInstance.onConfirmClick.subscribe(() => {
            if (this.currentStep === CLIENT_CONFIGURATION_STEPPER.FORMULARY_NAME) {
                this._configurationService.setIsFormularyConfigModified(false);
            } else if (this.currentStep === CLIENT_CONFIGURATION_STEPPER.LOB_WORKFLOW) {
                this._configurationService.setIsLobConfigModified(false);
                this.childLobWorkflowComponent.multiSourceSetupControl.reset();
            }
            this.showMainPage();
        });
    }

    navigateToPreviousStep() {
        if (this.currentStep === CLIENT_CONFIGURATION_STEPPER.LOB_WORKFLOW) {
            if (this._configurationService.isLobConfigModified()) {
                this.showNavigationModalBackButton();
            } else {
                this.stepper.previous();
            }
        } else {
            this.stepper.previous();
        }
    }

    showNavigationModalBackButton() {
        const dialog = this.openNavigateWithoutSaveDialog();
        dialog.componentInstance.onConfirmClick.subscribe(() => {
            if (this.currentStep === CLIENT_CONFIGURATION_STEPPER.LOB_WORKFLOW) {
                this._configurationService.setIsLobConfigModified(false);
                this.childLobWorkflowComponent.multiSourceSetupControl.reset();
            }
            this.stepper.previous();
        });
    }

    navigateReviewToPreviousPage() {
        if (this._configurationService.isClientConfigurationModified()) {
            this.showReviewNavigationModal();
        } else {
            this.showMainPage();
        }
    }

    showReviewNavigationModal() {
        const dialog = this.openReviewNavigateWithoutConfirmDialog(this.confirmReviewFormRef);
        dialog.componentInstance.onConfirmClick.subscribe(() => {
            this._configurationService.setIsLobConfigModified(false);
            this._configurationService.setIsFormularyConfigModified(false);
            this.showMainPage();
        });
    }

    navigateReviewToPreviousStep() {
        if (this._configurationService.isClientConfigurationModified()) {
            this.showReviewNavigationModalBackButton();
        } else {
            this.stepper.previous();
        }
    }

    showReviewNavigationModalBackButton() {
        const dialog = this.openReviewNavigateWithoutConfirmDialog(this.confirmReviewFormRef);
        dialog.componentInstance.onConfirmClick.subscribe(() => {
            this._configurationService.setIsFormularyConfigModified(false);
            this._configurationService.setIsLobConfigModified(false);
            this.stepper.previous();
        });
    }

    openReviewNavigateWithoutConfirmDialog(confirmReviewFormRef: TemplateRef<any>): any {
        return this._matDialog.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: 'Exit without confirming?',
                actionLabel: 'Exit Without Confirming',
                cancelLabel: 'Continue Editing',
                templatePortalContent: confirmReviewFormRef,
            },
            disableClose: true
        });
    }

    showMainPage() {
        this.showStepper = false;
        this.showExport();
        this.stepper.reset();
    }

    openConfirmDialog(reviewFormRef: TemplateRef<any>): any {
        const confirmationDialog = this._matDialog.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: 'Confirmation',
                actionLabel: 'Approve Setup',
                cancelLabel: 'Cancel',
                templatePortalContent: reviewFormRef
            },
            disableClose: true,
        });
        confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
            this.childReviewComponent.submitClientSetupRequest(this.comments);
        });
        confirmationDialog.componentInstance.onCancelClick.subscribe(() => {
            this.comments = '';
            confirmationDialog.close();
        });
    }

    canDeactivate(): boolean {
        if (this.currentStep === CLIENT_CONFIGURATION_STEPPER.FORMULARY_NAME) {
            return this._configurationService.isFormularyConfigModified();
        } else if (this.currentStep === CLIENT_CONFIGURATION_STEPPER.LOB_WORKFLOW) {
            return this._configurationService.isLobConfigModified();
        }
        return this._configurationService.isClientConfigurationModified();
    }

    setDeactivatingState(state: boolean): void {
        this._configurationService.setIsFormularyConfigModified(state);
        this._configurationService.setIsLobConfigModified(state);
    }

    setUpdated(event: boolean) {
        if (event) {
            this.isUpdated = false;
        } else {
            this.isUpdated = true;
        }
    }

    setLobName(lobDescription: string) {
        this.lobLastTouched = lobDescription;
    }
}

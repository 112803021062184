import {
    AfterContentChecked,
    ChangeDetectorRef,
    Component, EventEmitter,
    OnInit, Output,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FORMULARY_DATE_BOUNDARY} from '../../../enum/FormularyDateBoundary';
import {validDateRange} from '../../product-drug-requirements/dateRangeValidation';
import {MatOptionSelectionChange} from '@angular/material/core';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {ExcelExportModule} from '@ag-grid-enterprise/excel-export';
import {ColDef, GridOptions} from '@ag-grid-community/core';
import {FORMULARY_CODING_TYPE} from '../../../enum/FormularyCodingType';

@Component({
    selector: 'app-formulary-requirements-modal',
    templateUrl: './formulary-requirements-modal.component.html',
    styleUrls: ['./formulary-requirements-modal.component.scss']
})
export class FormularyRequirementsModalComponent implements OnInit, AfterContentChecked {
    @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
    formularyRequirementForm: FormGroup;
    coverageTypes = [
        'Coverage',
        'Non-Formulary Reject',
        'Exclusion',
    ];

    codingTypes = [
        'Plan Formulary GPI List Only (Restrict NDC)',
        'Standard Formulary NDC List Only',
        'Standard Formulary GPI List Only (No Restrict NDC)',
        'Standard Formulary GPI List Only (Restrict NDC)',
        'Standard Formulary GPI/NDC List',
        'Remove Coding',
    ];
    codingTypeSelected: string;

    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
        suppressSizeToFit: true,
        cellClass: 'excelString'
    };
    modules = [SetFilterModule, ColumnsToolPanelModule, ClientSideRowModelModule,
        MenuModule, ClipboardModule, ExcelExportModule];
    popupParent: HTMLElement | null = document.querySelector('body');
    overlayNoRowsTemplate = `<span><img src="/assets/error-f--s.svg" width="25px" height="25px"></br><b>No results found.</b></span>`;

    columnDefs: ColDef[] = [
        {
            headerName: 'Associated Formulary Number(s)', field: 'associatedNumber', filter: 'agSetColumnFilter', width: 290
        },
        {
            headerName: 'Line of Business', field: 'lineOfBusiness', filter: 'agSetColumnFilter', width: 250,
        },
        {
            headerName: 'Formulary List Attachment Date', field: 'attachmentDate', filter: 'agSetColumnFilter', width: 270,
        },
    ];

    formularyRequirementsGridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: this.columnDefs,
        enableCellTextSelection: true,
        suppressLoadingOverlay: true,
        excelStyles: [{
            id: 'excelString',
            dataType: 'String'
        }],
        defaultExcelExportParams: {
            sheetName: undefined
        }
    };
    formularyRowData= [];

    constructor(private fb: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.createFormularyRequirementsForm();
    }

    ngAfterContentChecked() {
        this.changeDetectorRef.detectChanges();
    }

    public createFormularyRequirementsForm() {
        this.formularyRequirementForm = this.fb.group({
            name: [''],
            description: [''],
            viewStartDate: [new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE), [Validators.required, validDateRange]],
            viewEndDate: [new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE), [Validators.required, validDateRange]],
            effectiveStartDate: [new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE), [Validators.required, validDateRange]],
            effectiveEndDate: [new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE), [Validators.required, validDateRange]],
            category: [''],
            coding: [''],
            cardList: [''],
        });
        this.resetFormularyRequirementModalFields();
    }

    onModalCancel() {
        this.closeEvent.emit(false);
    }

    saveFormularyRequirement() {
        this.addFormularyRequirementModalValidators();
        this.formularyRequirementForm.markAllAsTouched();
    }

    selectedCoding(event: MatOptionSelectionChange) {
        if (event.isUserInput) {
            this.codingTypeSelected = event.source.value;
        }
    }

    getCardLabel(codingTypeSelected: string) {
        if (codingTypeSelected === this.codingTypes[0]) {
            return FORMULARY_CODING_TYPE.PLAN_GPI;
        } else if (codingTypeSelected === this.codingTypes[1] || codingTypeSelected === this.codingTypes[4]) {
            return FORMULARY_CODING_TYPE.PREFERRED_NDC;
        } else if (codingTypeSelected === this.codingTypes[2] || codingTypeSelected === this.codingTypes[3]) {
            return FORMULARY_CODING_TYPE.PREFERRED_GPI;
        }
    }
    private addFormularyRequirementModalValidators() {
        this.formularyRequirementForm.controls['name'].addValidators([Validators.required]);
        this.formularyRequirementForm.controls['name'].updateValueAndValidity({onlySelf: true});
        this.formularyRequirementForm.controls['description'].addValidators([Validators.required]);
        this.formularyRequirementForm.controls['description'].updateValueAndValidity({onlySelf: true});
        this.formularyRequirementForm.controls['category'].addValidators([Validators.required]);
        this.formularyRequirementForm.controls['category'].updateValueAndValidity({onlySelf: true});
        this.formularyRequirementForm.controls['coding'].addValidators([Validators.required]);
        this.formularyRequirementForm.controls['coding'].updateValueAndValidity({onlySelf: true});
        if (this.codingTypeSelected) {
            this.formularyRequirementForm.controls['cardList'].addValidators([Validators.required]);
            this.formularyRequirementForm.controls['cardList'].updateValueAndValidity({onlySelf: true});
        }
    }

    private resetFormularyRequirementModalFields() {
        this.formularyRequirementForm.reset({
            viewStartDate: new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE),
            viewEndDate: new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE),
            effectiveStartDate: new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE),
            effectiveEndDate: new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE)
        });
        this.formularyRequirementForm.controls['name'].removeValidators([Validators.required]);
        this.formularyRequirementForm.controls['name'].updateValueAndValidity({onlySelf: true});
        this.formularyRequirementForm.controls['description'].removeValidators([Validators.required]);
        this.formularyRequirementForm.controls['description'].updateValueAndValidity({onlySelf: true});
        this.formularyRequirementForm.controls['category'].removeValidators([Validators.required]);
        this.formularyRequirementForm.controls['category'].updateValueAndValidity({onlySelf: true});
        this.formularyRequirementForm.controls['coding'].removeValidators([Validators.required]);
        this.formularyRequirementForm.controls['coding'].updateValueAndValidity({onlySelf: true});
        this.formularyRequirementForm.controls['cardList'].removeValidators([Validators.required]);
        this.formularyRequirementForm.controls['cardList'].updateValueAndValidity({onlySelf: true});
    }
}

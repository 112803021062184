import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CVSConfirmationDialogContentComponent, NavListItem} from 'angular-component-library';
import {RecommendationService} from '../../../service/recommendation/recommendation.service';
import {MatDialog} from '@angular/material/dialog';
import { BUSINESS_LOB } from 'src/app/enum/BusinessLob';

export interface CustomNavListItem extends NavListItem {
    formularyName?: string;
    formularyNumber?: string;
    tierCount?: string;
}

@Component({
    selector: 'app-recommendation-left-navigation',
    templateUrl: './recommendation-left-navigation.component.html',
    styleUrls: ['./recommendation-left-navigation.component.scss']
})
export class RecommendationLeftNavigationComponent implements OnInit, OnChanges {

    @Input() formularies = [];
    @Input() selectedFormularyNumber: string;
    @Output() selectedFormularyEmitter = new EventEmitter();
    @Input() blob: string;

    listPanelItems: CustomNavListItem[] = [];
    childClickIndex = -1;
    toolTipMessage = 'Must save form before navigating to other formularies.';

    protected readonly BUSINESS_LOB = BUSINESS_LOB;

    constructor(private _recommendationService: RecommendationService, private dialog: MatDialog) {}

    ngOnInit(): void {
        this.populatePanelItems();
        if (this.selectedFormularyNumber) {
            this.listPanelItems.filter(item => item.linkTitle.endsWith(this.selectedFormularyNumber))
                .map(item => item.isExpanded = true);
        } else {
            if (this.listPanelItems.length > 0) {
                this.listPanelItems[0].isExpanded = true;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedFormularyNumber) {
            this.childClickIndex = -1;
        }
    }

    populatePanelItems() {
        if (this.formularies) {
            this.formularies.forEach(formulary => {
                this.listPanelItems.push({
                    linkTitle: `${formulary.clientFormularyName} ${formulary.formularyNumber}`,
                    link: '/',
                    isExpanded: false,
                    formularyName: formulary.clientFormularyName,
                    formularyNumber: formulary.formularyNumber,
                    tierCount: formulary.tierCount,
                    children: [
                        {
                            link: '/recommendationsForm#tierAttachment',
                            linkTitle: 'Attachment / Coverage'
                        },
                        {
                            link: '/recommendationsForm#qualityLimit',
                            linkTitle: 'Quantity Limit'
                        },
                        {
                            link: '/recommendationsForm#paSelection',
                            linkTitle: 'Prior Authorization'
                        },
                        {
                            link: '/recommendationsForm#stepTherapy',
                            linkTitle: 'Step Therapy'
                        },
                        {
                            link: '/recommendationsForm#ageLimit',
                            linkTitle: 'Age Limit'
                        },
                        {
                            link: '/recommendationsForm#sexExclusion',
                            linkTitle: 'Sex Exclusion'
                        }
                    ]
                });
            });
        }
    }

    toggleExpansion(item: CustomNavListItem) {
        if (this._recommendationService.isRecommendationFormModified()) {
            const dialog = this.openNavigateWithoutSaveDialog();
            dialog.componentInstance.onConfirmClick.subscribe(() => {
                this.executeToggleExpansion(item);
            });
            dialog.componentInstance.onCancelClick.subscribe(() => {
                item.isExpanded = true;
                this.listPanelItems.some(element => {
                    if (element === item) {
                        setTimeout(() => {
                            element.isExpanded = false;
                        });
                        return true;
                    }
                });
            });
        } else {
            this.executeToggleExpansion(item);
        }
    }

    openNavigateWithoutSaveDialog(): any {
        return this.dialog.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: 'Exit without saving changes?',
                body: 'Any changes made will not be saved when navigating out of this page.',
                actionLabel: 'Exit Without Saving',
                cancelLabel: 'Continue Editing',
            },disableClose: true
        });
    }

    executeToggleExpansion(item: CustomNavListItem) {
        if (item.children) {
            if (item.isExpanded) {
                item.isExpanded = false;
            } else {
                this.listPanelItems.forEach(element => {
                    if (element !== item && element.isExpanded) {
                        element.isExpanded = false;
                    }
                });
                item.isExpanded = true;
                this.selectedFormularyEmitter.emit([item.formularyName, item.formularyNumber, item.tierCount]);
            }
        }
    }

    getSubItemStyles(childItem: NavListItem, childIndex: number): object {
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'disabled-sub-item': childItem.disabled,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'is-child-selected': childIndex === this.childClickIndex
        };
    }
    getPanelStyles(item: NavListItem): object {
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'disabled-expansion-panel': item.disabled,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'has-child-selected': this.childClickIndex > -1
        };
    }
}

import {Component, OnInit} from '@angular/core';
import {AuthorizationService, BrandTypes, CVSAuthConfig, CVSBaseConfig, MenuState, SideNavConfig} from 'angular-component-library';
import {UserService} from './service/user/user.service';
import {ENVIRONMENT} from './environments/environment';
import {CustomTooltipComponent} from './custom-tooltip/custom-tooltip.component';
import {SuperClient} from './model/SuperClient';
import {FEATURE_NAME, FeatureFlagService} from './service/feature-flag/feature-flag.service';
import {UserPreferenceService} from './service/user-preference/user-preference.service';
import {AppService} from './service/app/app.service';
import {NavItemConfig} from 'angular-component-library/completed/side-nav/SideNavConfig';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {CustomWelcomeComponent} from './common/custom-welcome/custom-welcome.component';
import { NavigationEnd, Router } from '@angular/router';
import {take, filter} from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    components: [
        { 'customTooltipComponent': CustomTooltipComponent }
    ];

    authConfig: CVSAuthConfig;
    baseConfig: CVSBaseConfig;
    sideNavConfig: SideNavConfig;
    currentSuperClient: string;
    updatedWorkflowUrl = 'workflow';
    previousUrl: string = null;
    currentUrl: string = null;

    private year = new Date().getFullYear();
    constructor(private authorizationService: AuthorizationService,
        private userService: UserService,
        private featureFlagService: FeatureFlagService,
        private userPreferenceService: UserPreferenceService,
        private appService: AppService,
        private matIconRegistry: MatIconRegistry,
        private router: Router,
        private domSanitizer: DomSanitizer) {
        this.router.events?.pipe(filter(event => event instanceof NavigationEnd), take(1)).subscribe((event: any) => {
            if (event.id === 1 && event.url === event.urlAfterRedirects) {
                window.location.assign('/');
            }
        });
        this.router.events?.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
            this.setupWorkflowMenu();
        });

        this.matIconRegistry.addSvgIcon('unchecked-circle',
            domSanitizer.bypassSecurityTrustResourceUrl('/assets/unchecked-circle.svg'));

        this.authConfig = {
            authenticationEnabled: ENVIRONMENT.authenticationEnabled,
            ssoBaseUrl: ENVIRONMENT.ssoBaseUrl,
            authorizationUrl: ENVIRONMENT.authorizationUrl,
            clientId: ENVIRONMENT.clientId,
            idle: ENVIRONMENT.idle,
            timeOut: ENVIRONMENT.timeOut,
            tokenUrl: ENVIRONMENT.tokenUrl,
            useAccessToken: ENVIRONMENT.useAccessToken,
            discoveryDocumentUrl: ENVIRONMENT.discoveryDocumentUrl,
            loginUrl: ENVIRONMENT.loginUrl,
            logoutUrl: ENVIRONMENT.logoutUrl,
            issuer: ENVIRONMENT.issuer,
            accessDeniedMessage: ENVIRONMENT.accessDeniedMessage,
            scope: ENVIRONMENT.scope
        } as CVSAuthConfig;

        this.baseConfig = {
            homeRoute: '',
            appName: 'Clinical Requirements (CLR)' + ENVIRONMENT.region,
            unbranded: false,
            brand: BrandTypes.CAREMARK,
            footerText: `© Copyright ${this.year} CVS Caremark`,
            headerPortal: CustomWelcomeComponent
        } as CVSBaseConfig;
        this.sideNavConfig = {
            sideNavItems: [
                {
                    name: 'Formulary Search',
                    icon: 'formularies--s',
                    routerLink: 'formulary/formularySearch'
                }, {
                    name: 'Drug Search',
                    icon: 'drug--s',
                    routerLink: 'formulary/drugSearch'
                }, {
                    name: 'myPBM',
                    icon: 'app--s',
                    routerLink: 'myPBM'
                }
            ],
            iconMenu: true,
            menuState: MenuState.Open
        } as SideNavConfig;
    }

    ngOnInit(): void {
        this.authorizationService.authorizationSuccess.subscribe( () => {
            this.appService.initializeServices().subscribe(mapResult => {
                this.userService.getSuperClientContextSubject().subscribe(superClient => {
                    this.currentSuperClient = superClient.superClientCode;
                    this.setSuperClientBanner(superClient);
                    this.setupMenu();
                });
            });
        });

        if (ENVIRONMENT.appDynamicKey !== null) {
            this.loadAppDynamicScript();
        }
    }

    setupMenu() {
        if (this.userService.getUser().clients.length > 1 && !this.hasMenuItem('Client Selection')) {
            this.addMenuItem(0, {
                name: 'Client Selection',
                icon: 'profile--s',
                routerLink: 'clientSelection'
            });
        }

        if (this.featureFlagService.isFeatureEnabled(FEATURE_NAME.WORKFLOW_PAGE) && !this.hasMenuItem('Workflow')) {
            if (this.userService.getUser().internalUser || this.featureFlagService.isFeatureEnabled(FEATURE_NAME.WORKFLOW_PAGE_EXTERNAL)) {
                this.addMenuItem(Math.max(this.menuItemIndex('Client Selection')) + 1, {
                    name: 'Workflow',
                    icon: 'lock--s',
                    routerLink: 'workflow'
                });
            }
        }

        if (this.featureFlagService.isFeatureEnabled(FEATURE_NAME.REPORTS_PAGE) && !this.hasMenuItem('Reports')) {
            this.addMenuItem(Math.max( this.menuItemIndex('Drug Search')) + 1, {
                name: 'Reports',
                icon: 'file-xls--s',
                routerLink: 'reports'
            });
        }

        if (this.userService.hasAdminPermission() && this.hasMenuItem('Reports') && !this.hasMenuItem('Client Configuration')) {
            this.addMenuItem(Math.max(this.menuItemIndex('Reports')) + 1, {
                name: 'Client Configuration',
                icon: 'gear--s',
                routerLink: 'configuration'
            });
        } else if (this.userService.hasAdminPermission() && !this.hasMenuItem('Reports') && !this.hasMenuItem('Client Configuration')) {
            this.addMenuItem(Math.max(this.menuItemIndex('Drug Search')) + 1, {
                name: 'Client Configuration',
                icon: 'gear--s',
                routerLink: 'configuration'
            });
        }
    }

    setupWorkflowMenu() {
        if (this.previousUrl !== null && this.currentUrl !==null) {
            if (this.previousUrl.includes('workflow')) {
                if (this.currentUrl.includes('clientSelection') || this.currentUrl.includes('configuration')) {
                    this.updatedWorkflowUrl = null;
                } else {
                    this.updatedWorkflowUrl = this.previousUrl;
                }
            } else {
                this.updatedWorkflowUrl = null;
            }
            this.addRemoveWorkflowItem();
        }
    }

    hasMenuItem(name: string): boolean {
        return this.menuItemIndex(name) !== -1;
    }

    menuItemIndex(name: string): number {
        return this.sideNavConfig.sideNavItems.findIndex(i => i.name === name);
    }

    addMenuItem(index: number, menu: NavItemConfig) {
        this.sideNavConfig.sideNavItems.splice(index, 0, menu);
    }

    removeMenuItem(name: string) {
        const index = this.menuItemIndex(name);
        if (index !== -1) {
            this.sideNavConfig.sideNavItems.splice(index, 1);
        }
    }

    setSuperClientBanner(superClient: SuperClient) {
        this.baseConfig.appName = `Clinical Requirements (CLR)${ENVIRONMENT.region} - ${superClient.clientName}`;
    }

    addRemoveWorkflowItem() {
        if (this.hasMenuItem('Workflow')) {
            this.removeMenuItem('Workflow');
            this.addMenuItem(Math.max(this.menuItemIndex('Client Selection')) + 1, {
                name: 'Workflow',
                icon: 'lock--s',
                routerLink: this.updatedWorkflowUrl===null? 'workflow' : this.updatedWorkflowUrl
            });
        }
    }

    private loadAppDynamicScript() {
        const script1 = document.createElement('script');
        script1.type = 'text/javascript';
        script1.innerHTML =
            'window["adrum-start-time"] = new Date().getTime(); ' +
            '(function(config){ ' +
            'config.appKey = "' +
            ENVIRONMENT.appDynamicKey +
            '"; ' +
            'config.adrumExtUrlHttp = "http://cdn.appdynamics.com"; ' +
            'config.adrumExtUrlHttps = "https://cdn.appdynamics.com"; ' +
            'config.beaconUrlHttp = "http://pdx-col.eum-appdynamics.com"; ' +
            'config.beaconUrlHttps = "https://pdx-col.eum-appdynamics.com"; ' +
            'config.useHTTPSAlways = true; ' +
            'config.resTiming = {"bufSize": 200, "clearResTimingOnBeaconSend": true}; ' +
            'config.maxUrlLength = 512; ' +
            '})(window["adrum-config"] || (window["adrum-config"] = {}));';
        const script2 = document.createElement('script');
        script2.src = '//cdn.appdynamics.com/adrum/adrum-23.3.0.4265.js';
        const head = document.getElementsByTagName('head')[0];
        if (head !== null && head !== undefined) {
            document.head.appendChild(script1);
            document.head.appendChild(script2);
        }
    }
}

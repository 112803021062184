import { Injectable } from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {ENVIRONMENT} from '../../environments/environment';

import {
    BehaviorSubject,
    Observable,
    Subject,
} from 'rxjs';
import {map, take, tap, shareReplay} from 'rxjs/operators';

import {Recommendation} from '../../model/Recommendation';
import {SupplementalList} from '../../model/SupplementalList';
import {MedispanTrackerResponse} from '../../model/MedispanTrackerResponse';
import {GpiLikeProduct} from '../../model/GpiLikeProduct';

@Injectable({
    providedIn: 'root'
})
export class RecommendationService {
    medispanTrackerResponse: MedispanTrackerResponse;
    NA = 'N/A';
    NO_CHANGE = 'No Change';
    recommendations$: Observable<any>;
    private triggerRecommendationsSaveSubject: Subject<Boolean> = new Subject();
    private loadRecommendationsComponentGridDataSubject: Subject<string> = new Subject<string>();

    private _recommendationFormModified = false;
    constructor(private cvsHttpClient: CVSHttpClient) {}

    saveRecommendations(recommendations: Recommendation[], superClientIndex: number): Observable<any> {
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}recommendations/save/${superClientIndex}`, recommendations);
    }
    getRecommendationGridData(encryptedRecordId: string): Observable<any>{
        this.recommendations$ = this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}recommendations/${encryptedRecordId}`).pipe(
            take(1)
        );
        return this.recommendations$;
    }

    saveSupplementalList(encryptedRecordId: string, supplementalList: SupplementalList): Observable<any> {
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}supplementalList/save`, {encryptedRecordId, ...supplementalList});
    }

    copyRecommendationAndSupplemental(superclientIndex: number,sourceRecordId: string,
        sourceFormularyIds: string[], targetRecordIds: string[] ) {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}recommendations/copyRecommendationAndSupplemental/${superclientIndex}`,{
            sourceRecordId, sourceFormularyIds, targetRecordIds
        });
    }

    copyRecommendation(superclientIndex: number,sourceRecordId: string, sourceFormularyId: string, targetFormularyIds: string[] ) {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}recommendations/copyRecommendation/${superclientIndex}`,{
            sourceRecordId, sourceFormularyId, targetFormularyIds
        });
    }


    getSupplementalList(encryptedRecordId: string): Observable<SupplementalList> {
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}supplementalList/${encryptedRecordId}`);
    }

    setMedispanTrackerResponse(medispanTrackerResponse: MedispanTrackerResponse): void {
        this.medispanTrackerResponse = medispanTrackerResponse;
    }

    getMedispanTrackerResponse(): MedispanTrackerResponse{
        return this.medispanTrackerResponse;
    }

    getTriggerRecommendationsSaveSubject(): Subject<Boolean> {
        return this.triggerRecommendationsSaveSubject;
    }

    getLoadRecommendationsComponentGridDataObservable(): Observable<any> {
        return this.loadRecommendationsComponentGridDataSubject;
    }

    newGetLoadRecommendationsComponentGridDataEvent(event) {
        this.loadRecommendationsComponentGridDataSubject.next(event);
    }

    setNAOrZerosToUndefinedOnRecommendationChanges(rc) {
        Object.keys(rc).forEach(key => {
            if (rc[key] === this.NA || rc[key] === this.NO_CHANGE) {
                rc[key] = undefined;
            }
        });
        return rc;
    }

    getFormularyByNumber(formularyNumber: string) {
        return this.recommendations$.pipe(
            map((formularies) =>
                formularies?.filter((formulary) => formulary.formularyNumber === formularyNumber)
            )
        );
    }

    getGpiLikeProducts(superclientIndex: number,encryptedRecordId: string, lob: string, gpi: string): Observable<GpiLikeProduct[]> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}recommendations/gpiLikeProducts/${superclientIndex}/${lob}/${gpi}/${encryptedRecordId}`);
    }

    isRecommendationFormModified(): boolean {
        return this._recommendationFormModified;
    }

    setRecommendationFormModified(value: boolean) {
        this._recommendationFormModified = value;
    }

}

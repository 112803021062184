import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {
    AgGridEvent,
    ColDef,
    ColGroupDef,
    Column,
    ColumnGroupOpenedEvent,
    ColumnMovedEvent,
    ColumnResizedEvent,
    ColumnVisibleEvent,
    FirstDataRenderedEvent,
    GridOptions,
    IDetailCellRendererParams,
    IRowNode,
    RowDataUpdatedEvent,
    SelectionChangedEvent
} from '@ag-grid-community/core';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {RichSelectModule} from '@ag-grid-enterprise/rich-select';
import {interval, Observable, Subject} from 'rxjs';
import {debounce, take} from 'rxjs/operators';
import {MasterDetailModule} from '@ag-grid-enterprise/master-detail';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {CustomNavigationComponent} from '../common/custom-navigation/custom-navigation.component';
import {FormularyDrugAttachmentResponse} from '../model/FormularyDrugAttachmentResponse';
import {dateTruncation, gridHyperLinkSelector, oracleDateFormat} from '../utility/utility';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import _ from 'lodash';
import {AgGridAngular} from '@ag-grid-community/angular';
import {UserPreferenceService} from '../service/user-preference/user-preference.service';
import {UserPreference} from '../model/UserPreference';
import {CVSAlertComponentData, CVSAlertService, CVSAlertType} from 'angular-component-library';
import {FormularyService} from '../service/formulary/formulary.service';
import {PRODUCT_OVERVIEW_SOURCE} from '../enum/ProductOverviewSource';
import {ExcelExportModule} from '@ag-grid-enterprise/excel-export';
import {CVSConfirmationDialogData} from 'angular-component-library/completed/confirmation-dialog/confirmation-dialog-content.component';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../service/user/user.service';
import {lookupRecordStatus, RECORD_STATUS} from '../enum/RecordStatus';
import {
    CLRConfirmationDialogContentComponent,
    CLRConfirmationDialogValueObject
} from '../common/create-adhoc-update-modal/create-adhoc-update-modal.component';
import {CommonCommon} from '../model/CommonCommon';
import {TrackerDataService} from '../service/tracker-data/tracker-data.service';
import {WorkflowService} from '../service/workflow/workflow.service';
import {AttachmentData} from '../model/AttachmentData';
import {ConvertGpiToNdcService} from '../common/create-adhoc-update-modal/convert-gpi-to-ndc-list/convert-gpi-to-ndc-list.component';
import {AdhocCreationDetail} from '../model/AdhocCreationDetail';
import {GridDisplayModalService} from '../common/grid-display-modal/grid-display-modal.component';
import {BUSINESS_LOB_NAME} from '../default-values/DefaultValues';
import {MedispanTrackerResponse} from '../model/MedispanTrackerResponse';
import {AdhocCreation} from '../model/AdhocCreation';
import { Location } from '@angular/common';

export interface IAdhocConflictDetail {
    matchingNDC?: string;
    matchingWeekDate?: string;
    statusId?: number;
    matchingUpdateType?: string;
    matchingEncryptedRecordId?: string;
    matchingNote?: string;
    matchingCombinedId?: Map<string, string>;
    matchingGPI?: string;
}

export interface IAdhocConflictRowData {
    adHocItem: string;
    existingNdcGpi: string;
    businessLob: string;
    adhocDetail: IAdhocConflictDetail;
    selectable: boolean;
    duplicateForToday: boolean;
}

@Component({
    selector: 'app-drug-list',
    templateUrl: './drug-list.component.html',
    styleUrls: ['./drug-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DrugListComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('drugListGrid', {static: true}) drugListGrid: AgGridAngular;
    @ViewChild('adhocModalRef') public adhocModalRef: TemplateRef<any>;
    @ViewChild('adhocDuplicateGridModalRef') public adhocDuplicateGridModalRef: TemplateRef<any>;
    @Input() formularyDrugAttachmentResponse: FormularyDrugAttachmentResponse[];
    @Input() detailCellRendererParams: IDetailCellRendererParams = undefined;
    @Input() masterDetail = false;
    @Input() filteredFormularyData;
    @Input() selectedLobs: string[];
    currentSearchCriteria = {formularies: []};
    formularyDataService: FormularyService;
    userPreferenceService: UserPreferenceService;
    trackerDataService: TrackerDataService;
    overlayNoRowsTemplate = `<span><img src="/assets/error-f--s.svg" width="25px" height="25px"></br><b>No results found.</b></span>`;
    modules = [SetFilterModule, ColumnsToolPanelModule, MenuModule, ClientSideRowModelModule,
        RichSelectModule, MasterDetailModule, ClipboardModule, ExcelExportModule];
    SUCCESS_HEADLINE = 'View Saved';
    SUCCESS_BODY = 'This is now your default view.';
    WARNING_HEADLINE = 'View Not Saved';
    WARNING_BODY = 'Something didn\'t work quite right, Try again later.';
    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
        headerClass: 'grid-Drug-Header',
        filter: true,
        suppressSizeToFit: true,
        cellClass: 'excelString'
    };
    columnDefs: ColDef[] = [];
    source: string;
    adHocButtonDisabled = true;
    selectedRows: IRowNode[] = [];
    detailSelectedRows: { api: any; selectedRows: IRowNode[] }[] = [];
    createAdHocUpdateModalRef: CLRConfirmationDialogContentComponent;
    popupParent: HTMLElement | null = document.querySelector('body');
    adhocUpdateCount;
    convertAttachmentNDCTypeToGPI = false;
    convertAttachmentGPITypeToNDC = false;

    drugListGridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: this.columnDefs,
        pagination: false,
        suppressLoadingOverlay: true,
        embedFullWidthRows: true,
        detailRowAutoHeight: true,
        getRowClass: params => {
            if (params.node.rowIndex % 2 !== 0) {
                return 'shaded-effect';
            }
        },
        enableCellTextSelection: true,
        excelStyles: [{
            id: 'excelString',
            dataType: 'String'
        }],
        defaultExcelExportParams: {
            sheetName: undefined,
            processCellCallback: params => (params.value === '@showChildDetail') ? '' : params.value
        }
    };
    tierDescriptionMap = [
        {tierCode: 'B', tierDesc: 'Brand'},
        {tierCode: 'BG', tierDesc: 'Brand with Generic Availability'},
        {tierCode: 'BS', tierDesc: 'Brand with No Generic'},
        {tierCode: 'PN', tierDesc: 'Covered under PENCD'},
        {tierCode: 'PA', tierDesc: 'Formulary PA Required'},
        {tierCode: 'FC', tierDesc: 'Firmly Coverage Excluded'},
        {tierCode: 'G', tierDesc: 'Generic'},
        {tierCode: 'HC', tierDesc: 'High Cost Tier'},
        {tierCode: 'BF', tierDesc: 'Non-Formulary Brand'},
        {tierCode: 'GF', tierDesc: 'Non-Formulary Generic'},
        {tierCode: 'SF', tierDesc: 'Non-Formulary Specialty'},
        {tierCode: 'BN', tierDesc: 'Non-Preferred Brand'},
        {tierCode: 'GN', tierDesc: 'Non-Preferred Generic'},
        {tierCode: 'SN', tierDesc: 'Non-Preferred Specialty'},
        {tierCode: 'NC', tierDesc: 'Not Covered'},
        {tierCode: 'MN', tierDesc: 'Not Cvrd Med Necessity'},
        {tierCode: 'BO', tierDesc: 'Other Brand'},
        {tierCode: 'GO', tierDesc: 'Other Generic'},
        {tierCode: 'BP', tierDesc: 'Preferred Brand'},
        {tierCode: 'GP', tierDesc: 'Preferred Generic'},
        {tierCode: 'SP', tierDesc: 'Preferred Specialty'},
        {tierCode: 'S', tierDesc: 'Specialty'},
        {tierCode: '1T', tierDesc: 'Tier 1'},
        {tierCode: '1A', tierDesc: 'Tier 1 A'},
        {tierCode: '1B', tierDesc: 'Tier 1 B'},
        {tierCode: '2T', tierDesc: 'Tier 2'},
        {tierCode: 'G2', tierDesc: 'Tier 2 Generic'},
        {tierCode: '3T', tierDesc: 'Tier 3'},
        {tierCode: 'G3', tierDesc: 'Tier 3 Generic'},
        {tierCode: '4T', tierDesc: 'Tier 4'},
        {tierCode: 'G4', tierDesc: 'Tier 4 Generic'},
        {tierCode: '5T', tierDesc: 'Tier 5'},
        {tierCode: 'G5', tierDesc: 'Tier 5 Generic'},
        {tierCode: '6T', tierDesc: 'Tier 6'},
        {tierCode: '0T', tierDesc: 'Tier Zero'},
        {tierCode: 'U', tierDesc: 'Undefined'},
        {tierCode: 'BV', tierDesc: 'Value Brand'},
        {tierCode: 'GV', tierDesc: 'Value Generic'},
        {tierCode: 'NF', tierDesc: 'Non-Formulary'},
        {tierCode: 'P', tierDesc: 'Preferred'},
        {tierCode: 'NP', tierDesc: 'Non-Preferred'}
    ];
    pdlDescriptionMap = [
        {pdlCode: 'P', pdlDesc: 'Preferred'},
        {pdlCode: 'N', pdlDesc: 'Non Preferred'},
        {pdlCode: 'O', pdlDesc: 'Others'},
        {pdlCode: 'X', pdlDesc: 'Non-Formulary'},
        {pdlCode: 'C', pdlDesc: 'Non Formulary Not Covered'},
        {pdlCode: 'D', pdlDesc: 'Non-Formulary PA Override (MNPA)'},
        {pdlCode: 'G', pdlDesc: 'Non-Formulary Neutral'},
        {pdlCode: 'J', pdlDesc: 'Non Formulary Non Preferred'}
    ];
    resizeColumnDebounceSubject = new Subject();
    hasEditPermission;

    selectedGpi!: string;
    selectedGPIAttachmentPayload: AttachmentData[] = [];
    gpiToNdcSelectedItems: AdhocCreationDetail[] = [];
    selectedGapiAttachmentCount: number;
    gridHeight: string;
    private FORMULARY_COL_GROUP = 'FORMULARY';
    private SOURCE_PARENT = 'parent';
    private SOURCE_CHILD = 'child';

    constructor(formularyDataService: FormularyService,
        userPreferenceService: UserPreferenceService,
        private alertService: CVSAlertService,
        private dialog: MatDialog,
        private convertGpiToNdcService: ConvertGpiToNdcService,
        private location: Location,
        private _userService: UserService,
        private _trackerDataService: TrackerDataService,
        private _workflowService: WorkflowService,
        private _gridDisplayModalService: GridDisplayModalService) {
        this.formularyDataService = formularyDataService;
        this.userPreferenceService = userPreferenceService;
        this.trackerDataService = _trackerDataService;
    }

    ngOnInit(): void {
        this.hasEditPermission = this._userService.hasEditPermission();
        this.resizeColumnDebounceSubject.pipe(debounce(() => interval(10))).subscribe((params: ColumnResizedEvent) => {
            this.setDetailColumnWidth(params, this.SOURCE_PARENT);
            this.setFormularyColumnWidth(params);
        });
        this.convertGpiToNdcService.ndcItemsSelected.subscribe(selectedItems => this.gpiToNdcSelectedItems = selectedItems);
    }

    ngOnDestroy(): void {
        this.convertGpiToNdcService.ndcItemsSelected.unsubscribe();
    }

    buildSearchCriteria(gridFormularyDrugData: FormularyDrugAttachmentResponse[]) {
        const formularies = gridFormularyDrugData?.flatMap(d => d.formularies)
            .filter((n, i, ary) => ary.indexOf(n) === i);
        this.setSheetName(formularies);
        return {formularies};
    }

    setSheetName(formularies) {
        if (formularies) {
            const sheetName = formularies.join('-');
            this.drugListGridOptions.defaultExcelExportParams.sheetName = sheetName;
            if (this.detailCellRendererParams) {
                this.detailCellRendererParams.detailGridOptions.defaultExcelExportParams.sheetName = sheetName;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.formularyDrugAttachmentResponse?.currentValue || changes.selectedLobs?.currentValue) {
            this.clearAllGridSelections();
            this.currentSearchCriteria = this.buildSearchCriteria(this.formularyDrugAttachmentResponse);
            this.drugListGridOptions?.api?.setColumnDefs([]);
            const colDefs: ColGroupDef[] =
                this.buildColumnDef(this.drugListGridOptions, this.formularyDrugAttachmentResponse);
            if (colDefs) {
                if (this.detailCellRendererParams) {
                    this.setupDetailGrid();
                    this.setupDetailGridEvents();
                }
                this.drugListGridOptions.api.setColumnDefs(colDefs);
                if (this.userPreferenceService.userPreference) {
                    this.restoreGridView();
                }
            }
        }
    }

    setGridRowHeight(rows: number) {
        if (rows <= 12) {
            this.drugListGridOptions.domLayout = 'autoHeight';
            this.gridHeight = '';
        } else {
            this.gridHeight = '46.0vh';
        }
    }

    onFirstDataRendered(event: FirstDataRenderedEvent) {
        this.setGridRowHeight(event.api.getDisplayedRowCount());
    }

    onRowDataUpdated(event: RowDataUpdatedEvent) {
        this.setGridRowHeight(event.api.getDisplayedRowCount());
    }

    setupDetailGrid() {
        const detailColumnDefs: ColGroupDef[] =
            this.buildColumnDef(this.drugListGridOptions, this.formularyDrugAttachmentResponse);
        const generalInfoColumnGroupGPI: ColDef =
            detailColumnDefs.find(g => g.headerName === 'General Info').children.find(c => c.headerName === 'GPI');
        generalInfoColumnGroupGPI.checkboxSelection = () => this.hasEditPermission;
        generalInfoColumnGroupGPI.headerCheckboxSelection = () => this.hasEditPermission;
        if (this.hasEditPermission) {
            this.detailCellRendererParams.detailGridOptions.rowSelection = 'multiple';
            this.detailCellRendererParams.detailGridOptions.rowMultiSelectWithClick = true;
            this.detailCellRendererParams.detailGridOptions.suppressRowClickSelection = true;
        }
        this.detailCellRendererParams.detailGridOptions.columnDefs = detailColumnDefs;
        this.detailCellRendererParams.detailGridOptions.suppressHorizontalScroll = true;
    }

    setupDetailGridEvents() {
        if (!this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onColumnGroupOpened) {
            this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onColumnGroupOpened =
                (event: ColumnGroupOpenedEvent) => {
                    this.setDetailColumnGroupOpened(event, this.SOURCE_CHILD);
                    this.setFormularyColumnGroupOpened(event, this.SOURCE_CHILD);
                };
        }
        if (!this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onColumnMoved) {
            this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onColumnMoved =
                (event: ColumnMovedEvent) => {
                    this.setDetailColumnMoved(event, this.SOURCE_CHILD);
                    this.setFormularyColumnMoved(event, this.SOURCE_CHILD);
                };
        }
        if (!this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onColumnVisible) {
            this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onColumnVisible =
                (event: ColumnVisibleEvent) => {
                    this.setDetailColumnVisible(event, this.SOURCE_CHILD);
                    this.setFormularyColumnVisible(event);
                };
        }
        if (!this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onColumnResized) {
            this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onColumnResized =
                (event: ColumnResizedEvent) => {
                    this.setDetailColumnWidth(event, this.SOURCE_CHILD);
                    this.setFormularyColumnWidth(event);
                };
        }
        if (!this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onFirstDataRendered) {
            this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onFirstDataRendered = () => {
                this.onDetailDataRendered();
            };
        }
        if (!this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onSelectionChanged) {
            this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onSelectionChanged = ($event) => {
                this.onDetailSelectionChange($event);
            };
        }
    }

    onDetailDataRendered() {
        this.drugListGridOptions.columnApi.getColumns().forEach((i: Column) => {
            this.setDetailColumnVisibleToMatchParent(i);
            this.setDetailColumnWidthToMatchParent(i);
        });
        (this.drugListGridOptions.api.getColumnDefs() as ColGroupDef[]).forEach(i => {
            this.drugListGridOptions.api.forEachDetailGridInfo((detailGridInfo) => {
                detailGridInfo.columnApi.setColumnGroupState([{
                    groupId: i.groupId,
                    open: this.drugListGridOptions.columnApi.getColumnGroup(i.groupId).isExpanded()
                }]);
            });
        });
    }

    onRowGroupOpened($event){
        if (!$event.expanded) {
            this.detailSelectedRows = [];
            this.drugListGridOptions.api.forEachDetailGridInfo(d => {
                if (d.api.getSelectedNodes().length !== 0) {
                    this.detailSelectedRows.push({api: d.api, selectedRows: d.api.getSelectedNodes()});
                }
            });
            this.adhocUpdateCount = this.getAdhocUpdateCount(this.adHocButtonDisabled);
        }
    }

    buildColumnDef(gridDrugListGridOptions: GridOptions,
        gridFormularyDrugData: FormularyDrugAttachmentResponse[]): ColGroupDef[] {
        if (gridDrugListGridOptions && gridDrugListGridOptions.api && gridFormularyDrugData) {
            let columnDefs: ColGroupDef[] = gridDrugListGridOptions.api.getColumnDefs().splice(0) as ColGroupDef[];
            columnDefs = columnDefs.concat([
                {
                    headerName: 'General Info', marryChildren: true, openByDefault: true,
                    children: [
                        {
                            headerName: 'GPI',
                            field: 'drugAttachmentIndicator',
                            filter: 'agSetColumnFilter',
                            cellRenderer: 'agGroupCellRenderer',
                            headerCheckboxSelection: this.hasEditPermission,
                            showDisabledCheckboxes: this.hasEditPermission,
                            checkboxSelection: (params) =>
                                params.data.drugAttachmentIndicatorType === 'NDC' ? false : this.hasEditPermission
                        },
                        {
                            headerName: 'Attachment Level Type',
                            field: 'drugAttachmentIndicatorType',
                            filter: 'agSetColumnFilter',
                            width: 80,
                        },
                        {
                            headerName: 'Label Name',
                            field: 'drugMasterResponse.labelName',
                            filter: 'agSetColumnFilter',
                            cellRenderer: CustomNavigationComponent,
                            search: 'DRUG_NAME'
                        } as any,
                        {
                            headerName: 'Generic Name',
                            field: 'drugMasterResponse.genericName',
                            filter: 'agSetColumnFilter',
                            search: 'DRUG_NAME',
                        },
                        {
                            headerName: 'Product Name',
                            field: 'drugMasterResponse.productName',
                            filter: 'agSetColumnFilter',
                            columnGroupShow: 'closed'
                        },
                        {
                            headerName: 'RxCUI',
                            filter: 'agSetColumnFilter', width: 110, field: 'drugMasterResponse.rxcui',
                            columnGroupShow: 'closed'
                        },
                        {
                            headerName: 'Multi-Source Code',
                            field: 'drugMasterResponse.multiSourceCode',
                            filter: 'agSetColumnFilter',
                            width: 80,
                            columnGroupShow: 'closed'
                        }
                    ]
                }
            ]);
            const formularies = gridFormularyDrugData.flatMap(d => d.formularies)
                .filter((n, i, ary) => ary.indexOf(n) === i);
            formularies.forEach(formularyNumber => {
                const formularyName = this.filteredFormularyData?.filter(formulary => formulary.formularyNumber === formularyNumber)
                    .map(formulary => formulary.clientFormularyName);
                columnDefs = columnDefs.concat([
                    {
                        headerName: `${formularyNumber} ${formularyName}`,
                        marryChildren: true,
                        openByDefault: true,
                        headerGroupComponentParams: this.FORMULARY_COL_GROUP,
                        children: [
                            {
                                headerName: 'NDC', field: `formularyNDC${formularyNumber}`, filter: 'agSetColumnFilter',
                                cellRenderer: CustomNavigationComponent,
                                cellRendererParams: {
                                    source: PRODUCT_OVERVIEW_SOURCE.FORMULARY_SEARCH,
                                    selectedLOBS: this.selectedLobs,
                                    navigatedFromLink: this.location.path()
                                },
                                width: 120,
                            },
                            {
                                headerName: 'Tier',
                                field: `formularyDLTierNumber${formularyNumber}`,
                                filter: 'agSetColumnFilter',
                                width: 70
                            },
                            {
                                headerName: 'Coded Multi-Source Codes',
                                field: `coveredMultisourceCodes${formularyNumber}`,
                                filter: 'agSetColumnFilter',
                                width: 210
                            },
                            {
                                headerName: 'Form Status',
                                filter: 'agSetColumnFilter',
                                field: `formularyDLLTOnFormulary${formularyNumber}`,
                                width: 119,
                                valueGetter: params => this.populateFormStatus(params.data[`formularyDLLTOnFormulary${formularyNumber}`])
                            },
                            {
                                headerName: 'Coded or Defaulted',
                                valueGetter: params => this.populateCodedOrDefaulted(params.data[`defaultTier${formularyNumber}`]),
                                filter: 'agSetColumnFilter',
                                width: 160,
                            },
                            {
                                headerName: 'Rx/OTC',
                                valueGetter: params => this.populateRxOTC(params.data[`drugAttachmentRxOTC${formularyNumber}`]),
                                filter: 'agSetColumnFilter',
                                width: 94,
                                columnGroupShow: 'closed'
                            },
                            {
                                headerName: 'Med-D',
                                field: `drugAttachmentMEDD${formularyNumber}`,
                                filter: 'agSetColumnFilter',
                                width: 100,
                                columnGroupShow: 'closed'
                            },
                            {
                                headerName: 'Tier Effective Date',
                                field: `tierFromDate${formularyNumber}`,
                                filter: 'agSetColumnFilter',
                                width: 155,
                                columnGroupShow: 'closed',
                                valueGetter: params => dateTruncation(params.data[`tierFromDate${formularyNumber}`])
                            },
                            {
                                headerName: 'Tier Term date',
                                field: `tierThruDate${formularyNumber}`,
                                filter: 'agSetColumnFilter',
                                width: 132,
                                columnGroupShow: 'closed',
                                valueGetter: params => dateTruncation(params.data[`tierThruDate${formularyNumber}`])
                            },
                            {
                                headerName: 'Tier Code Description',
                                valueGetter: params => this.populateTierDescription(params.data[`formularyDLLTTierCode${formularyNumber}`]),
                                filter: 'agSetColumnFilter',
                                width: 176,
                                columnGroupShow: 'closed'
                            },
                            {
                                headerName: 'PDL Description',
                                valueGetter: params =>
                                    this.populatePdlDescription(params.data[`formularyDLLTPreferredDLCode${formularyNumber}`]),
                                filter: 'agSetColumnFilter',
                                width: 141,
                                columnGroupShow: 'closed'
                            },
                            {
                                headerName: 'Message Type',
                                field: `drugAttachmentMessageType${formularyNumber}`,
                                filter: 'agSetColumnFilter',
                                width: 130,
                                columnGroupShow: 'closed'
                            },
                            {
                                headerName: 'Message Code',
                                field: `drugAttachmentMessageCode${formularyNumber}`,
                                filter: 'agSetColumnFilter',
                                width: 131,
                                columnGroupShow: 'closed'
                            },
                            {
                                headerName: 'Message Description',
                                field: `drugAttachmentMessageDescription${formularyNumber}`,
                                filter: 'agSetColumnFilter',
                                width: 169,
                                columnGroupShow: 'closed'
                            },
                            {
                                headerName: 'Prior Authorization',
                                field: `priorAuthorization`,
                                filter: 'agSetColumnFilter',
                                width: 172,
                                columnGroupShow: 'closed'
                            },
                            {
                                headerName: 'Step Therapy',
                                field: `stepTherapy`,
                                filter: 'agSetColumnFilter',
                                width: 169,
                                columnGroupShow: 'closed'
                            },
                            {
                                headerName: 'Quantity Limit',
                                field: `quantityLimit`,
                                filter: 'agSetColumnFilter',
                                width: 169,
                                columnGroupShow: 'closed'
                            }
                        ]
                    }
                ]);
            });
            columnDefs = columnDefs.concat([
                {
                    headerName: 'Drug info', marryChildren: true, openByDefault: true,
                    children: [
                        {
                            headerName: 'Medispan Therapeutic Category',
                            field: 'drugMasterResponse.therapeuticCategory',
                            filter: 'agSetColumnFilter',
                            width: 100
                        },
                        {
                            headerName: 'Medispan Therapeutic Class',
                            field: 'drugMasterResponse.therapeuticClass',
                            filter: 'agSetColumnFilter',
                            width: 100,
                        },
                        {
                            headerName: 'Proxy FDA Type',
                            field: 'drugMasterResponse.fdaAppType',
                            filter: 'agSetColumnFilter',
                            width: 100,
                            columnGroupShow: 'closed'
                        },
                        {
                            headerName: 'Dosage Form',
                            field: 'drugMasterResponse.dosageForm',
                            filter: 'agSetColumnFilter',
                            width: 100,
                            columnGroupShow: 'closed'
                        },
                        {
                            headerName: 'Route', field: 'drugMasterResponse.routeOfAdmin',
                            filter: 'agSetColumnFilter', width: 100, columnGroupShow: 'closed'
                        },
                        {
                            headerName: 'Strength', field: 'drugMasterResponse.strength',
                            filter: 'agSetColumnFilter', width: 100, columnGroupShow: 'closed'
                        },
                        {
                            headerName: 'Repackaging',
                            field: 'drugMasterResponse.repackaged',
                            filter: 'agSetColumnFilter',
                            width: 100,
                            columnGroupShow: 'closed'
                        },
                        {
                            headerName: 'Obsolete Date',
                            field: 'drugMasterResponse.obsoleteDate',
                            filter: 'agSetColumnFilter',
                            width: 100,
                            columnGroupShow: 'closed'
                        },
                        {
                            headerName: 'Inactive Date',
                            field: 'drugMasterResponse.inactiveDate',
                            filter: 'agSetColumnFilter',
                            width: 145,
                            columnGroupShow: 'closed'
                        }
                    ]
                },
            ]);
            return columnDefs as ColGroupDef[];
        }
    }

    setDebounceColumnWidth(params: ColumnResizedEvent) {
        if (params.type === 'columnResized' && params.source === 'uiColumnDragged') {
            this.setDetailColumnWidth(params, this.SOURCE_PARENT);
            this.resizeColumnDebounceSubject.next(params);
        }
    }

    setDetailColumnWidth(params: ColumnResizedEvent, source: string) {
        if (params.type === 'columnResized' && params.source === 'uiColumnDragged') {
            params.columns.forEach((i: Column) => {
                if (source.toLowerCase() === this.SOURCE_CHILD) {
                    const func = this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onColumnResized;
                    this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onColumnResized = undefined;
                    this.drugListGridOptions.columnApi.setColumnWidth(i.getColId(), i.getActualWidth());
                    this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onColumnResized = func;
                }
                this.setDetailColumnWidthToMatchParent(i);
            });
        }
    }

    setDetailColumnWidthToMatchParent(i: Column) {
        this.drugListGridOptions.api.forEachDetailGridInfo(detailGrid => {
            detailGrid.columnApi.setColumnWidth(i.getColId(), i.getActualWidth());
        });
    }

    setFormularyColumnWidth(params: ColumnResizedEvent) {
        if (['uiColumnDragged', 'autosizeColumns'].includes(params.source) && params?.column?.getParent()) {
            if (params.column.getParent()
                .getColGroupDef().headerGroupComponentParams === this.FORMULARY_COL_GROUP) {

                const formularyColumnGroups = params.api.getColumnDefs()
                    .filter((f: ColGroupDef) => f.headerGroupComponentParams === this.FORMULARY_COL_GROUP);

                formularyColumnGroups.forEach((fcg: ColGroupDef) => {
                    const childHeader: ColDef = fcg.children.find(c => c.headerName === params.column.getColDef().headerName);
                    params.columnApi.setColumnWidth(childHeader.colId, params.column.getActualWidth());

                    const detailGroup = (this.drugListGridOptions.detailCellRendererParams.detailGridOptions.columnDefs as ColGroupDef[])
                        .find(i => i.headerName === fcg.headerName);
                    (detailGroup.children.find(i => i.headerName === childHeader.headerName) as ColDef)
                        .width = params.column.getActualWidth();

                    this.drugListGridOptions.columnApi.setColumnWidth(childHeader.colId, params.column.getActualWidth());
                    this.drugListGridOptions.api.forEachDetailGridInfo(detailGrid => {
                        detailGrid.columnApi.setColumnWidth(childHeader.colId, params.column.getActualWidth());
                    });
                });
            }
        }
    }

    setColumnGroupOpened(params: ColumnGroupOpenedEvent) {
        this.setDetailColumnGroupOpened(params, this.SOURCE_PARENT);
        this.setFormularyColumnGroupOpened(params, this.SOURCE_PARENT);
    }

    setDetailColumnGroupOpened(params: ColumnGroupOpenedEvent, source: string) {
        if (params.type === 'columnGroupOpened') {
            if (source.toLowerCase() === this.SOURCE_CHILD) {
                this.drugListGridOptions.columnApi.setColumnGroupState([{
                    groupId: params.columnGroup.getGroupId(),
                    open: params.columnGroup.isExpanded()
                }]);
            }
            if (source.toLowerCase() === this.SOURCE_PARENT) {
                this.drugListGridOptions.api.forEachDetailGridInfo((detailGridInfo, index) => {
                    detailGridInfo.columnApi.setColumnGroupState([{
                        groupId: params.columnGroup.getGroupId(),
                        open: params.columnGroup.isExpanded()
                    }]);
                });
            }
        }
    }

    setFormularyColumnGroupOpened(params: ColumnGroupOpenedEvent, source: string) {
        if (params.type === 'columnGroupOpened') {
            if (params.columnGroup.getColGroupDef().headerGroupComponentParams === this.FORMULARY_COL_GROUP) {
                const formularyColumnGroups = params.api.getColumnDefs()
                    .filter((f: ColGroupDef) => (f.headerGroupComponentParams === this.FORMULARY_COL_GROUP) &&
                        (f.groupId !== params.columnGroup.getGroupId())) as ColGroupDef[];
                const changedColumnGroup = params.columnApi.getColumnGroup(params.columnGroup.getGroupId());
                formularyColumnGroups.forEach(fcg => {
                    const columnGroup = params.columnApi.getColumnGroup(fcg.groupId);
                    if (source.toLowerCase() === this.SOURCE_PARENT) {
                        this.drugListGridOptions.api.forEachDetailGridInfo(detailGrid => {
                            detailGrid.columnApi.setColumnGroupState([{
                                groupId: columnGroup.getGroupId(),
                                open: changedColumnGroup.isExpanded()
                            }]);
                        });
                    }
                    if (source.toLowerCase() === this.SOURCE_CHILD) {
                        this.drugListGridOptions.columnApi.setColumnGroupState([{
                            groupId: columnGroup.getGroupId(),
                            open: changedColumnGroup.isExpanded()
                        }]);
                    }
                    params.columnApi.setColumnWidth(columnGroup.getGroupId(), changedColumnGroup.getActualWidth());
                    params.columnApi.setColumnGroupState([{
                        groupId: columnGroup.getGroupId(),
                        open: changedColumnGroup.isExpanded()
                    }]);

                });
            }
        }
    }

    setColumnMoved(params: ColumnMovedEvent) {
        this.setDetailColumnMoved(params, this.SOURCE_PARENT);
        this.setFormularyColumnMoved(params, this.SOURCE_PARENT);
    }

    setDetailColumnMoved(params: ColumnMovedEvent, source: string) {
        if (params.type === 'columnMoved' && params.source === 'uiColumnMoved') {
            if (source.toLowerCase() === this.SOURCE_CHILD) {
                this.drugListGridOptions.columnApi.moveColumn(params.column.getColId(), params.toIndex);
            }
            this.drugListGridOptions.api.forEachDetailGridInfo(detailGridInfo => {
                detailGridInfo.columnApi.moveColumn(params.column.getColId(), params.toIndex);
            });
            const theGroupDef = this.drugListGridOptions.detailCellRendererParams.detailGridOptions.columnDefs;
            let indexOffset = 0;
            theGroupDef.forEach(gd => {
                const theChildren = gd.children;
                const sourceIndex = theChildren.findIndex(i => i.headerName === params.column.getColDef().headerName);
                if (sourceIndex !== -1) {
                    const theItem = theChildren[sourceIndex];
                    theChildren.splice(sourceIndex, 1);
                    gd.children.splice(params.toIndex - indexOffset, 0, theItem);
                }
                indexOffset += theChildren.length;
            });
        }
    }

    setFormularyColumnMoved(params: ColumnMovedEvent, source) {
        if (params.source === 'uiColumnMoved') {
            if (params.column.getParent().getColGroupDef().headerGroupComponentParams === this.FORMULARY_COL_GROUP) {

                const formularyColumnGroups = params.api.getColumnDefs()
                    .filter((f: ColGroupDef) => f.headerGroupComponentParams === this.FORMULARY_COL_GROUP &&
                        f.groupId !== params.column.getParent().getGroupId()) as ColGroupDef[];

                const currentColumnIndex = params.columnApi.getAllGridColumns()
                    .findIndex(col => col.getColId() === params.column.getColId());

                const firstColumnIndex = params.columnApi.getAllGridColumns()
                    .findIndex(col => col.getColId() === (params.column.getParent().getChildren()[0] as ColDef).colId);

                const offset = currentColumnIndex - firstColumnIndex;

                formularyColumnGroups.forEach((fcg: ColGroupDef, fcgIndex) => {
                    const headerInGroup = fcg.children.find(i => i.headerName === params.column.getColDef().headerName) as ColDef;

                    const firstColumnIndexInGroup = params.columnApi.getAllGridColumns()
                        .findIndex(f => f.getColId() === (fcg.children[0] as ColDef).colId);

                    if (source.toLowerCase() === this.SOURCE_PARENT) {
                        params.columnApi.moveColumn(headerInGroup.colId, offset + firstColumnIndexInGroup);
                    }
                    if (source.toLowerCase() === this.SOURCE_CHILD) {
                        this.drugListGridOptions.columnApi.moveColumn(headerInGroup.colId, offset + firstColumnIndexInGroup);
                    }
                    this.drugListGridOptions.api.forEachDetailGridInfo(detailGrid => {
                        detailGrid.columnApi.moveColumn(headerInGroup.colId, offset + firstColumnIndexInGroup);
                    });
                });

                const detailFormularyColGroup = this.drugListGridOptions.detailCellRendererParams.detailGridOptions.columnDefs
                    .filter((f: ColGroupDef) => f.headerGroupComponentParams === this.FORMULARY_COL_GROUP &&
                        f.groupId !== params.column.getParent().getGroupId()) as ColGroupDef[];

                detailFormularyColGroup.forEach(gd => {
                    const theChildren = gd.children as ColDef[];
                    const sourceHeaders = theChildren.filter(i => i.headerName === params.column.getColDef().headerName);

                    sourceHeaders.forEach(sh => {
                        const sourceIndex = theChildren.findIndex(i => i.headerName === sh.headerName);
                        if (sourceIndex !== -1) {
                            const theItem = theChildren[sourceIndex];
                            theChildren.splice(sourceIndex, 1);
                            gd.children.splice(offset, 0, theItem);
                        }
                    });
                });
            }
        }
    }

    setColumnVisible(params: ColumnVisibleEvent) {
        this.setDetailColumnVisible(params, this.SOURCE_PARENT);
        this.setFormularyColumnVisible(params);
    }

    setDetailColumnVisible(params: ColumnVisibleEvent, source: string) {
        if (params.type === 'columnVisible' && params.source === 'toolPanelUi') {
            params.columns.forEach((i: Column) => {
                if (source.toLowerCase() === this.SOURCE_CHILD) {
                    const func = this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onColumnVisible;
                    this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onColumnVisible = undefined;
                    this.drugListGridOptions.columnApi.setColumnVisible(i.getColId(), i.isVisible());
                    this.drugListGridOptions.detailCellRendererParams.detailGridOptions.onColumnVisible = func;
                } else {
                    this.setDetailColumnVisibleToMatchParent(i);
                }
                i.getUserProvidedColDef().hide = !i.isVisible();
            });
        }
    }


    setDetailColumnVisibleToMatchParent(i: Column) {
        this.drugListGridOptions.api.forEachDetailGridInfo((detailGridInfo, index) => {
            detailGridInfo.columnApi.setColumnVisible(i.getColId(), i.isVisible());
        });
    }

    setFormularyColumnVisible(params: ColumnVisibleEvent) {
        if (params.column?.getParent().getColGroupDef().headerGroupComponentParams === this.FORMULARY_COL_GROUP) {
            const formularyColumnGroups = params.api.getColumnDefs()
                .filter((f: ColGroupDef) => f.headerGroupComponentParams === this.FORMULARY_COL_GROUP &&
                    f.groupId !== params.column.getParent().getGroupId()) as ColGroupDef[];
            formularyColumnGroups.forEach((fcg: ColGroupDef, fcgIndex) => {
                const headerInGroup = fcg.children.find(i => i.headerName === params.column.getColDef().headerName) as ColDef;
                params.columnApi.setColumnVisible(headerInGroup.colId, params.column.isVisible());

                const detailGroup = (this.drugListGridOptions.detailCellRendererParams.detailGridOptions.columnDefs as ColGroupDef[])
                    .find(i => i.headerName === fcg.headerName);
                (detailGroup.children.find(i => i.headerName === params.column.getColDef().headerName) as ColDef)
                    .hide = !params.column.isVisible();
            });
        }
    }

    populateTierDescription(tierCode): string {
        if (tierCode) {
            return this.tierDescriptionMap.filter(t => t.tierCode === tierCode).map(t => `${t.tierCode}-${t.tierDesc}`)[0];
        }
    }

    populatePdlDescription(pdlCode): string {
        if (pdlCode) {
            return this.pdlDescriptionMap.filter(t => t.pdlCode === pdlCode).map(t => `${t.pdlCode}-${t.pdlDesc}`)[0];
        }
    }

    populateCodedOrDefaulted(defaultTier): string {
        if (defaultTier !== undefined) {
            if (defaultTier) {
                return 'Default';
            } else {
                return 'Coded';
            }
        }
    }

    populateFormStatus(formularyDLLTOnFormulary): string {
        if (formularyDLLTOnFormulary === true) {
            return 'ON';
        } else if (formularyDLLTOnFormulary === false) {
            return 'OFF';
        }
    }

    saveGridView() {
        const colGroups = [];
        const colGroupState = [];
        let firstFormularyGroup = true;
        const gridColumnGroupState = this.drugListGrid.gridOptions.columnApi.getColumnGroupState();
        this.drugListGrid.gridOptions.api.getColumnDefs().forEach((i: ColGroupDef, index) => {
            if (i.headerGroupComponentParams === this.FORMULARY_COL_GROUP) {
                if (firstFormularyGroup) {
                    colGroups.push({...i, headerName: this.FORMULARY_COL_GROUP});
                    const thisGroupState = {...gridColumnGroupState[index]};
                    thisGroupState.groupId = `${index}`;
                    colGroupState.push(thisGroupState);
                    firstFormularyGroup = false;
                }
            } else {
                colGroups.push(i);
                const thisGroupState = {...gridColumnGroupState[index]};
                thisGroupState.groupId = `${index}`;
                colGroupState.push(thisGroupState);
            }
        });
        const userPreference: UserPreference = {
            formularyDrugSearchGridView: {
                columnGroupState: JSON.stringify(colGroupState),
                colGroups: JSON.stringify(colGroups)
            }
        };
        this.userPreferenceService.saveUserPreference(userPreference).subscribe(() => {
        },
        () => {
            const alert = {
                headline: this.WARNING_HEADLINE,
                body: this.WARNING_BODY,
                alertType: CVSAlertType.Warning,
                removedAfterMilliseconds: 15000
            } as CVSAlertComponentData;
            this.alertService.sendAlert(alert);
        },
        () => {
            const alert = {
                headline: this.SUCCESS_HEADLINE,
                body: this.SUCCESS_BODY,
                alertType: CVSAlertType.Success,
                removedAfterMilliseconds: 15000
            } as CVSAlertComponentData;
            this.alertService.sendAlert(alert);
        });
    }

    resetGridAndSaveView() {
        const userPreference: UserPreference = {
            formularyDrugSearchGridView: null
        };
        this.userPreferenceService.saveUserPreference(userPreference).subscribe(() => {
        });
        this.drugListGridOptions.api.forEachNode(rn => {
            if (rn.expanded === true) {
                rn.setExpanded(false);
            }
        });
        this.drugListGridOptions.api.redrawRows();
    }

    restoreGridView() {
        const gridColGroups = this.drugListGrid.gridOptions.api.getColumnDefs() as ColGroupDef[];
        const gridColumnGroupState = this.drugListGrid.gridOptions.columnApi.getColumnGroupState();
        try {
            const userPreference = this.userPreferenceService.userPreference;
            if (userPreference?.formularyDrugSearchGridView?.colGroups?.length > 0 &&
                userPreference?.formularyDrugSearchGridView?.columnGroupState?.length > 0) {
                const viewColGroups: ColGroupDef[] =
                    JSON.parse(userPreference?.formularyDrugSearchGridView?.colGroups);
                const viewGroupStates: { groupId: string; open: boolean }[] =
                    JSON.parse(userPreference?.formularyDrugSearchGridView?.columnGroupState);
                if (viewColGroups !== undefined && viewGroupStates !== undefined) {
                    const finalColGroups: ColGroupDef[] = [];
                    const finalGroupStates: { groupId: string; open: boolean }[] = [];
                    viewColGroups.forEach((viewColGroup) => {
                        let groupState: { groupId: string; open: boolean };
                        let gridColumnGroups = gridColGroups.filter(f => f.headerName === viewColGroup.headerName);
                        if (gridColumnGroups.length > 0) {
                            const gridColIndex = gridColGroups.filter(f => f.headerGroupComponentParams !== this.FORMULARY_COL_GROUP)
                                .findIndex(f => f.headerName === viewColGroup.headerName);
                            groupState = viewGroupStates.filter(
                                f => f !== viewGroupStates[
                                    viewColGroups.findIndex(f2 => f2.headerGroupComponentParams === this.FORMULARY_COL_GROUP)
                                ]
                            )[gridColIndex];
                        } else {
                            gridColumnGroups = gridColGroups.filter(f => f.headerGroupComponentParams === this.FORMULARY_COL_GROUP);
                            groupState = viewGroupStates[
                                viewColGroups.findIndex(f => f.headerGroupComponentParams === this.FORMULARY_COL_GROUP)
                            ];
                        }

                        gridColumnGroups.forEach((gridColumnGroup) => {
                            const newColGroup = {...gridColumnGroup, children: []};
                            viewColGroup.children.forEach((groupViewChild: ColDef) => {
                                const gridChild = gridColumnGroup.children.find(f => f.headerName === groupViewChild.headerName);
                                newColGroup.children.push({
                                    ...gridChild,
                                    hide: groupViewChild.hide,
                                    initialHide: groupViewChild.initialHide,
                                    width: groupViewChild.width,
                                    initialWidth: groupViewChild.initialWidth
                                });
                            });
                            finalColGroups.push(newColGroup);
                            finalGroupStates.push({groupId: gridColumnGroup.groupId, open: groupState.open});
                        });
                    });
                    this.drugListGrid.gridOptions.api.setColumnDefs(finalColGroups);
                    this.drugListGrid.gridOptions.columnApi.setColumnGroupState(finalGroupStates);
                }
            }
        } catch (e) {
            this.drugListGrid.gridOptions.api.setColumnDefs(gridColGroups);
            this.drugListGrid.gridOptions.columnApi.setColumnGroupState(gridColumnGroupState);
        }
    }

    resetDefaultView() {
        this.drugListGridOptions.api.setColumnDefs([]);
        const colDefs =
            this.buildColumnDef(this.drugListGridOptions, this.formularyDrugAttachmentResponse);
        if (colDefs) {
            if (this.detailCellRendererParams) {
                this.detailCellRendererParams.detailGridOptions.columnDefs = colDefs;
            }
            this.drugListGridOptions.api.setColumnDefs(colDefs);
            this.resetGridAndSaveView();
        }
    }

    populateRxOTC(drugAttachmentRxOTC: any) {
        if (drugAttachmentRxOTC?.toUpperCase() === 'OTC') {
            return drugAttachmentRxOTC.toUpperCase();
        } else if (drugAttachmentRxOTC?.toUpperCase() === 'RX') {
            return 'Rx';
        } else {
            return drugAttachmentRxOTC;
        }
    }

    onSelectionChange(event: AgGridEvent) {
        this.selectedRows = event.api.getSelectedNodes();
        this.adHocButtonDisabled = this.detailSelectedRows.length === 0 && this.selectedRows.length === 0;
        this.adhocUpdateCount = this.getAdhocUpdateCount(this.adHocButtonDisabled);
    }

    onDetailSelectionChange(event: SelectionChangedEvent) {
        const theIndex = this.detailSelectedRows.findIndex(dg => dg.api === event.api);
        if (theIndex === -1 && event.source !== 'apiSelectAll') {
            this.detailSelectedRows.push({api: event.api, selectedRows: event.api.getSelectedNodes()});
        } else {
            if (event.api.getSelectedNodes().length === 0) {
                this.detailSelectedRows.splice(theIndex, 1);
            } else {
                this.detailSelectedRows[theIndex].selectedRows = event.api.getSelectedNodes();
            }
        }
        this.adHocButtonDisabled = this.detailSelectedRows.length === 0 && this.selectedRows.length === 0;
        this.adhocUpdateCount = this.getAdhocUpdateCount(this.adHocButtonDisabled);
    }

    getAdhocUpdateCount(buttonDisabled: boolean) {
        return (buttonDisabled) ? undefined :
            ` (${this.mergeParentDetailRowSelectionData(this.selectedRows, this.detailSelectedRows).length})`;
    }

    onClickCreateAdHocUpdate() {
        const ndcRowsToAdhoc = this.mergeParentDetailRowSelectionData(this.selectedRows, this.detailSelectedRows);
        this.selectedGapiAttachmentCount = ndcRowsToAdhoc.filter(row => row.drugAttachmentIndicatorType === 'GPI').length;
        this.showAdHocUpdateModal(
            ndcRowsToAdhoc,
            this.adhocModalRef,
            this.saveAdHocSelection.bind(this));
    }

    saveAdHocSelection(valueObject: CLRConfirmationDialogValueObject) {
        if (!valueObject.statusId || valueObject.businessLob.length === 0) {
            return;
        }

        const todaysDate = oracleDateFormat(new Date());
        const mergedSelectedRows: CommonCommon[] = this.mergeParentDetailRowSelectionData(this.selectedRows, this.detailSelectedRows);
        const convertGpiToNdc = valueObject.convertGpiToNdc && this.gpiToNdcSelectedItems.length > 0;
        const adhoc = convertGpiToNdc ? { ...valueObject, weekDate: todaysDate, details: this.gpiToNdcSelectedItems } : {
            ...valueObject,
            weekDate: todaysDate,
            details: this._trackerDataService.transformDrugRowDataToAdhoc(mergedSelectedRows,valueObject.convertNdcToGpi)
        };
        const ndcFormularySearchSelectionRowCount = mergedSelectedRows.filter(f => f.drugAttachmentIndicatorType === 'NDC').length;
        const gpiFormularySearchSelectionRowCount = mergedSelectedRows.filter(f => f.drugAttachmentIndicatorType === 'GPI').length;

        this._trackerDataService.findAdhocDuplicates(this._userService.getSuperClientContext().id, adhoc)
            .pipe(take(1))
            .subscribe(adhocDuplicates => {
                if (convertGpiToNdc) {
                    if (adhocDuplicates.length === 0) {
                        this.createAdhoc(adhoc, adhoc.details.length, mergedSelectedRows, valueObject,
                            gpiFormularySearchSelectionRowCount, ndcFormularySearchSelectionRowCount);
                    } else {
                        const rowData: IAdhocConflictRowData[] = this.buildAdhocConflictRowData(adhoc, adhocDuplicates);
                        this.showAdhocConflictModal(rowData).subscribe(selectedItems => {
                            if (selectedItems) {
                                adhoc.details = selectedItems.map(m => m.data.adhocDetail);
                                this.createAdhocByDetail(adhoc, gpiFormularySearchSelectionRowCount, 0);
                            }
                        });
                    }
                } else if (adhocDuplicates.length === 0 || ndcFormularySearchSelectionRowCount === 0 || valueObject.convertNdcToGpi) {
                    this.createAdhoc(adhoc, undefined, mergedSelectedRows, valueObject, gpiFormularySearchSelectionRowCount,
                        ndcFormularySearchSelectionRowCount);
                } else {
                    const rowData: IAdhocConflictRowData[] = this.buildAdhocConflictRowData(adhoc, adhocDuplicates);
                    this.showAdhocConflictModal(rowData).subscribe(selectedItems => {
                        if (selectedItems) {
                            adhoc.details = selectedItems.map(m => m.data.adhocDetail);
                            this.createAdhocByDetail(adhoc, 0, selectedItems.length);
                        }
                    });
                }
            });
    }

    showAdhocConflictModal(rowData: IAdhocConflictRowData[]): Observable<any> {
        const columnDefs = this.buildColumnDefs();
        const bodyMessage = 'Review the potentially conflicting item(s) below that ' +
            'match the existing NDC(s) and/or GPI(s) in the Workflow.';
        const footerMessage = 'Do you wish to continue adding the selected item(s) to the workflow?';
        return this._gridDisplayModalService.show(
            this.adhocDuplicateGridModalRef,
            'Warning: Did Not Add Item(s) to Workflow',
            bodyMessage,
            'Add Selected Item(s)',
            rowData,
            {
                columnDefs,
                isRowSelectable: (params) => params.data.selectable,
            } as GridOptions,
            true, footerMessage
        );
    }

    buildColumnDefs() {
        const columnDefs: ColDef[] = [
            {
                headerName: 'Ad Hoc Item', field: 'adHocItem', width: 337,
                tooltipValueGetter: params => params.data.duplicateForToday ?
                    'Duplicate items can only be added once per day' : undefined
            },
            {
                headerName: 'Existing NDC/GPI in Workflow', field: 'existingNdcGpi', width: 655,
                cellRendererSelector: (params) => gridHyperLinkSelector(params.data.existingNdcGpi, 'None'),
                cellRendererParams: {
                    source: PRODUCT_OVERVIEW_SOURCE.GPI_TO_NDC_ADHOC,
                    mainTabName:'Research & Recommendations',
                    subTabIndex: 0
                },
            },
            { headerName: 'Line of Business', field: 'businessLob', width: 95}
        ];
        return columnDefs;
    }

    buildAdhocConflictRowData(adhoc: AdhocCreation, adhocDuplicates: MedispanTrackerResponse[]): IAdhocConflictRowData[] {
        const rowData: IAdhocConflictRowData[] = [];
        let conflictFound = false;
        const todaysDate = dateTruncation(new Date().toISOString());
        adhoc.businessLob.forEach(adhocBusiness => {
            adhoc.details.forEach(adhocDetail => {
                const gpiMatches = adhocDuplicates.filter(f => f.businessLobCd === adhocBusiness);
                const duplicateNDC = adhocDuplicates.filter(f => f.ndc === adhocDetail.ndc && f.businessLobCd === adhocBusiness);
                const maxDuplicateNDCDate = _.maxBy(duplicateNDC, (f) => f.weekDate);
                if (gpiMatches.length !== 0) {
                    conflictFound = true;
                    gpiMatches.forEach(gpiMatch => {
                        if (gpiMatch.gpi === adhocDetail.gpi) {
                            rowData.push({
                                adHocItem: `NDC: ${adhocDetail.ndc} - ${adhocDetail.labelName}`,
                                existingNdcGpi: `${dateTruncation(gpiMatch.weekDate)} - ` +
                                        (!!gpiMatch.ndc ? `GPI: ${gpiMatch.gpi} NDC: ${gpiMatch.ndc} - ${gpiMatch.labelName}` :
                                            `GPI: ${gpiMatch.gpi} - ${gpiMatch.gpiName}`),
                                businessLob: gpiMatch.businessLob,
                                adhocDetail: {
                                    ...adhocDetail,
                                    matchingGPI: gpiMatch.gpi,
                                    matchingWeekDate: gpiMatch.weekDate,
                                    statusId: gpiMatch.statusId,
                                    matchingNDC: gpiMatch.ndc,
                                    matchingUpdateType: gpiMatch.updateType,
                                    matchingEncryptedRecordId: gpiMatch.encryptedRecordId,
                                    matchingNote: gpiMatch.note,
                                    matchingCombinedId: gpiMatch.combinedId,
                                    businessLobCd: gpiMatch.businessLobCd
                                } as IAdhocConflictDetail,
                                selectable: (!!maxDuplicateNDCDate &&
                                        dateTruncation(maxDuplicateNDCDate.weekDate) === dateTruncation(gpiMatch.weekDate) &&
                                        dateTruncation(maxDuplicateNDCDate.weekDate) < todaysDate) && gpiMatch.ndc === adhocDetail.ndc,
                                duplicateForToday: (!!maxDuplicateNDCDate &&
                                        dateTruncation(maxDuplicateNDCDate.weekDate) === dateTruncation(gpiMatch.weekDate) &&
                                        dateTruncation(maxDuplicateNDCDate.weekDate) === todaysDate) && gpiMatch.ndc === adhocDetail.ndc
                            });
                        }
                    }
                    );
                }

                if (duplicateNDC.length === 0) {
                    rowData.push({
                        adHocItem: `NDC: ${adhocDetail.ndc} - ${adhocDetail.labelName}`,
                        existingNdcGpi: 'None',
                        businessLob: BUSINESS_LOB_NAME.get(adhocBusiness),
                        adhocDetail: {
                            ...adhocDetail, matchingWeekDate: undefined,
                            businessLobCd: adhocBusiness
                        } as IAdhocConflictDetail,
                        selectable: true,
                        duplicateForToday: false
                    });
                }
            });
        });
        return conflictFound ? rowData : [];
    }

    onSaveAdhocDetailError(adhoc: AdhocCreation, gpiRowCount, ndcRowCount) {
        const bannerLink = {
            bannerLinks: [
                {
                    linkText: 'Retry',
                    linkFunc: () => {
                        this.createAdhocByDetail(adhoc, gpiRowCount, ndcRowCount);
                    }
                }
            ]
        };
        this._workflowService.showAdhocWorkflowErrorBanner(bannerLink);
    }

    onSaveError(adhoc, recordCount, mergedSelectedRows, valueObject,
        gpiRowCount, ndcRowCount) {
        const bannerLink = {
            bannerLinks: [
                {
                    linkText: 'Retry',
                    linkFunc: () => {
                        this.createAdhoc(adhoc, recordCount, mergedSelectedRows, valueObject,
                            gpiRowCount, ndcRowCount);
                    }
                }
            ]
        };
        this._workflowService.showAdhocWorkflowErrorBanner(bannerLink);
    }

    createAdhocByDetail(adhoc: AdhocCreation, gpiRowCount: number, ndcRowCount: number) {
        this._trackerDataService.createAdhocByDetail(this._userService.getSuperClientContext().id, adhoc)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this._workflowService.refreshWorkflowStatusGrid(lookupRecordStatus(Number(adhoc.statusId)));
                    this._workflowService.adjustStatusCount(Number(adhoc.statusId),
                        RECORD_STATUS.DEFAULT, adhoc.details.length);
                    // eslint-disable-next-line max-len
                    const messageObject = gpiRowCount > 0 ? { message: `converted to ${adhoc.details.length} NDC(s) and moved to` } : {};
                    this._workflowService.buildAndShowMoveInWorkflowSuccessBanner({
                        statusId: adhoc.statusId, ...messageObject,
                        details: [
                            {type: 'GPI', selectedRowsCount: gpiRowCount},
                            { type: 'NDC', selectedRowsCount: ndcRowCount }
                        ]
                    });
                    this.clearAllGridSelections();
                },
                error: () => this.onSaveAdhocDetailError(adhoc,gpiRowCount, ndcRowCount)
            });
    }

    createAdhoc(adhoc: AdhocCreation, recordCount, mergedSelectedRows,
        valueObject: CLRConfirmationDialogValueObject, gpiRowCount, ndcRowCount) {
        this._trackerDataService.createAdhoc(this._userService.getSuperClientContext().id, adhoc)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this._workflowService.refreshWorkflowStatusGrid(lookupRecordStatus(Number(adhoc.statusId)));
                    this._workflowService.adjustStatusCount(Number(adhoc.statusId),
                        RECORD_STATUS.DEFAULT, recordCount ? recordCount : mergedSelectedRows.length * valueObject.businessLob.length);
                    let messageObject = {};
                    if (valueObject.convertGpiToNdc) {
                        messageObject =
                            {message: `converted to ${recordCount} NDC(s) and moved to`};
                    } else if (valueObject.convertNdcToGpi) {
                        messageObject = {message: 'converted to GPI(s) and moved to'};
                    }

                    this._workflowService.buildAndShowMoveInWorkflowSuccessBanner({
                        statusId: valueObject.statusId, ...messageObject,
                        details: [
                            {type: 'GPI', selectedRowsCount: gpiRowCount},
                            {type: 'NDC', selectedRowsCount: ndcRowCount}
                        ]
                    });
                    this.clearAllGridSelections();
                },
                error: () => this.onSaveError(adhoc, recordCount, mergedSelectedRows, valueObject, gpiRowCount, ndcRowCount)
            });
    }

    clearAllGridSelections() {
        this.drugListGrid.gridOptions?.api?.deselectAll();
        this.detailSelectedRows?.forEach(dr => {
            dr.api.deselectAll();
        });
        this.detailSelectedRows = [];
        this.selectedRows = [];
    }

    mergeParentDetailRowSelectionData(parentSelectedRows: IRowNode[],
        detailSelectedRows: { api: any; selectedRows: IRowNode[] }[]): CommonCommon[] {
        return parentSelectedRows.concat(...detailSelectedRows.map(d => d.selectedRows))
            .map(m => m.data);
    }

    showAdHocUpdateModal(
        selectedRows: CommonCommon[],
        formRef: TemplateRef<any>,
        callbackOnConfirm?: (newValue: CLRConfirmationDialogValueObject) => void
    ) {
        this.convertAttachmentGPITypeToNDC = selectedRows.some(selectedRow => selectedRow.drugAttachmentIndicatorType === 'GPI');
        this.convertAttachmentNDCTypeToGPI = selectedRows.some(selectedRow => selectedRow.drugAttachmentIndicatorType === 'NDC');
        this.selectedGpi = '';
        if (selectedRows.length === 1 && !this.convertAttachmentNDCTypeToGPI) {
            this.selectedGpi = selectedRows[0].drugAttachmentIndicator;
            this.selectedRows[0].data.formularies.forEach(formularyNumber => {
                this.selectedGPIAttachmentPayload.push({
                    formularyNumber: formularyNumber,
                    tierNumber: this.selectedRows[0].data[`formularyDLTierNumber${formularyNumber}`],
                    tierCode: this.selectedRows[0].data[`formularyDLLTTierCode${formularyNumber}`],
                    pdlCode: this.selectedRows[0].data[`formularyDLLTPreferredDLCode${formularyNumber}`],
                    fromDate: this.selectedRows[0].data[`tierFromDate${formularyNumber}`],
                    thruDate: this.selectedRows[0].data[`tierThruDate${formularyNumber}`],
                    coveredMultiSourceCode: this.selectedRows[0].data[`coveredMultisourceCodes${formularyNumber}`],
                    drugAttachmentRxOTC: this.selectedRows[0].data[`drugAttachmentRxOTC${formularyNumber}`],
                    drugAttachmentMEDD: this.selectedRows[0].data[`drugAttachmentMEDD${formularyNumber}`],
                    formularyDLLTOnFormulary: this.selectedRows[0].data[`formularyDLLTOnFormulary${formularyNumber}`],
                    formularyNDC: this.selectedRows[0].data[`formularyNDC${formularyNumber}`],
                    formularyDLHierarchy: this.selectedRows[0].data[`formularyDLHierarchy${formularyNumber}`],
                    drugAttachmentIndicatorType: this.selectedRows[0].data.drugAttachmentIndicatorType,
                });
            });
        }
        this.createAdHocUpdateModalRef = this.dialog.open(CLRConfirmationDialogContentComponent, {
            disableClose: true,
            data: {
                headline: `Create Ad Hoc Update and Add to Workflow (${selectedRows.length})`,
                actionLabel: 'Add to Workflow',
                cancelLabel: 'Cancel',
                templatePortalContent: formRef
            } as CVSConfirmationDialogData
        }).componentInstance;

        this.dialog.afterAllClosed.pipe(take(1)).subscribe(val => {
            this.selectedGPIAttachmentPayload = [];
            this.convertAttachmentGPITypeToNDC = false;
            this.gpiToNdcSelectedItems = [];
        });

        if (callbackOnConfirm) {
            this.createAdHocUpdateModalRef.onConfirmClick.pipe(take(1)).subscribe(throwAway => {
                callbackOnConfirm(this.createAdHocUpdateModalRef.valueObject);
            });
        }
    }

    getAttachmentResultMessage() {
        return `${this.formularyDrugAttachmentResponse ? this.formularyDrugAttachmentResponse.length : 'Loading'} Attachment Result(s)`;
    }
}

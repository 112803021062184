import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {ColDef, GridOptions, MenuItemDef} from '@ag-grid-community/core';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {take} from 'rxjs/operators';
import {AppService} from '../../service/app/app.service';
import {FormularyService} from '../../service/formulary/formulary.service';
import {Formulary} from '../../model/Formulary';
import {RichSelectModule} from '@ag-grid-enterprise/rich-select';
import {BUSINESS_LOB} from '../../enum/BusinessLob';
import {UserService} from '../../service/user/user.service';
import {
    AGE_LIMIT,
    BUSINESS_LOB_DESC_CD,
    BUSINESS_LOB_NAME, MED_D, PA_SELECTION_MEDICARE,
    QL_TYPE_COM_EX_MED,
    QL_TYPE_MEDICARE, RX_OTC, SEX_EXCLUSIONS,
} from '../../default-values/DefaultValues';
import {MedispanTrackerResponse} from '../../model/MedispanTrackerResponse';
import {RecommendationService} from '../../service/recommendation/recommendation.service';
import {KabobCellComponent} from '../../common/kabob-cell/kabob-cell.component';
import {Router} from '@angular/router';
import {SupplementalList} from '../../model/SupplementalList';
import {Recommendation} from 'src/app/model/Recommendation';
import _ from 'lodash';
import {RecordService} from '../../service/record/record.service';
import {buildTabSelectionChangeEvent, dateFormatter} from '../../utility/utility';
import {CVSBannerService, CVSBannerType} from 'angular-component-library';
import {
    MultiSelectListItem,
    MultiSelectModalService
} from '../../common/multi-select-modal/multi-select-modal.component';
import {Record} from '../../model/Record';
import {PRODUCT_OVERVIEW_SOURCE} from '../../enum/ProductOverviewSource';
import {Observable, Subscription} from 'rxjs';
import {GridDisplayModalService} from '../../common/grid-display-modal/grid-display-modal.component';
import {GpiLikeProduct} from '../../model/GpiLikeProduct';
import {CustomStateComponent} from '../../common/custom-state/custom-state.component';
import {MasterDrugResponse} from '../../model/MasterDrugResponse';

export interface RecommendationFormState {
    businessLobCD: string;
    formularyNumber?: string;
    medispanTrackerResponse?: MedispanTrackerResponse;
    tabs?: (string|number)[];
    drugData?: MasterDrugResponse;
}

export interface SupplementalListItem {
    name: string;
    header: string;
    value: string;
}

@Component({
    selector: 'app-recommendations',
    templateUrl: './recommendations.component.html',
    styleUrls: ['./recommendations.component.scss']
})
export class RecommendationsComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('copyPasteModalRef') public copyPasteModalRef: TemplateRef<any>;
    @ViewChild('massCopyGridModalRef') public massCopyGridModalRef: TemplateRef<any>;
    @Input() ndcBusinessLob;
    @Input() medispanTrackerResponse: MedispanTrackerResponse;
    @Input() mainTabName: string;
    @Input() subTabIndex: number;
    @Input() fromSource;
    recommendationFormState: RecommendationFormState;
    selectedBusinessLobCd;
    selectedBusinessLobName;
    recommendationDrugInfo: any[];
    supplementalListItems: SupplementalListItem[] = [];
    BUSINESS_LOB = BUSINESS_LOB;
    NA = 'N/A';
    modules = [SetFilterModule, ColumnsToolPanelModule, ClientSideRowModelModule,
        MenuModule, ClipboardModule, RichSelectModule];
    activeGridOptions: GridOptions = {
        defaultColDef: {
            sortable: true,
            resizable: true,
            suppressSizeToFit: true
        },
        enableCellTextSelection: true,
        suppressLoadingOverlay: true
    };
    context = {this: this};
    hasEditPermission = false;
    popupParent: HTMLElement | null = document.querySelector('body');
    productRecordDetail!: Record[];
    disableCopyRecommendationsToLikeProducts = false;
    isStatusPastReadyForDiscussion = false;
    tableAction: {icon: string; description: string} = {
        icon: 'paper-edit--s',
        description: 'Edit Recommendation'
    };
    subscriptions: Subscription[] = [];
    private readonly PAST_RECOMMENDATION_STATUSES = [PRODUCT_OVERVIEW_SOURCE.WORKFLOW_REGULATORY_TRACKING,
        PRODUCT_OVERVIEW_SOURCE.WORKFLOW_OPERATIONS_AND_IMPLEMENTATION, PRODUCT_OVERVIEW_SOURCE.WORKFLOW_ARCHIVE];

    private readonly SUPPLEMENTAL_LIST: {
        MEDICARE: SupplementalListItem[];
        COMMERCIAL_EXCHANGE_MEDICAID: SupplementalListItem[];
    } = {
            MEDICARE: [
                {name: 'endStageRenalDisease', header: 'End Stage Renal Disease (ESRD)', value: 'N'},
                {name: 'bvsD', header: 'B vs D', value: 'N'},
                {name: 'hospice', header: 'Hospice', value: 'N'},
                {name: 'doNotMultiplyList', header: 'Do Not Multiply List', value: 'N'},
                {name: 'unbreakable', header: 'Unbreakable', value: 'N'},
                {name: 'protectedClassDrug', header: 'Protected Class Drug (PCD)', value: 'N'},
                {name: 'diagnosisRequired', header: 'Diagnosis Required', value: 'N'},
                {name: 'contractedRebate', header: 'Contracted/ Rebate', value: 'N'},
                {name: 'specialtyPharmacy', header: 'Specialty Pharmacy', value: 'N'},
                {name: 'maintenance', header: 'Maintenance', value: 'N'},
                {name: 'valueBasedInsuranceDesign', header: 'Value Based Insurance Design (VBID)', value: 'N'},
                {name: 'limitedAccess', header: 'Limited Access', value: 'N'},
                {name: 'nonExtendedDaySupply', header: 'Non-Extended Day Supply (NDS)', value: 'N'},
            ],
            COMMERCIAL_EXCHANGE_MEDICAID: [
                {name: 'contractedRebate', header: 'Contracted / Rebate', value: 'N'},
                {name: 'valueBasedInsuranceDesign', header: 'Value Based Insurance Design (VBID)', value: 'N'},
                {name: 'limitedAccess', header: 'Limited Access', value: 'N'},
                {name: 'specialtyPharmacy', header: 'Specialty Pharmacy', value: 'N'},
                {name: 'maintenance', header: 'Maintenance', value: 'N'},
                {name: 'shortCycle', header: 'Short Cycle (Starter Fill)', value: 'N'},
                {name: 'editLift', header: 'Edit Lift', value: 'N'},
                {name: 'oralAntiCancer', header: 'Oral Anti-Cancer', value: 'N'},
                {name: 'highDeductibleHealthPlan', header: 'High Deductible Health Plan (HDHP)', value: 'N'},
                {name: 'cvsMedicalNecessityPa', header: 'CVS Medical Necessity PA (MNPA)', value: 'N'},
                {name: 'preventativeHealthDrugs', header: 'Preventive Health Drugs', value: 'N'},
                {name: 'retailOnly', header: 'Retail Only', value: 'N'},
                {name: 'qlTypeAndMaxThirtyAtMailOrder', header: 'QL Type & Max 30DS at Mail Order', value: 'N'},
                {name: 'sharedPatientSavings', header: 'Shared Patient Savings (SPS)', value: 'N'},
            ]
        };


    constructor(private _appService: AppService,
        private _formularyService: FormularyService,
        private userService: UserService,
        private _recommendationService: RecommendationService,
        private _router: Router,
        private _recordService: RecordService,
        private _multiSelectModalService: MultiSelectModalService,
        private _bannerService: CVSBannerService,
        public _gridDisplayModalService: GridDisplayModalService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.hasEditPermission = this.userService.hasEditPermission();
        if (changes.ndcBusinessLob) {
            this.ndcBusinessLob = changes.ndcBusinessLob.currentValue;
            this.onBusinessLobTabSelectionChange(
                buildTabSelectionChangeEvent(
                    BUSINESS_LOB_DESC_CD.get(this.ndcBusinessLob),
                    this.ndcBusinessLob
                )
            );
            this.buildColDefs();
        }
        if (changes.medispanTrackerResponse?.currentValue) {
            const trackerValue = changes.medispanTrackerResponse.currentValue;
            this.medispanTrackerResponse = trackerValue;
            this._recommendationService.setMedispanTrackerResponse(trackerValue);
            this.subscriptions.push(this._recordService.getProductRecordDetails(
                trackerValue.encryptedRecordId,
                trackerValue.updateType,this._appService.getSuperClientContext().id).pipe(take(1))
                .subscribe(productRecordDetail => {
                    this.productRecordDetail = productRecordDetail;
                    this.onBusinessLobTabSelectionChange(
                        buildTabSelectionChangeEvent(
                            BUSINESS_LOB_DESC_CD.get(trackerValue.businessLob),
                            trackerValue.businessLob
                        )
                    );
                }));
        }
        if(changes.fromSource?.currentValue){
            this.fromSource = changes.fromSource.currentValue;
            this.isStatusPastReadyForDiscussion = this.PAST_RECOMMENDATION_STATUSES.includes(this.fromSource);
            this.tableAction.icon = this.isStatusPastReadyForDiscussion? 'file-search--s' : 'paper-edit--s';
            this.tableAction.description = this.isStatusPastReadyForDiscussion? 'View Recommendation' : 'Edit Recommendation';
        }

    }


    ngOnInit(): void {
        this.subscriptions.push(this._recommendationService.getLoadRecommendationsComponentGridDataObservable().subscribe((recordId) => {
            this.getFormularyByBLOB(this.selectedBusinessLobCd, true, recordId);
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach( sub => sub.unsubscribe());
    }

    onBusinessLobTabSelectionChange(event: MatTabChangeEvent) {
        this.selectedBusinessLobCd = event.tab.ariaLabel;
        this.selectedBusinessLobName = event.tab.textLabel as BUSINESS_LOB;
        this.supplementalListItems = this.buildSupplementalListItems(this.selectedBusinessLobName);
        this.loadAndSetSupplementalListValues(this.supplementalListItems);
        this.buildColDefs();
        this.getFormularyByBLOB(this.selectedBusinessLobCd, false);
        this.activeGridOptions.suppressContextMenu = !this.allowEditing();
    }

    buildSupplementalListItems(selectedBusinessLobName): SupplementalListItem[] {
        if (selectedBusinessLobName === BUSINESS_LOB.MEDICARE) {
            return _.cloneDeep(this.SUPPLEMENTAL_LIST.MEDICARE);
        } else if (selectedBusinessLobName === BUSINESS_LOB.COMMERCIAL ||
            selectedBusinessLobName === BUSINESS_LOB.EXCHANGE ||
            selectedBusinessLobName === BUSINESS_LOB.MEDICAID) {
            return _.cloneDeep(this.SUPPLEMENTAL_LIST.COMMERCIAL_EXCHANGE_MEDICAID);
        }
    }

    loadAndSetSupplementalListValues(supplementalListToUpdate: SupplementalListItem[]) {
        if (this.medispanTrackerResponse) {
            const record = this.productRecordDetail?.find(i => i.businessLobCd === this.selectedBusinessLobCd);
            if (record) {
                this.subscriptions.push(this._recommendationService.getSupplementalList(record.encryptedRecordId)
                    .pipe(take(1))
                    .subscribe(supplementalList => {
                        if (supplementalList) {
                            Object.keys(supplementalList).forEach(key => {
                                const supplementalListItem = supplementalListToUpdate.find(f => f.name === key);
                                if (supplementalListItem) {
                                    supplementalListItem.value = supplementalList[key];
                                }
                            });
                        }
                    }));
            }
        }
    }

    getFormularyByBLOB(businessLobCd: string, backNavigation: boolean, encryptedRecordId?: string) {
        this._appService.getInitializationSubject().pipe(take(1)).subscribe(() => {
            let record: Record;
            const index = this._appService.getSuperClientContext().id;
            this._formularyService.getFormularyData(index).pipe(take(1)).subscribe(formularyResponse => {
                // eslint-disable-next-line max-len
                const formularies: Formulary[] = this._formularyService.getDistinctFormulariesByBusinessLob(businessLobCd, formularyResponse);
                if (!backNavigation) {
                    record = this.productRecordDetail?.find(product => product.businessLobCd === businessLobCd);
                    if (record) {
                        encryptedRecordId = record.encryptedRecordId;
                    }
                }
                if (encryptedRecordId) {
                    this.subscriptions.push(this._recommendationService.getRecommendationGridData(encryptedRecordId)
                        .pipe(take(1))
                        .subscribe((recommendation: Recommendation[]) => {
                            this.disableCopyRecommendationsToLikeProducts = recommendation.length === 0;
                            this.buildRecommendationDrugInfo(formularies);
                            this.buildRecommendationsGridData(recommendation);
                        }));
                } else {
                    this.buildRecommendationDrugInfo(formularies);
                }

                if (this.ndcBusinessLob === this.selectedBusinessLobName) {
                    this.buildRecommendationFormState(formularies[0]?.formularyNumber);
                }
            });
        });
    }

    buildRecommendationDrugInfo(formularies: Formulary[]) {
        this.recommendationDrugInfo = [];
        formularies.forEach(formulary => {
            this.recommendationDrugInfo.push({
                formularyNumber: formulary.formularyNumber,
                clientFormularyName: formulary.clientFormularyName,
                tierCount: formulary.tierCount,
                encryptedRecordId: this.medispanTrackerResponse.encryptedRecordId,
                noChangeFlag: 'N'
            });
        });
    }

    isEditable(params: { data: [] }, fieldName: string, validValues: string[]): boolean {
        return this.allowEditing() && validValues.includes(params.data[fieldName]);
    }

    allowEditing(): boolean {
        return (this.ndcBusinessLob === this.selectedBusinessLobName) && this.userService.hasEditPermission();
    }

    buildColDefs() {
        let colDef: ColDef[];
        {
            const allowEditing = this.allowEditing();
            if (this.selectedBusinessLobName === BUSINESS_LOB.MEDICARE) {
                colDef = [
                    {
                        headerName: '', field: 'kabob', cellRenderer: KabobCellComponent, editable: false, width: 40,
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        cellStyle: {cursor: 'pointer', display: 'flex', 'align-items': 'center'}, pinned: true,
                        onCellClicked: (params) => this.kabobClicked(params),
                        suppressMenu: true,
                        hide: !allowEditing
                    },
                    {
                        headerName: 'Formulary Name', field: 'clientFormularyName', filter: 'agSetColumnFilter', width: 160,
                        pinned: true
                    },
                    {
                        headerName: 'Formulary ID',
                        field: 'formularyNumber',
                        filter: 'agSetColumnFilter',
                        width: 140,
                        pinned: true
                    },
                    {
                        headerName: 'Recommendation State', field: 'statusCd', filter: 'agSetColumnFilter', width: 185,
                        cellRenderer: CustomStateComponent
                    },
                    {
                        headerName: 'Attachment Type', field: 'attachmentType', filter: 'agSetColumnFilter', width: 150
                    },
                    {
                        headerName: 'Attachment Level',
                        field: 'attachmentLevel',
                        filter: 'agSetColumnFilter',
                        width: 150
                    },
                    {
                        headerName: 'Attachment MONY', field: 'monyCode', filter: 'agSetColumnFilter', width: 172
                    },
                    {
                        headerName: 'Attachment Rx/OTC', field: 'coverageMiscRxOtc', filter: 'agSetColumnFilter', width: 160
                    },
                    {
                        headerName: 'Attachment Med D', field: 'coverageMiscMedD', filter: 'agSetColumnFilter', width: 160
                    },
                    {
                        headerName: 'Tier', field: 'tier', filter: 'agSetColumnFilter', width: 130
                    },
                    {
                        headerName: 'Prior Authorization', field: 'paSelection', filter: 'agSetColumnFilter', width: 170
                    },
                    {
                        headerName: 'PA Group Name', field: 'paGroupName', filter: 'agSetColumnFilter', width: 150
                    },
                    {
                        headerName: 'Step Type', field: 'stepType', filter: 'agSetColumnFilter', width: 100
                    },
                    {
                        headerName: 'Step Name', field: 'stepName', filter: 'agSetColumnFilter', width: 120
                    },
                    {
                        headerName: 'QL Type', field: 'qlType', filter: 'agSetColumnFilter', width: 200,
                    },
                    {
                        headerName: 'QL Amount', field: 'qlAmount', filter: 'agSetColumnFilter', width: 110
                    },
                    {
                        headerName: 'QL Unit', field: 'qlUnit', filter: 'agSetColumnFilter', width: 90
                    },
                    {
                        headerName: 'QL Qty Per Period (Days)', field: 'qQPPD', filter: 'agSetColumnFilter', width: 190
                    },
                    {headerName: 'Age Limit', field: 'ageLimit', filter: 'agSetColumnFilter', width: 100},
                    {headerName: 'Age Limit Min', field: 'ageLimitMin', filter: 'agSetColumnFilter', width: 125},
                    {
                        headerName: 'Age Limit Min Value',
                        field: 'ageLimitMinValue',
                        filter: 'agSetColumnFilter',
                        width: 160
                    },
                    {headerName: 'Age Limit Max', field: 'ageLimitMax', filter: 'agSetColumnFilter', width: 130},
                    {
                        headerName: 'Age Limit Max Value',
                        field: 'ageLimitMaxValue',
                        filter: 'agSetColumnFilter',
                        width: 165
                    },
                    {headerName: 'Gender Edit', field: 'sexExclusion', filter: 'agSetColumnFilter', width: 125},
                ];
            }

            if (this.selectedBusinessLobName === BUSINESS_LOB.COMMERCIAL ||
                this.selectedBusinessLobName === BUSINESS_LOB.EXCHANGE ||
                this.selectedBusinessLobName === BUSINESS_LOB.MEDICAID) {
                colDef = [
                    {
                        headerName: '', field: 'kabob', cellRenderer: KabobCellComponent, editable: false, width: 40,
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        cellStyle: {cursor: 'pointer', display: 'flex', 'align-items': 'center'}, pinned: true,
                        onCellClicked: (params) => this.kabobClicked(params),
                        suppressMenu: true,
                        hide: !allowEditing
                    },
                    {
                        headerName: 'Formulary Name',
                        field: 'clientFormularyName',
                        filter: 'agSetColumnFilter',
                        width: 160,
                        pinned: true
                    },
                    {
                        headerName: 'Formulary ID',
                        field: 'formularyNumber',
                        filter: 'agSetColumnFilter',
                        width: 140,
                        pinned: true
                    },
                    {
                        headerName: 'Recommendation State', field: 'statusCd', filter: 'agSetColumnFilter', width: 185,
                        cellRenderer: CustomStateComponent
                    },
                    {
                        headerName: 'Attachment Type', field: 'attachmentType', filter: 'agSetColumnFilter', width: 150
                    },
                    {
                        headerName: 'Attachment Level',
                        field: 'attachmentLevel',
                        filter: 'agSetColumnFilter',
                        width: 150
                    },
                    {
                        headerName: 'Attachment MONY', field: 'monyCode', filter: 'agSetColumnFilter', width: 172
                    },
                    {
                        headerName: 'Attachment Rx/OTC', field: 'coverageMiscRxOtc', filter: 'agSetColumnFilter', width: 160
                    },
                    {
                        headerName: 'Tier', field: 'tier', filter: 'agSetColumnFilter', width: 130
                    },
                    {
                        headerName: 'Prior Authorization', field: 'paSelection', filter: 'agSetColumnFilter', width: 160
                    },
                    {
                        headerName: 'PA Group Name', field: 'paGroupName', filter: 'agSetColumnFilter', width: 150
                    },
                    {
                        headerName: 'Step Type', field: 'stepType', filter: 'agSetColumnFilter', width: 100
                    },
                    {
                        headerName: 'Step Name', field: 'stepName', filter: 'agSetColumnFilter', width: 120
                    },
                    {
                        headerName: 'QL Type A', field: 'qlTypeA', filter: 'agSetColumnFilter', width: 224
                    },
                    {
                        headerName: 'QL Amount A', field: 'qlAmountA', filter: 'agSetColumnFilter', width: 120
                    },
                    {
                        headerName: 'QL Unit A', field: 'qlUnitA', filter: 'agSetColumnFilter', width: 100
                    },
                    {
                        headerName: 'QL Qty Per Period (Days) A',
                        field: 'qQPPDA',
                        filter: 'agSetColumnFilter',
                        width: 200
                    },
                    {
                        headerName: 'QL Type B', field: 'qlTypeB', filter: 'agSetColumnFilter', width: 224
                    },
                    {
                        headerName: 'QL Amount B', field: 'qlAmountB', filter: 'agSetColumnFilter', width: 120
                    },
                    {
                        headerName: 'QL Unit B', field: 'qlUnitB', filter: 'agSetColumnFilter', width: 100
                    },
                    {
                        headerName: 'QL Qty Per Period (Days) B',
                        field: 'qQPPDB',
                        filter: 'agSetColumnFilter',
                        width: 200
                    },
                    {
                        headerName: 'QL Type C', field: 'qlTypeC', filter: 'agSetColumnFilter', width: 224
                    },
                    {
                        headerName: 'QL Amount C', field: 'qlAmountC', filter: 'agSetColumnFilter', width: 120
                    },
                    {
                        headerName: 'QL Unit C', field: 'qlUnitC', filter: 'agSetColumnFilter', width: 100
                    },
                    {
                        headerName: 'QL Qty Per Period (Days) C',
                        field: 'qQPPDC',
                        filter: 'agSetColumnFilter',
                        width: 200
                    },
                    {headerName: 'Age Limit', field: 'ageLimit', filter: 'agSetColumnFilter', width: 100},
                    {headerName: 'Age Limit Min', field: 'ageLimitMin', filter: 'agSetColumnFilter', width: 125},
                    {
                        headerName: 'Age Limit Min Value',
                        field: 'ageLimitMinValue',
                        filter: 'agSetColumnFilter',
                        width: 160
                    },
                    {headerName: 'Age Limit Max', field: 'ageLimitMax', filter: 'agSetColumnFilter', width: 130},
                    {
                        headerName: 'Age Limit Max Value',
                        field: 'ageLimitMaxValue',
                        filter: 'agSetColumnFilter',
                        width: 165
                    },
                    {headerName: 'Gender Edit', field: 'sexExclusion', filter: 'agSetColumnFilter', width: 125},
                ];
            }
        }

        if (this.activeGridOptions.api) {
            this.activeGridOptions.api.setColumnDefs(colDef);
        } else {
            this.activeGridOptions.columnDefs = colDef;
        }
    }

    saveSupplementalList() {
        this._recommendationService.saveSupplementalList(this.medispanTrackerResponse.encryptedRecordId, this.mapSupplementalList())
            .pipe(take(1))
            .subscribe();
    }

    mapSupplementalList(): SupplementalList {
        const supplementalList: SupplementalList = {};
        this.supplementalListItems.forEach(i => {
            supplementalList[i.name] = i.value;
        });
        return supplementalList;
    }

    buildRecommendationsGridData(recommendationsData: Recommendation[]) {
        recommendationsData.forEach(recommendationData => {
            if (recommendationData.tier === undefined) {
                recommendationData.tier = 'No Change';
            }
            const foundFormulary = this.recommendationDrugInfo
                .find(recDrugInfo => recDrugInfo.formularyNumber === recommendationData.formularyNumber);

            if (foundFormulary) {
                foundFormulary.hasRecommendation = true;
                Object.keys(recommendationData).forEach(key => {
                    foundFormulary[key] = recommendationData[key];
                });
                if (this.selectedBusinessLobName === BUSINESS_LOB.MEDICARE) {
                    this.setMedicareNAData(foundFormulary);
                    this.setPriorAuthMedicare(foundFormulary);
                    this.setStepTherapyMedicare(foundFormulary);
                    this.setMedicareAttachmentMedD(foundFormulary);
                }
                if (this.selectedBusinessLobName === BUSINESS_LOB.COMMERCIAL ||
                    this.selectedBusinessLobName === BUSINESS_LOB.MEDICAID ||
                    this.selectedBusinessLobName === BUSINESS_LOB.EXCHANGE) {
                    this.setCommercialMedicaidExchangeNAData(foundFormulary);
                    this.setPriorAuthCode(foundFormulary);
                    this.setStepTherapyCodeNonMED(foundFormulary);
                }
                this.setAgeLimitCode(foundFormulary);
                this.setAttachmentLevelFormat(foundFormulary);
                this.setGenderEditCode(foundFormulary);
                this.setQLTypeCode(foundFormulary);
                this.setRxOtcCode(foundFormulary);
            }

        });
    }

    setMedicareNAData(formularyRecData) {
        if (formularyRecData['attachmentType'] === 'NDC') {
            formularyRecData['monyCode'] = this.NA;
        } else if (formularyRecData['attachmentType'] === 'GPI') {
            formularyRecData['monyCode'] = formularyRecData['monyCode']?.split('').join(', ');
        }
        if (formularyRecData['paSelection'] === '0') {
            formularyRecData['paGroupName'] = this.NA;
        }
        if (formularyRecData['stepType'] === '0') {
            formularyRecData['stepName'] = this.NA;
            formularyRecData['stepBehavior'] = this.NA;
        }
        if (formularyRecData['qlType'] === '0 - None') {
            formularyRecData['qlAmount'] = this.NA;
            formularyRecData['qlUnit'] = this.NA;
            formularyRecData['qQPPD'] = this.NA;
        }
    }

    setQLTypeCode(formularyRecData) {
        if (QL_TYPE_MEDICARE.find(i => i.code === formularyRecData['qlType'])) {
            formularyRecData['qlType'] = QL_TYPE_MEDICARE.find(i => i.code === formularyRecData['qlType'])
                .description.replace(/\s*\(.*?\)\s*/g, '');
        }
        if (QL_TYPE_COM_EX_MED.find(i => i.code === formularyRecData['qlTypeA'])) {
            formularyRecData['qlTypeA'] = QL_TYPE_COM_EX_MED.find(i => i.code === formularyRecData['qlTypeA']).description
                .replace(/\s*\(.*?\)\s*/g, '');
        }
        if (QL_TYPE_COM_EX_MED.find(i => i.code === formularyRecData['qlTypeB'])) {
            formularyRecData['qlTypeB'] = QL_TYPE_COM_EX_MED.find(i => i.code === formularyRecData['qlTypeB']).description
                .replace(/\s*\(.*?\)\s*/g, '');
        }
        if (QL_TYPE_COM_EX_MED.find(i => i.code === formularyRecData['qlTypeC'])) {
            formularyRecData['qlTypeC'] = QL_TYPE_COM_EX_MED.find(i => i.code === formularyRecData['qlTypeC']).description
                .replace(/\s*\(.*?\)\s*/g, '');
        }
    }

    setCommercialMedicaidExchangeNAData(formularyRecData) {
        if (formularyRecData['attachmentType'] === 'NDC') {
            formularyRecData['monyCode'] = this.NA;
        } else if (formularyRecData['attachmentType'] === 'GPI') {
            formularyRecData['monyCode'] = formularyRecData['monyCode']?.split('').join(', ');
        }
        if (formularyRecData['paSelection'] === '0') {
            formularyRecData['paGroupName'] = this.NA;
        }
        if (formularyRecData['stepType'] === '0') {
            formularyRecData['stepName'] = this.NA;
        }
        if (formularyRecData['qlTypeA'] === 'None') {
            formularyRecData['qlAmountA'] = this.NA;
            formularyRecData['qlUnitA'] = this.NA;
            formularyRecData['qQPPDA'] = this.NA;
        }
        if (formularyRecData['qlTypeB'] === 'None') {
            formularyRecData['qlAmountB'] = this.NA;
            formularyRecData['qlUnitB'] = this.NA;
            formularyRecData['qQPPDB'] = this.NA;
        }
        if (formularyRecData['qlTypeC'] === 'None') {
            formularyRecData['qlAmountC'] = this.NA;
            formularyRecData['qlUnitC'] = this.NA;
            formularyRecData['qQPPDC'] = this.NA;
        }
    }

    getContextMenuItems(params): MenuItemDef[] {
        const name = params.context.this.isStatusPastReadyForDiscussion? 'View Recommendation' : 'Edit Recommendation';
        const options: MenuItemDef[] = [
            {
                name: name,
                action: () => {
                    params.context.this._router.navigate(['/recommendationsForm'],
                        {state: params.context.this.recommendationFormState});
                }
            }];

        if (params.api.getRenderedNodes().length > 1 && params.node.data.hasRecommendation &&
            !params.context.this.isStatusPastReadyForDiscussion) {
            options.push({
                name: 'Copy Recommendations to Formularies',
                action: () => {
                    params.context.this.copyRecommendationToFormularies(params);
                }
            });
        }

        return options;
    }

    public kabobClicked(params: any) {
        this.buildRecommendationFormState(params.data.formularyNumber);
        params.api.contextMenuFactory.showMenu(
            params.node, params.column, params.value, params.event
        );
    }

    buildRecommendationFormState(formularyNumber?: string) {
        this.recommendationFormState = {
            businessLobCD: this.selectedBusinessLobCd,
            formularyNumber: formularyNumber,
            medispanTrackerResponse: this.medispanTrackerResponse,
            tabs: [this.mainTabName, this.subTabIndex]
        };
    }

    copyRecommendationToFormularies(params) {
        this._bannerService.close();
        const listItems: MultiSelectListItem[] =
            params.api.getRenderedNodes()
                .filter(m => m.data.formularyNumber !== params.node.data.formularyNumber)
                .map(m => ({name: m.data.clientFormularyName, value: m.data.formularyNumber}));
        const bodyMessage =
            `Copy Recommendations for ${params.node.data.clientFormularyName} (${params.node.data.formularyNumber}) to:`;
        this._multiSelectModalService.show(
            this.copyPasteModalRef,
            'Copy Recommendations',
            bodyMessage,
            'Paste',
            listItems)
            .pipe(take(1)).subscribe((selectedItems) => {
                if (selectedItems) {
                    const sourceFormularyId = params.node.data.formularyNumber;
                    const sourceEncryptedRecordId = this.medispanTrackerResponse.encryptedRecordId;
                    const targetFormularyIds = selectedItems;
                    const index = this._appService.getSuperClientContext().id;
                    this._recommendationService.copyRecommendation(index,sourceEncryptedRecordId,sourceFormularyId,targetFormularyIds)
                        .pipe(take(1)).subscribe({
                            next: () => this.getFormularyByBLOB(this.selectedBusinessLobCd, false),
                            error: () => {
                                this._bannerService.sendAlert({
                                    headline: 'Error when saving from copy/paste operation.',
                                    body: 'Error when saving from copy/paste operation.',
                                    bannerType: CVSBannerType.Error,
                                    outletId: '#successBanner',
                                    hideX: true
                                });
                            }
                        });
                }
            });
    }

    mapRecommendation(formularyNumber, targetRow, sourceRecommendation): Recommendation {
        let otherDropDownOptions = [
            'Non-Formulary',
            'Part B Pay at POS',
            'Part A/B Deny as POS',
            'Excluded'
        ];

        if (this.selectedBusinessLobName !== BUSINESS_LOB.MEDICARE) {
            otherDropDownOptions = [
                'Non-Formulary',
                'Excluded MB',
                'Excluded OTC'
            ];
        }

        if (sourceRecommendation.tier === undefined ||
            +sourceRecommendation.tier <= +this.findRecommendation(formularyNumber)?.tierCount ||
            otherDropDownOptions.includes(sourceRecommendation.tier)) {
            return {
                ...sourceRecommendation,
                isDirty: false,
                encryptedRecordId: targetRow.node.data.encryptedRecordId,
                formularyNumber: formularyNumber,
                encryptedRecommendationId: undefined
            };
        }
        return undefined;
    }

    findRecommendation(formularyNumber: string) {
        return this.recommendationDrugInfo
            .filter(drugInformation => drugInformation.formularyNumber === formularyNumber)[0];
    }

    copyRecommendationsToLikeProducts() {
        this._gridDisplayModalService.showSpinner = true;
        this.findGPILikeProducts().pipe(take(1))
            .subscribe(likeProducts => {
                likeProducts.map(likeProduct => likeProduct.businessLob = BUSINESS_LOB_NAME.get(likeProduct.businessLob));
                this.showCopyRecommendationsToLikeProductsModal(likeProducts);
            });
    }

    findGPILikeProducts(): Observable<GpiLikeProduct[]> {
        const index = this._appService.getSuperClientContext().id;
        return this._recommendationService.getGpiLikeProducts(index,
            this.medispanTrackerResponse.encryptedRecordId,
            BUSINESS_LOB_DESC_CD.get(this.medispanTrackerResponse.businessLob),
            this.medispanTrackerResponse.gpi
        );
    }

    showCopyRecommendationsToLikeProductsModal(gpiLikeProducts: GpiLikeProduct[]) {
        if (this.fromSource === PRODUCT_OVERVIEW_SOURCE.WORKFLOW_RESEARCH_AND_RECOMMENDATIONS ||
            this.fromSource === PRODUCT_OVERVIEW_SOURCE.WORKFLOW_OPERATIONS_AND_IMPLEMENTATION ||
            this.fromSource === PRODUCT_OVERVIEW_SOURCE.WORKFLOW_REGULATORY_TRACKING ||
            this.fromSource === PRODUCT_OVERVIEW_SOURCE.WORKFLOW_ARCHIVE ||
            this.fromSource === PRODUCT_OVERVIEW_SOURCE.GPI_TO_NDC_ADHOC) {
            this._bannerService.close();
            const columnDefs: ColDef[] = [{headerName: 'Date', field: 'weekDt',
                valueFormatter: params => dateFormatter(params.data.weekDt)},
            {headerName: 'GPI', field: 'gpi'},
            {headerName: 'GPI Name', field: 'gpiName'},
            {headerName: 'NDC', field: 'ndc'},
            {headerName: 'LabelName', field: 'labelName'},
            {headerName: 'Line of Business', field: 'businessLob'},
            {headerName: 'MONY', field: 'mony'}];
            const bodyMessage =
                // eslint-disable-next-line max-len
                `Displaying all GPI 12 matches currently in Research and/or Ready for Clinical Recommendation for ${this.ndcBusinessLob}. ` +
                'Select GPI(s) below for current\nrecommendation(s) to copy to:';
            this._gridDisplayModalService.show(
                this.massCopyGridModalRef,
                'Copy Recommendations to Like Products',
                bodyMessage,
                'Paste to selected GPIs',
                gpiLikeProducts,
                {columnDefs} as GridOptions,
                true)
                .pipe(take(1)).subscribe((selectedNodes) => {
                    if (selectedNodes) {
                        const sourceFormularyIds = this.activeGridOptions.api.getRenderedNodes()
                            .map(sourceData => sourceData.data.formularyNumber);
                        const sourceEncryptedRecordId = this.medispanTrackerResponse.encryptedRecordId;
                        const targetRecordIds = selectedNodes.map(selectedNode => selectedNode.data.encryptedRecordId);
                        const index = this._appService.getSuperClientContext().id;
                        this._recommendationService
                            .copyRecommendationAndSupplemental(index, sourceEncryptedRecordId, sourceFormularyIds, targetRecordIds)
                            .pipe(take(1)).subscribe(() => this._bannerService.sendAlert({
                                bannerType: CVSBannerType.Success,
                                headline: 'Success',
                                body: `GPI recommendations copied to ${selectedNodes.length} related GPI(s)`,
                                outletId: '#successBanner',
                                isHorizontalBannerLinks: false,
                                bannerLinks: selectedNodes.map(node => ({
                                    linkText: this.getLinkText(node.data),
                                    linkFunc: () => {
                                        this._router.onSameUrlNavigation = 'reload';
                                        this._router.navigate(
                                            ['/productOverview'],
                                            {
                                                state: {
                                                    medispanTrackerData: {
                                                        ...node.data,
                                                    },
                                                    navigationSource: '/workflow/research/inResearch',
                                                    source: 4,
                                                    mainTabName: 'Research & Recommendations',
                                                    subTabIndex: node.data.statusId === 100 ? 0 : 1
                                                },
                                            });
                                    }
                                }))
                            }), error1 =>
                                this._bannerService.sendAlert({
                                    bannerType: CVSBannerType.Error,
                                    headline: 'Server Error: Unable to paste recommendations',
                                    // eslint-disable-next-line max-len
                                    body: `Oops, it looks like there was an issue copying and saving your recommendations. Please try again.`,
                                    outletId: '#successBanner'
                                })
                            );
                    }
                });
        }
    }

    getLinkText(data: GpiLikeProduct): string {
        if (data.ndc) {
            return `NDC: ${data.ndc} - ${data.labelName}`;
        }
        return `GPI: ${data.gpi} - ${data.gpiName}`;
    }

    setAttachmentLevelFormat(formularyRecData){
        if(formularyRecData.attachmentLevel && formularyRecData.attachmentType) {
            formularyRecData['attachmentLevel'] = '(' + formularyRecData.attachmentType + ' ' + formularyRecData.attachmentLevel.length
                + ') ' + formularyRecData.attachmentLevel;
        }
    }

    setPriorAuthCode(formularyRecData){
        if(formularyRecData.paSelection === '1'){
            formularyRecData['paSelection'] = 'PA';
        }
        if(formularyRecData.paSelection === '2'){
            formularyRecData['paSelection'] = 'Remove PA';

        }
    }
    setPriorAuthMedicare(formularyRecData){
        const matchingEntry = Object.values(PA_SELECTION_MEDICARE).find(i => i.code === formularyRecData['paSelection']);
        if (matchingEntry){
            formularyRecData['paSelection'] = matchingEntry.gridDisplay;
        }
    }

    setStepTherapyCodeNonMED(formularyRecData){
        if(formularyRecData.stepType === '1'){
            formularyRecData['stepType'] = 'ST';
        }
        if(formularyRecData.stepType === '2'){
            formularyRecData['stepType'] = 'Remove ST';
        }
    }
    setStepTherapyMedicare(formularyRecData){
        if(formularyRecData.stepType === '0'){
            formularyRecData['stepType'] = '0 - None';
        }
        if(formularyRecData.stepType === '1'){
            formularyRecData['stepType'] = '1 - ST';
        }
        if(formularyRecData.stepType === '2'){
            formularyRecData['stepType'] = '2 - ST';
        }
    }

    setAgeLimitCode(formularyRecData){
        if (AGE_LIMIT.find(i => i.code === formularyRecData['ageLimit'])) {
            formularyRecData['ageLimit'] = AGE_LIMIT.find(i => i.code === formularyRecData['ageLimit']).description;
        }
    }
    setGenderEditCode(formularyRecData){
        if (SEX_EXCLUSIONS.find(i => i.code === formularyRecData['sexExclusion'])) {
            formularyRecData['sexExclusion'] = SEX_EXCLUSIONS.find(i => i.code === formularyRecData['sexExclusion']).description;
        }
    }
    setMedicareAttachmentMedD(formularyRecData){
        if (MED_D.find(i => i.code === formularyRecData['coverageMiscMedD'])) {
            formularyRecData['coverageMiscMedD'] = MED_D.find(i => i.code === formularyRecData['coverageMiscMedD']).description;
        }
    }
    setRxOtcCode(formularyRecData){
        if (RX_OTC.find(i => i.code === formularyRecData['coverageMiscRxOtc'])) {
            formularyRecData['coverageMiscRxOtc'] = RX_OTC.find(i => i.code === formularyRecData['coverageMiscRxOtc']).description;
        }
    }
}

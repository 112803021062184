import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
    CVSAlertType,
    CVSBannerService,
    CVSBannerType,
} from 'angular-component-library';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FORMULARY_DATE_BOUNDARY } from 'src/app/enum/FormularyDateBoundary';
import {take} from 'rxjs/operators';
import {CVSBannerComponentData} from 'angular-component-library/completed/banner/banner.interfaces';
import {ClientProductDrugCoverage} from '../../../model/ClientProductDrugCoverage';
import {PRODUCT_DRUG_COVERAGE_TYPE} from '../../../default-values/DefaultValues';
import {AppService} from '../../../service/app/app.service';
import {ConfigurationService} from '../../../service/configuration/configuration.service';
import {validDateRange} from '../dateRangeValidation';
@Component({
    selector: 'app-product-drug-coverage',
    templateUrl: './product-drug-coverage.component.html',
    styleUrls: ['./product-drug-coverage.component.scss']
})
export class ProductDrugCoverageComponent implements OnInit {
    @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();

    hasTruncRejectMessageError = false;
    rejectionMessageValue='';
    productDrugCoverageForm: FormGroup;
    coverageTypes = PRODUCT_DRUG_COVERAGE_TYPE;

    constructor(
        private fb: FormBuilder,
        private _appService: AppService,
        private _configurationService: ConfigurationService,
        private _bannerService: CVSBannerService
    ) {
        this.createDrugCoverageForm();
    }
    ngOnInit(): void {
        this.resetDrugCoverageModalFields();
    }

    public createDrugCoverageForm() {
        this.productDrugCoverageForm = this.fb.group({
            type: [''],
            name: [''],
            description: [''],
            viewStartDate: [new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE), [Validators.required, validDateRange]],
            viewEndDate: [new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE), [Validators.required, validDateRange]],
            effectiveStartDate: [new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE), [Validators.required, validDateRange]],
            effectiveEndDate: [new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE), [Validators.required, validDateRange]],
            rejectionMessage: ['']
        });
    }

    saveProductDrugCoverage() {
        this.addDrugCoverageModalValidators();
        this.productDrugCoverageForm.markAllAsTouched();
        const isFormValid = this.validateProductDrugCoverageForm() && this.productDrugCoverageForm.valid;
        if (isFormValid) {
            const clientProductDrugCoverage: ClientProductDrugCoverage = {
                productDrugCoverageTypeCd: null,
                productDrugCoverageName: null,
                productDrugCoverageDescription: null,
                rank: null, active: null,
                productDrugCoverageViewStartDate: null,
                productDrugCoverageViewEndDate: null,
                productDrugCoverageEffectiveStartDate: null,
                productDrugCoverageEffectiveEndDate: null,
                rejectionMessage: null,
            };

            clientProductDrugCoverage.active = 'Y';
            clientProductDrugCoverage.productDrugCoverageTypeCd = this.productDrugCoverageForm.controls['type'].value;
            clientProductDrugCoverage.productDrugCoverageName = this.productDrugCoverageForm.controls['name'].value;
            clientProductDrugCoverage.productDrugCoverageDescription = this.productDrugCoverageForm.controls['description'].value;
            clientProductDrugCoverage.productDrugCoverageViewStartDate = this.productDrugCoverageForm.controls['viewStartDate'].value;
            clientProductDrugCoverage.productDrugCoverageViewEndDate = this.productDrugCoverageForm.controls['viewEndDate'].value;
            clientProductDrugCoverage.productDrugCoverageEffectiveStartDate =
                this.productDrugCoverageForm.controls['effectiveStartDate'].value;
            clientProductDrugCoverage.productDrugCoverageEffectiveEndDate = this.productDrugCoverageForm.controls['effectiveEndDate'].value;
            clientProductDrugCoverage.rejectionMessage = this.productDrugCoverageForm.controls['rejectionMessage'].value;

            const index = this._appService.getSuperClientContext().id;
            this._configurationService.saveClientProductDrugCoverage(clientProductDrugCoverage, index).pipe(take(1))
                .subscribe({
                    next: () => {
                        this.productDrugCoverageForm.markAsPristine();
                        this.onSaveProductDrugCoverageSuccess();
                        this.submitEvent.emit(true);
                    }, error: () => {
                        this.onSaveProductDrugCoverageError();
                    }
                });
        }
    }

    onSaveProductDrugCoverageSuccess() {
        const successBannerData = {
            bannerType: CVSBannerType.Success,
            outletId: '#successBanner',
            headline: 'Success',
            body: 'Product and Drug Coverage has been created.',
            hideX: false,
            alertType: CVSAlertType.Success,
        } as CVSBannerComponentData;
        this._bannerService.sendAlert(successBannerData);
    }

    onSaveProductDrugCoverageError() {
        const errorBannerData = {
            bannerType: CVSBannerType.Error,
            outletId: '#productDrugCoverageErrorBanner',
            hideX: true,
            headline: 'System error: Changes have not been saved',
            body: 'Please try again.',
            bannerLinks: [
                {
                    linkText: 'Retry',
                    linkFunc: () => {
                        this.saveProductDrugCoverage();
                    }
                }
            ]
        } as CVSBannerComponentData;
        this._bannerService.sendAlert(errorBannerData);
    }

    stopWarning() {
        this.hasTruncRejectMessageError = false;
    }

    validateProductDrugCoverageForm() {
        if (!this.productDrugCoverageForm.controls['type'].errors?.required &&
            !this.productDrugCoverageForm.controls['name'].errors?.required &&
            !this.productDrugCoverageForm.controls['description'].errors?.required) {
            return true;
        } else {
            return false;
        }
    }

    onRejectionMessageDrugChanges() {
        if (this.productDrugCoverageForm.controls['rejectionMessage'].value?.length > 210) {
            if (this.rejectionMessageValue.length === 0) {
                this.hasTruncRejectMessageError = true;
            }
            this.productDrugCoverageForm.controls['rejectionMessage']
                .setValue(this.productDrugCoverageForm.controls['rejectionMessage'].value?.substring(0, 210));
        } else {
            this.hasTruncRejectMessageError = false;
        }
        this.rejectionMessageValue = this.productDrugCoverageForm.controls['rejectionMessage'].value;
    }

    onModalCancel() {
        this.closeEvent.emit(true);
    }

    private addDrugCoverageModalValidators() {
        this.productDrugCoverageForm.controls['type'].addValidators([Validators.required]);
        this.productDrugCoverageForm.controls['type'].updateValueAndValidity({onlySelf: true});
        this.productDrugCoverageForm.controls['name'].addValidators([Validators.required]);
        this.productDrugCoverageForm.controls['name'].updateValueAndValidity({onlySelf: true});
        this.productDrugCoverageForm.controls['description'].addValidators([Validators.required]);
        this.productDrugCoverageForm.controls['description'].updateValueAndValidity({onlySelf: true});
    }

    private resetDrugCoverageModalFields() {
        this.productDrugCoverageForm.reset({
            viewStartDate: new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE),
            viewEndDate: new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE),
            effectiveStartDate: new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE),
            effectiveEndDate: new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE)
        });
        this.productDrugCoverageForm.controls['type'].removeValidators([Validators.required]);
        this.productDrugCoverageForm.controls['type'].updateValueAndValidity({onlySelf: true});
        this.productDrugCoverageForm.controls['name'].removeValidators([Validators.required]);
        this.productDrugCoverageForm.controls['name'].updateValueAndValidity({onlySelf: true});
        this.productDrugCoverageForm.controls['description'].removeValidators([Validators.required]);
        this.productDrugCoverageForm.controls['description'].updateValueAndValidity({onlySelf: true});
        this.rejectionMessageValue = '';
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges, ViewChild
} from '@angular/core';
import {FormularyService} from '../../service/formulary/formulary.service';
import {Subscription, take} from 'rxjs';
import {AppService} from '../../service/app/app.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BusinessLob} from '../../model/BusinessLob';
import {ConfigurationService} from '../../service/configuration/configuration.service';
import {CVSBannerService, CVSBannerType} from 'angular-component-library';
import {ClientLobWorkflow} from '../../model/ClientLobWorkflow';
import {CLIENT_CONFIGURATION_STATUS} from '../../enum/ClientConfigurationStatus';
import {CLIENT_MULTI_SOURCE_SETUP} from '../../default-values/DefaultValues';
import {ClientWorkflowYear} from '../../model/ClientWorkflowYear';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatSelect} from '@angular/material/select';
import {CLIENT_CONFIGURATION_STEPPER} from '../../enum/ClientConfigurationStepper';
import { Router } from '@angular/router';
import { ICLRRouteReuseStrategy } from 'src/app/ICLRRouteReuseStrategy';

@Component({
    selector: 'app-lob-workflow',
    templateUrl: './lob-workflow.component.html',
    styleUrls: ['./lob-workflow.component.scss']
})
export class LobWorkflowComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('selectAllCheckbox') public selectAllCheckbox: MatCheckbox;
    @ViewChild('multiSelectCheckbox', {static: false}) public multiSelectCheckbox: MatSelect;
    @Output() isSaveSuccessful = new EventEmitter<boolean>();
    @Input() currentStepFromParent;
    superClientLobs: BusinessLob[] = [];
    subscriptions: Subscription[] = [];
    clientMultiSourceSetupTooltip = 'This drives if the Medispan Multi-source code changes are viewable on the Medispan report';
    lobWorkFlowFormGroup: FormGroup;
    ROLLING = 'Rolling';
    formularyYears: { [key: number]: number[] | [] } = {};
    warningHeader = 'Form was saved, but there are incomplete fields';
    warningBody = 'Resolve all errors on this step to move forward.';
    multiSourceSetupControl = new FormControl('CR');
    clientLobWorkflows: ClientLobWorkflow[];
    displayClientWorkflow = false;
    protected readonly CLIENT_MULTI_SOURCE_SETUP = CLIENT_MULTI_SOURCE_SETUP;

    constructor(private _appService: AppService, private _formularyService: FormularyService, private _formBuilder: FormBuilder,
        private _configService: ConfigurationService,
        private _router: Router,
        private _bannerService: CVSBannerService) {
    }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentStepFromParent && changes.currentStepFromParent.currentValue === CLIENT_CONFIGURATION_STEPPER.LOB_WORKFLOW) {
            this.populateWorkflowLobDataAndCreateControls();
        }
    }

    populateWorkflowLobDataAndCreateControls(): void {
        const index = this._appService.getSuperClientContext().id;
        this.subscriptions.push(this._formularyService.getSuperClientBusinessLobs(index).pipe(take(1))
            .subscribe(lobResponse => {
                this.superClientLobs = [...lobResponse].sort((a, b) =>
                    (String(a.description) > String(b.description)) ? 1 : -1);
                this.superClientLobs.map(m => m.isLobDisabled = true);
                this.populateFormularyYears(index);
                this.getClientLobWorkflows(index);
            })
        );
    }

    toggleAllSelectionCheckbox($event, selectAllCheckbox) {
        if (selectAllCheckbox?.checked) {
            this.lobWorkFlowFormGroup.get(`workflowYears_${$event.description}`).patchValue(this.formularyYears[$event.description]);
            $event.selectAllChecked = true;
        } else {
            this.lobWorkFlowFormGroup.get(`workflowYears_${$event.description}`).reset();
            $event.selectAllChecked = false;
        }
    }

    populateFormularyYears(index: number) {
        this._configService.getClientLobWorkflows(index).pipe(take(1)).subscribe(data => {
            this.superClientLobs.forEach(lob => {
                const currentYear = new Date().getFullYear();
                const years: number[] = [currentYear - 1, currentYear, currentYear + 1];
                if (data) {
                    const filteredFormularyYears =
                        data?.filter(clientFormularyMapping => clientFormularyMapping.businessLobCode === lob.code
                        && clientFormularyMapping.clientWorkflowYears)
                            .flatMap(clientFormularyMapping => clientFormularyMapping.clientWorkflowYears.map(item => item.year));
                    const formularyYears = Array.from(new Set([...filteredFormularyYears, ...years]));
                    this.formularyYears[lob.description] = formularyYears;
                } else {
                    this.formularyYears[lob.description] = years;
                }
            });
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    createAndPopulateLobWorkFlowFormGroup() {
        const group: any = {};
        this.superClientLobs.forEach(lob => {
            let clientLobWorkflow;
            let workflowYears;
            if (this.clientLobWorkflows) {
                clientLobWorkflow = this.clientLobWorkflows.find(lobWorkflow => lobWorkflow.businessLobCode === lob.code);
                workflowYears = clientLobWorkflow?.clientWorkflowYears.map(item => item.year);
            }
            const workflowRadioValue =
                (clientLobWorkflow && clientLobWorkflow.multipleYearPreference) ? clientLobWorkflow.multipleYearPreference : 'N';
            group[`workflowRadio_${lob.description}`] = new FormControl(workflowRadioValue);
            const workflowYearsValue = (workflowRadioValue === 'Y') ? workflowYears : [this.ROLLING];
            lob.isLobDisabled = (!(clientLobWorkflow !== undefined && clientLobWorkflow.multipleYearPreference === 'Y'));
            this.setLobSelectAllAndIndeterminateState(lob, workflowYears);
            group[`workflowYears_${lob.description}`] = new FormControl({
                value: workflowYearsValue,
                disabled: lob.isLobDisabled
            });
            if (workflowYearsValue !== '[this.ROLLING]') {
                group[`workflowYears_${lob.description}`].setValidators([Validators.required]);
            }
        });
        this.lobWorkFlowFormGroup = this._formBuilder.group(group);
        this.multiSourceSetupControl.patchValue(this.clientLobWorkflows ? this.clientLobWorkflows[0].clientMultiSourceSetupCode : 'CR');
        this.displayClientWorkflow = true;
        this.subscribeToRadioChanges();
        this.subscribeToMultiSourceChanges();
    }

    setLobSelectAllAndIndeterminateState(lob: BusinessLob, workflowYears) {
        if (this.formularyYears[lob.description]?.length === workflowYears?.length
            && this.formularyYears[lob.description]?.length !== undefined) {
            lob.selectAllChecked = true;
            lob.indeterminateState = false;
        } else if (workflowYears?.length > 0 && this.formularyYears[lob.description]?.length !== workflowYears.length) {
            lob.indeterminateState = true;
            lob.selectAllChecked = false;
        } else if (this.formularyYears[lob.description]?.length === workflowYears?.length || workflowYears?.length === undefined) {
            lob.indeterminateState = false;
            lob.selectAllChecked = false;
        }
    }

    subscribeToMultiSourceChanges() {
        this.multiSourceSetupControl.valueChanges.subscribe(value => {
            if (!this.multiSourceSetupControl.pristine) {
                this._configService.setIsLobConfigModified(true);
            }
        });
    }

    subscribeToRadioChanges() {
        this.superClientLobs.forEach(blob => {
            const radioControlName = `workflowRadio_${blob.description}`;
            const workflowYearsControl = this.lobWorkFlowFormGroup.get(`workflowYears_${blob.description}`);
            this.lobWorkFlowFormGroup.get(radioControlName).valueChanges.subscribe(value => {
                this._configService.setIsLobConfigModified(true);
                if (value === 'N') {
                    workflowYearsControl.patchValue([this.ROLLING]);
                    workflowYearsControl.disable();
                    blob.isLobDisabled = true;
                    blob.indeterminateState = false;
                    blob.selectAllChecked = false;
                } else {
                    blob.isLobDisabled = false;
                    let workflowYears = [];
                    if (this.clientLobWorkflows !== null) {
                        const clientLobWorkflow = this.clientLobWorkflows.find(lobWorkflow => lobWorkflow.businessLobCode === blob.code);
                        workflowYears = clientLobWorkflow.clientWorkflowYears.map(item => item.year);
                    }
                    workflowYearsControl.patchValue(workflowYears);
                    workflowYearsControl.setValidators([Validators.required]);
                    workflowYearsControl.enable();
                    workflowYearsControl.updateValueAndValidity();
                    this.populateFormularyYears(this._appService.getSuperClientContext().id);
                }
            });
        });
    }

    saveLobWorkflows() {
        const clientLobWorkflowYears = this.buildClientLobWorkflowYears();
        const index = this._appService.getSuperClientContext().id;
        if (this.lobWorkFlowFormGroup.valid) {
            this._configService.saveClientLobWorkflows(clientLobWorkflowYears, index).pipe(take(1)).subscribe(() => {
                this._bannerService.sendAlert({
                    bannerType: CVSBannerType.Success,
                    headline: 'Success',
                    body: 'Changes have been saved.',
                    outletId: '#successBanner',
                });
                this.isSaveSuccessful.emit(true);
                this.multiSourceSetupControl.reset();
                this._configService.setIsLobConfigModified(false);
                (this._router.routeReuseStrategy as ICLRRouteReuseStrategy)?.delete('workflowmedispancurrentReport');
            });
        } else {
            this._configService.saveClientLobWorkflows(clientLobWorkflowYears, index).pipe(take(1)).subscribe(() => {
                this._bannerService.sendAlert({
                    bannerType: CVSBannerType.Warning,
                    headline: this.warningHeader,
                    body: this.warningBody,
                    outletId: '#warningBanner'
                });
                this.isSaveSuccessful.emit(false);
                this._configService.setIsLobConfigModified(false);
            });
        }
    }

    buildClientLobWorkflowYears(): ClientLobWorkflow[] {
        const clientLobWorkflows: ClientLobWorkflow[] = [];
        this.superClientLobs.forEach(lob => {
            const blobRadioValue = this.lobWorkFlowFormGroup.get(`workflowRadio_${lob.description}`).value;
            const clientLobWorkflow: ClientLobWorkflow = {
                multipleYearPreference: blobRadioValue,
                clientWorkflowYears: blobRadioValue === 'Y' ?
                    this.buildClientWorkflowYears(this.lobWorkFlowFormGroup.get(`workflowYears_${lob.description}`).value) : [],
                businessLobCode: lob.code,
                lobWorkflowStatus: CLIENT_CONFIGURATION_STATUS.DRAFT,
                clientMultiSourceSetupCode: this.multiSourceSetupControl.value
            };
            clientLobWorkflows.push(clientLobWorkflow);
        });
        return clientLobWorkflows;
    }

    buildClientWorkflowYears(years: number[]) {
        const clientWorkflowYears: ClientWorkflowYear[] = [];
        years.forEach(year => {
            clientWorkflowYears.push({year: year});
        });
        return clientWorkflowYears;
    }

    getClientLobWorkflows(index: number) {
        this._configService.getClientLobWorkflows(index).pipe(take(1)).subscribe(data => {
            this.clientLobWorkflows = data;
            this.createAndPopulateLobWorkFlowFormGroup();
        });
    }

    onMatSelectionChange(multiSelectCheckbox: MatSelect, $event) {
        const selectedList = multiSelectCheckbox.options.filter(opt => opt.selected).length;
        $event.indeterminateState = (selectedList > 0 && selectedList < this.formularyYears[$event.description].length);
        if (!$event.selectAllChecked && selectedList === this.formularyYears[$event.description].length) {
            $event.selectAllChecked = true;
        } else {
            $event.selectAllChecked = false;
        }
    }
}

import {Injectable} from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {HttpDataCache} from '../HttpDataCache';
import {Record} from '../../model/Record';
import {Observable} from 'rxjs';
import {ENVIRONMENT} from '../../environments/environment';
import {convertDateToMidnightISO} from '../../utility/utility';
import {TrackingDate} from '../../model/TrackingDate';

@Injectable({
    providedIn: 'root'
})
export class RecordService extends HttpDataCache {

    constructor(private cvsHttpClient: CVSHttpClient) {
        super(cvsHttpClient);
    }

    getRecord(encryptedRecordId: string): Observable<Record>{
        return this.cvsHttpClient.get(`${this.getEnvironmentBackendBaseUrl()}record/${encryptedRecordId}`);
    }

    saveProtectedClassAndNewMolecularEntity(encryptedRecordId: string, protectedClass: string, newMolecularEntity: string){
        return this.cvsHttpClient.post(`${this.getEnvironmentBackendBaseUrl()}record/save/protectedClassAndNewMolecular`, {
            encryptedRecordId,
            protectedClass,
            newMolecularEntity
        });
    }

    updateStatus(
        encryptedRecordIds: string[],
        nextStatus: number,
        approvalDate: Date,
        approvalNotes: string,
        currentStatus: number
    ): Observable<any> {
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}record/updateStatus`, {
            encryptedRecordIds,
            nextStatus : Number(nextStatus),
            approvalDate: convertDateToMidnightISO(approvalDate),
            approvalNotes,
            currentStatus
        });
    }

    getRecordStatusCount(superClientIndex: number): Observable<Map<number, number>>{
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}record/status/count/${superClientIndex}` );
    }

    getProductRecordDetails(encryptedRecordId: string, updateType: string, superClientIndex: number): Observable<Record[]> {
        return this.cvsHttpClient
            // eslint-disable-next-line max-len
            .get(`${this.getEnvironmentBackendBaseUrl()}record/productRecordDetails/${updateType}/${encryptedRecordId}/${superClientIndex}`);
    }

    getApprovalDateAndNotes(encryptedRecordId: string): Observable<TrackingDate[]>{
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}record/approvalDetails/${encryptedRecordId}`);
    }

    saveNote(encryptedRecordId: String, note: String, superclientIndex: number) {
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}record/saveNote/${superclientIndex}`, {
            encryptedRecordId: encryptedRecordId,
            note: note
        });
    }
}

import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {FormularyPageComponent} from './formulary-page/formulary-page.component';
import {ProductOverviewComponent} from './product-overview/product-overview.component';
import {ENVIRONMENT} from './environments/environment';
import {ClientSelectionPageComponent} from './client-selection-page/client-selection-page.component';
import {ReportsPageComponent} from './reports-page/reports-page.component';
import {
    RecommendationsFormComponent
} from './product-overview/recommendations/recommendations-form/recommendations-form.component';
import {DeactivateGuard} from './guards/deactivate.guard';
import {WorkflowComponent} from './workflow/workflow.component';
import {MedispanSettingsComponent} from './workflow/medispan-settings/medispan-settings.component';
import {ConfigurationComponent} from './configuration/configuration.component';

const ROUTES: Routes = [
    {path: '', redirectTo: 'clientSelection', pathMatch: 'full'},
    {
        path: 'formulary',
        children: [{
            path: 'formularySearch',
            title: 'CLR - Formulary Search',
            component: FormularyPageComponent,
            data: {mode: 'formularySearch'},
        }, {
            path: 'drugSearch',
            title: 'CLR - Drug Search',
            component: FormularyPageComponent,
            data: {mode: 'drugSearch'},
        }]
    },
    {path: 'clientSelection', component: ClientSelectionPageComponent, title: 'CLR - Client Selection'},
    {path: 'productOverview', component: ProductOverviewComponent, title: 'CLR - Product Overview'},

    {
        path: 'workflow', title: 'CLR - Workflow', children: [
            {path: '', redirectTo: 'medispan', pathMatch: 'full'},
            {
                path: 'medispan', children: [
                    {path: '', redirectTo: 'currentReport', pathMatch: 'full'},
                    {path: 'currentReport', component: WorkflowComponent},
                    {path: 'previousReport', component: WorkflowComponent}
                ]
            },
            {
                path: 'research', children: [
                    {path: '', redirectTo: 'inResearch', pathMatch: 'full'},
                    {path: 'inResearch', component: WorkflowComponent},
                    {path: 'readyForClinicalR', component: WorkflowComponent},
                    {path: 'readyForDiscussion', component: WorkflowComponent}
                ]
            },
            {
                path: 'approvalTracking', children: [
                    {path: '', redirectTo: 'pendingUM', pathMatch: 'full'},
                    {path: 'pendingUM', component: WorkflowComponent},
                    {path: 'pendingFormulary', component: WorkflowComponent},
                    {path: 'pendingPT', component: WorkflowComponent},
                    {path: 'pendingSubmission', component: WorkflowComponent}
                ]
            },
            {
                path: 'operations', children: [
                    {path: '', redirectTo: 'operationsCheckpoint', pathMatch: 'full'},
                    {path: 'operationsCheckpoint', component: WorkflowComponent},
                    {path: 'pendingCoding', component: WorkflowComponent},
                    {path: 'pendingTesting', component: WorkflowComponent},
                    {path: 'pendingApproval', component: WorkflowComponent},
                ]
            },
            {path: 'noAction', component: WorkflowComponent},
            {path: 'archive', component: WorkflowComponent}
        ]
    },
    {path: 'reports', component: ReportsPageComponent, title: 'CLR - Reports'},
    {
        path: 'configuration', component: ConfigurationComponent, title: 'CLR - Client Configuration',
        canDeactivate: [DeactivateGuard]
    },
    {
        path: 'myPBM',
        component: FormularyPageComponent,
        resolve: {
            url: 'externalUrlRedirectResolver'
        },
        data: {
            externalUrl: ENVIRONMENT.myPBMSiteURL
        },
        title: 'CLR - MyPBM'
    },
    {
        path: 'recommendationsForm',
        component: RecommendationsFormComponent,
        canDeactivate: [DeactivateGuard]
    },
    {
        path: 'medispanSettings',
        component: MedispanSettingsComponent,
        title: 'CLR - Medispan Settings',
        canDeactivate: [DeactivateGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(ROUTES)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

import {Injectable} from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {Observable} from 'rxjs';
import {SuperClient} from '../../model/SuperClient';
import {HttpDataCache} from '../HttpDataCache';

@Injectable({
    providedIn: 'root'
})
export class ClientSelectionService extends HttpDataCache{


    constructor(private cvsHttpClient: CVSHttpClient) {
        super(cvsHttpClient);
    }

    getSuperClientNameAndDescription(): Observable<SuperClient[]> {
        return this.cvsHttpClient.get(`${this.getEnvironmentBackendBaseUrl()}superClient/description`);
    }
}

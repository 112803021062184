import {ChangeDetectorRef, Component, OnInit, QueryList, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {MatDatepicker} from '@angular/material/datepicker';
import {ICellEditorAngularComp} from '@ag-grid-community/angular';

@Component({
    selector: 'app-custom-date-picker',
    templateUrl: './custom-date-picker.component.html',
    styleUrls: ['./custom-date-picker.component.scss']
})
export class CustomDatePickerComponent implements OnInit, ICellEditorAngularComp {

    @ViewChild('picker', {read: MatDatepicker}) picker: MatDatepicker<Date>;
    params: any;
    inputDate = '';
    minDate: Date;
    maxDate: Date;

    public inputs: QueryList<any>;

    constructor(private cdr: ChangeDetectorRef) {
        const today = new Date();
        this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    }

    ngOnInit() {
    }

    agInit(params: any): void {
        this.params = params;
        this.maxDate = new Date(moment(params.data.criterion[0].programThruDate).format('YYYY-MM-DD'));
        this.maxDate.setMinutes(this.maxDate.getTimezoneOffset());
    }

    getValue() {
        return moment(this.inputDate).toISOString();
    }

    // tslint:disable-next-line:use-lifecycle-interface
    // ngAfterViewInit() {
    //   this.picker.open();
    //   this.cdr.detectChanges();
    // }

    isPopup(): boolean {
        return true;
    }

    datePickerClear() {
        this.params.api.clearFocusedCell();
    }
}

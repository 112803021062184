import {AfterViewInit, Component, EventEmitter, Injectable, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatSelectionList} from '@angular/material/list';
import {Observable, Subject, timer} from 'rxjs';
import {CVSConfirmationDialogContentComponent} from 'angular-component-library';
import {CVSConfirmationDialogData} from 'angular-component-library/completed/confirmation-dialog/confirmation-dialog-content.component';
import {take} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';

export interface MultiSelectListItem {
    name: string;
    value: string;
}

@Component({
    selector: 'app-multi-select-modal',
    templateUrl: './multi-select-modal.component.html',
    styleUrls: ['./multi-select-modal.component.scss']
})
export class MultiSelectModalComponent implements OnInit, AfterViewInit {

    @ViewChild('matSelectionList') matSelectionList: MatSelectionList;
    isSelectAllChanging = false;

    constructor(private multiSelectModalService: MultiSelectModalService) {
        this.multiSelectModalService.onReady.pipe(take(1)).subscribe(() => {
            this.setScreenElements(true);
        });
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.setScreenElements(true);
    }

    selectionChange(e) {
        this.setConfirmationDisable(!(this.matSelectionList.selectedOptions.selected.length - 1 >= 0));
        if (this.matSelectionList.options.length - this.matSelectionList.selectedOptions.selected.length === 1) {
            this.isSelectAllChanging = true;
            this.matSelectionList.options.first.selected = e.options.selected;
            this.isSelectAllChanging = false;
        }
        this.multiSelectModalService.setSelected(
            this.matSelectionList.options.toArray()
                .filter(o => o.selected && o.value !== undefined)
                .flatMap(m => m.value));
    }

    selectAllChange(newCheckValue) {
        if (!this.isSelectAllChanging) {
            if (newCheckValue === true) {
                this.matSelectionList.selectAll();
            } else {
                this.matSelectionList.deselectAll();
            }
            this.setConfirmationDisable(!newCheckValue);
        }
    }

    setScreenElements(disableConfirmation: boolean) {
        timer(5).pipe(take(1)).subscribe(() => {
            this.setConfirmationDisable(disableConfirmation);
            this.matSelectionList.focus();
        });
    }

    setConfirmationDisable(disable: boolean) {
        try {
            const theButton = document.getElementsByClassName('mat-raised-button')[0] as HTMLButtonElement;
            theButton.disabled = disable;
        } catch (e) {}
    }
}

@Injectable({
    providedIn: 'root'
})
export class MultiSelectModalService {
    bodyMessage: string;
    listItems: MultiSelectListItem[];
    selectedList: string[];
    onReady = new EventEmitter<any>();

    constructor(private _dialog: MatDialog) {
    }

    show(copyPasteModalRef: TemplateRef<any>,
        headline: string,
        bodyMessage: string,
        confirmationButtonText: string,
        listItems: MultiSelectListItem[]): Observable<string[]> {
        const dialogResponse = new Subject<string[]>();
        this.listItems = listItems;
        this.bodyMessage = bodyMessage;
        if (listItems.length === 0) {
            dialogResponse.next(undefined);
            return;
        }
        const dialogRef = this._dialog.open(CVSConfirmationDialogContentComponent, { disableClose: true,
            data: {
                headline: headline,
                actionLabel: confirmationButtonText,
                cancelLabel: 'Cancel',
                templatePortalContent: copyPasteModalRef,
            } as CVSConfirmationDialogData
        }).componentInstance as CVSConfirmationDialogContentComponent;
        dialogRef.onConfirmClick.pipe(take(1))
            .subscribe(() => dialogResponse.next(this.selectedList));
        dialogRef.onCancelClick.pipe(take(1)).subscribe(() => dialogResponse.next(undefined));
        dialogRef.dialogRef.afterOpened().pipe(take(1))
            .subscribe(() => this.onReady.emit());
        return dialogResponse;
    }

    setSelected(itemValues: string[]) {
        this.selectedList = itemValues;
    }
}

import {Injectable} from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {ENVIRONMENT} from '../../environments/environment';
import {Observable} from 'rxjs';
import {RecommendationReportRequest} from '../../model/RecommendationReportRequest';

@Injectable({
    providedIn: 'root'
})
export class ReportService {

    constructor(private cvsHttpClient: CVSHttpClient) {
    }

    submitReport(index: number,
        formularyNumber: string,
        reportType: string,
        fromDate: string,
        thruDate: string): Observable<any> {
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}report/submit/${index}`, {
            'formularyNumber': formularyNumber,
            'reportType': reportType,
            'fromDate': fromDate,
            'thruDate': thruDate
        });
    }

    loadSubmittedReportInformation() {
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}report/reportInformation`);
    }

    downloadReport(downloadLink: string) {
        return this.cvsHttpClient.downloadFilePost(`${ENVIRONMENT.backendBaseUrl}report/download`, {downloadLink});
    }

    getRecommendationReport(superClientIndex: number, recommendationReportRequest: RecommendationReportRequest[]) {
        return this.cvsHttpClient.downloadFilePost(`${ENVIRONMENT.backendBaseUrl}report/recommendationReport/${superClientIndex}`,
            recommendationReportRequest);
    }

    getClientSetupReport(superClientIndex: number) {
        return this.cvsHttpClient.downloadFile(`${ENVIRONMENT.backendBaseUrl}report/clientSetupReport/${superClientIndex}`);
    }
}

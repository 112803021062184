import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ColDef, GridOptions} from '@ag-grid-community/core';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {KabobCellComponent} from '../../common/kabob-cell/kabob-cell.component';
import {AppService} from '../../service/app/app.service';
import {FormularyService} from '../../service/formulary/formulary.service';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {take} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {CVSConfirmationDialogContentComponent} from 'angular-component-library';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-formulary-requirements',
    templateUrl: './formulary-requirements.component.html',
    styleUrls: ['./formulary-requirements.component.scss']
})
export class FormularyRequirementsComponent implements OnInit {
    @Input() isUpdated: boolean;
    @Input() lobLastToggled: string;
    @ViewChild('formularyRequirementsFormRef') public formularyRequirementsFormRef: TemplateRef<any>;
    formularyRequirementsModalRef: MatDialogRef<CVSConfirmationDialogContentComponent, any>;
    subscriptions: Subscription[] = [];
    currentDrugPosition;
    formularies;
    clientName;
    modules = [SetFilterModule, ColumnsToolPanelModule, ClientSideRowModelModule,
        MenuModule, ClipboardModule];
    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
    };

    formularyRuleGridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: [
            {headerName: 'Action', field: 'kabob', cellRenderer: KabobCellComponent, pinned: 'left', width: 100},
            {headerName: 'Formulary Requirement Name', field: 'formularyName', filter: 'agSetColumnFilter', pinned: 'left', width: 200},
            {headerName: 'Formulary Requirement Description', field: '', filter: 'agSetColumnFilter', width: 230},
            {headerName: 'Formulary Coverage Category', field: '', filter: 'agSetColumnFilter', width: 220},
            {headerName: 'Formulary Requirement View Date Range', field: '', filter: 'agSetColumnFilter', width: 250},
            {headerName: 'Formulary Requirement Effective Date Range', field: '', filter: 'agSetColumnFilter', width: 270},
            {headerName: 'Coding Setup', field: '', filter: 'agSetColumnFilter', width: 150},
        ],
        enableCellTextSelection: true,
        suppressLoadingOverlay: true,
    };

    overlayNoRowsTemplate = `<span><img src="/assets/error-f--s.svg" width="25px" height="25px"></br><b>No results found.</b></span>`;
    popupParent: HTMLElement | null = document.querySelector('body');
    confirmationDialog: MatDialogRef<CVSConfirmationDialogContentComponent, any>;

    constructor(private _appService: AppService, private _formularyService: FormularyService, private matDialog: MatDialog,
    ) {}
    ngOnInit(): void {}

    onBusinessLobTabSelectionChange(event: MatTabChangeEvent) {
        const businessLobCd = event.tab.ariaLabel;
        this.getFormularyData(businessLobCd);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    getFormularyData(businessLobCd: string) {
        this.formularies = [];
        this._appService.getInitializationSubject().pipe(take(1)).subscribe(() => {
            const index = this._appService.getSuperClientContext().id;
            this.subscriptions.push(this._formularyService.getClientFormularies(index).pipe(take(1)).subscribe(formularyResponse => {
                formularyResponse?.forEach((formulary) => {
                    if (formulary.businessLobCode === businessLobCd && formulary.showFormulary === 'Y') {
                        this.formularies.push(formulary);
                    }
                });
            }));
        });
    }

    openDialog(formularyRequirementsFormRef: TemplateRef<any>, formulary: any) {
        this.formularyRequirementsModalRef = this.matDialog.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: `Create a Formulary Requirement Rule for: ${formulary.formularyId} - ${formulary.formularyName}`,
                templatePortalContent: formularyRequirementsFormRef
            },
            disableClose: true,
            panelClass: 'formulary-requirements-modal'
        });

        this.formularyRequirementsModalRef.componentInstance.onCancelClick.subscribe(() => {
            this.formularyRequirementsModalRef.close();
        });
    }

    closeModal(isCreated: boolean) {
        if (isCreated) {
            this.formularyRequirementsModalRef.close();
        } else {
            this.formularyRequirementsModalRef.close();
        }
    }
}

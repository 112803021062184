import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {WorkflowService} from '../../service/workflow/workflow.service';
import {RECORD_STATUS, RECORD_STATUSES} from '../../enum/RecordStatus';
import {PDSInlineCalloutType} from 'angular-component-library';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {FORMULARY_DATE_BOUNDARY} from '../../enum/FormularyDateBoundary';

@Component({
    selector: 'app-move-in-workflow-modal',
    templateUrl: './move-in-workflow-modal.component.html',
    styleUrls: ['./move-in-workflow-modal.component.scss']
})
export class MoveInWorkflowModalComponent implements OnInit {
    RECORD_STATUS = RECORD_STATUS;
    dateValidation!: boolean;
    hasTruncNotesError = false;
    notesValue='';
    workflowForm: FormGroup;
    messageText = ' All formulary recommendations are read-only outside of Research ' +
        '& Recommendations. If you wish to make edits in the future, move \n' +
        'in workflow to Research & Recommendations.';
    inlineCalloutType = PDSInlineCalloutType;
    hidden: boolean;
    minDate = FORMULARY_DATE_BOUNDARY.MINIMUM_DATE;
    maxDate = FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE;
    startDate = new Date(new Date().setDate(new Date().getDate()));

    constructor(public workflowService: WorkflowService, private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.dateValidation = this.workflowService.dialogData.approvalDateLabel !== 'Discussion Approval Date';
        this.hidden = this.workflowService.currentStatus !== RECORD_STATUSES[3].description;
        this.workflowForm = this.fb.group({
            approvalDate: [this.workflowService.approvalDetails.approvalDate],
            approvalNotes: [this.workflowService.approvalDetails.approvalNotes],
        });
        this.resetModalFields();
    }

    resetModalFields() {
        if (!this.dateValidation) {
            this.workflowForm.controls['approvalDate'].removeValidators([Validators.required]);
            this.workflowForm.controls['approvalDate'].updateValueAndValidity({onlySelf: true});
        }
    }

    saveWorkflowForm() {
        this.addWorkflowModalValidators();
        this.workflowForm.markAllAsTouched();
        if (!this.workflowForm.controls['approvalDate'].errors?.required) {
            this.workflowService.onConfirmModal(this.workflowForm.value);
        }
    }

    onNotesChanges() {
        if (this.workflowForm.controls['approvalNotes'].value?.length > 250) {
            if (this.notesValue.length === 0) {
                this.hasTruncNotesError = true;
            }
            this.workflowForm.controls['approvalNotes'].setValue(this.workflowForm.controls['approvalNotes'].value?.substring(0, 250));
        } else {
            this.hasTruncNotesError = false;
        }
        this.notesValue = this.workflowForm.controls['approvalNotes'].value;
    }

    stopWarning() {
        this.hasTruncNotesError = false;
    }

    private addWorkflowModalValidators() {
        if (!this.dateValidation) {
            this.workflowForm.controls['approvalDate'].addValidators([Validators.required]);
            this.workflowForm.controls['approvalDate'].updateValueAndValidity({onlySelf: true});
        }
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {ColDef, FirstDataRenderedEvent, GridOptions, RowDataUpdatedEvent} from '@ag-grid-community/core';
import {WorkflowResponse} from '../../model/WorkflowResponse';
import {
    dateFormatter,
    dateTruncation,
    gridHyperLinkSelector,
    gridHyperLinkSelectorByUpdateType
} from '../../utility/utility';
import {PRODUCT_OVERVIEW_SOURCE} from '../../enum/ProductOverviewSource';
import {TrackerDataService} from '../../service/tracker-data/tracker-data.service';
import {AppService} from '../../service/app/app.service';
import {WorkflowService} from '../../service/workflow/workflow.service';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {ExcelExportModule} from '@ag-grid-enterprise/excel-export';
import {finalize, take} from 'rxjs/operators';
import {RECORD_STATUS} from '../../enum/RecordStatus';
import {getUpdateTypeDisplayNameForWorkflow} from '../../mapper/hierarchyUpdateType';
import { WORKFLOW_TABS } from '../WorkflowTab';
import moment from 'moment';
@Component({
    selector: 'app-workflow-archive-list',
    templateUrl: './workflow-archive-list.component.html',
    styleUrls: ['./workflow-archive-list.component.scss']
})
export class WorkflowArchiveListComponent implements OnInit {
    @Input() mainTabName = '';
    @Input() navigatedFromLink = '';
    statuses = [RECORD_STATUS.ARCHIVE];
    modules = [SetFilterModule, ColumnsToolPanelModule, ClientSideRowModelModule,
        MenuModule, ClipboardModule, ExcelExportModule];

    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
        suppressSizeToFit: true,
        cellClass: 'excelString'
    };

    subscriptions = [];
    workflowArchiveData: WorkflowResponse[] = [];
    filteredWorkFlowArchiveData: WorkflowResponse[] = [];
    popupParent: HTMLElement | null = document.querySelector('body');
    isLoading = false;

    columnDefs: ColDef[] = [
        {
            headerName: 'Date', field: 'weekDate', valueGetter: params => dateTruncation(params.data.weekDate),
            valueFormatter: params => dateFormatter(params.data.weekDate), filter: 'agSetColumnFilter', width: 130,
        },
        {
            headerName: 'Update Type', field: 'updateType', filter: 'agSetColumnFilter', width: 195,
            valueGetter:  (params) => getUpdateTypeDisplayNameForWorkflow(params.data.updateType),
            filterParams: {
                comparator: (value1, value2) => {
                    if (value1.hierarchy === value2.hierarchy) {
                        return 0;
                    }
                    return value1.hierarchy > value2.hierarchy ? 1 : -1;
                }
            }
        },
        {
            headerName: 'GPI', field: 'gpi', filter: 'agSetColumnFilter', width: 150,
            cellRendererSelector: (params) => gridHyperLinkSelectorByUpdateType(params.data.updateType),
            cellRendererParams: {
                source: PRODUCT_OVERVIEW_SOURCE.WORKFLOW_ARCHIVE,
                mainTabName: this.mainTabName,
                subTabIndex: undefined,
                navigatedFromLink: this.navigatedFromLink
            }
        },
        {headerName: 'GPI Name', field: 'gpiName', filter: 'agSetColumnFilter', width: 200},
        {
            headerName: 'NDC', field: 'ndc', filter: 'agSetColumnFilter', width: 150,
            cellRendererSelector: (params) => gridHyperLinkSelector(params.data.ndc, 'N/A'),
            cellRendererParams: {
                source: PRODUCT_OVERVIEW_SOURCE.WORKFLOW_ARCHIVE,
                mainTabName: this.mainTabName,
                subTabIndex: undefined,
                navigatedFromLink: this.navigatedFromLink
            },
        },
        {headerName: 'Label Name', field: 'labelName', filter: 'agSetColumnFilter', width: 200},
        {headerName: 'Line of Business', field: 'businessLob', filter: 'agSetColumnFilter', width: 140},
        {
            headerName: 'Notes', field: 'note', filter: 'agSetColumnFilter', width: 200,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorParams: {cols: 80, maxLength: 5000},
        }];

    workflowArchiveGridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: this.columnDefs,
        enableCellTextSelection: true,
        excelStyles: [{
            id: 'excelString',
            dataType: 'String'
        }],
        defaultExcelExportParams: {
            sheetName: undefined
        }
    };
    overlayNoRowsTemplate = `<span><img src="/assets/error-f--s.svg" width="25px" height="25px"></br><b>No results found.</b></span>`;
    superClientIndex;
    gridHeight: string;

    endDate = new Date();
    startDate = moment().subtract(29, 'days').toDate();
    maxDate = new Date();
    dateRangeError = 'Error: Provide a valid date range within 01/01/1995 to today\'s date.';

    constructor(private trackerDataService: TrackerDataService,
        private appService: AppService,
        private workflowService: WorkflowService) {
    }

    ngOnInit(): void {
        this.init();
    }

    init() {
        this.columnDefs.find(cd => cd.field === 'ndc').cellRendererParams.mainTabName = this.mainTabName;
        this.columnDefs.find(cd => cd.field === 'ndc').cellRendererParams.navigatedFromLink = this.navigatedFromLink;
        this.columnDefs.find(cd => cd.field === 'gpi').cellRendererParams.mainTabName = this.mainTabName;
        this.columnDefs.find(cd => cd.field === 'gpi').cellRendererParams.navigatedFromLink = this.navigatedFromLink;
        this.subscribeToWorkflowMessage();
        this.superClientIndex = this.appService.getSuperClientContext().id;
        this.getWorkflowData();
    }

    getWorkflowData() {
        this.isLoading = true;
        this.workflowArchiveGridOptions.suppressNoRowsOverlay = true;
        this.trackerDataService.getWorkflowData(RECORD_STATUS.ARCHIVE, this.superClientIndex).pipe(
            take(1),
            finalize(() => this.isLoading = false)
        ).subscribe(
            response => {
                if (response) {
                    this.workflowArchiveData = response;
                    this.filterByDate();
                }
                this.workflowArchiveGridOptions.suppressNoRowsOverlay = false;
                this.workflowArchiveGridOptions.api.showNoRowsOverlay();
            });
        this.workflowArchiveGridOptions.defaultExcelExportParams.sheetName = WORKFLOW_TABS.TAB5ARCHIVE.name;
    }

    setGridRowHeight(rows: number) {
        if (rows <= 20) {
            this.workflowArchiveGridOptions.domLayout = 'autoHeight';
            this.gridHeight = '';
        } else {
            this.gridHeight = '62.6vh';
        }
    }

    onFirstDataRendered(event: FirstDataRenderedEvent) {
        this.setGridRowHeight(event.api.getDisplayedRowCount());
    }

    onRowDataUpdated(event: RowDataUpdatedEvent) {
        this.setGridRowHeight(event.api.getDisplayedRowCount());
    }

    subscribeToWorkflowMessage() {
        this.subscriptions.push(this.workflowService.getMessageProvider().subscribe(workflowMessage => {
            this.workflowService.processWorkflowMessage(this.mainTabName, 0, this.statuses,
                this.workflowArchiveGridOptions, this.workflowArchiveData,
                workflowMessage, () => this.getWorkflowData());
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(item => item.unsubscribe());
    }

    getDates(event) {
        this.startDate = event[0];
        this.endDate = event[1];
        this.filterByDate();
    }

    filterByDate() {
        this.filteredWorkFlowArchiveData = this.workflowArchiveData
            .filter(item => moment(item.weekDate).isSameOrAfter(moment(this.startDate)) &&
                moment(item.weekDate).isSameOrBefore(moment(this.endDate)));
    }
}

import {BUSINESS_LOB} from '../../../enum/BusinessLob';
import {ValidatorFn, Validators} from '@angular/forms';
import {NA, PA_SELECTION_MEDICARE} from '../../../default-values/DefaultValues';

export interface IRecommendationRules {
    [name: string]: (IValueRule | IOtherwiseRule)[];
}

export interface IOptions {
    disabled: boolean;
    value?: string | string[];
    validators?: ValidatorFn[];
    updateOn?: string;
}

export interface IGroup {
    controls: string[];
    opts: IOptions;
}

export interface IValueRule extends IBlobGroup {
    values: (undefined | string)[];
}

export interface IOtherwiseRule {
    otherwise?: IBlobGroup;
}

export interface IBlobGroup {
    bLobs?: string[];
    groups: IGroup[];
}

export function recommendationRules(blob: string): IRecommendationRules  {
    return {
        attachmentType: [
            {
                values: [null, undefined, 'GPI'],
                groups: [
                    { controls: ['attachmentMonyDD'], opts: { disabled: false, validators: [Validators.required] } },
                    { controls: ['monyCode'], opts: { disabled: true, value: NA, validators: [] } }
                ]
            },
            {
                values: ['NDC'],
                groups: [
                    { controls: ['attachmentMonyDD'], opts: { disabled: true, value: null, validators: [] } },
                    { controls: ['monyCode'], opts: { disabled: true, value: NA, validators: [] } }
                ]
            }
        ],
        tier: [
            {
                values: ['Non-Formulary',
                    'Part B Pay at POS',
                    'Part A/B Deny as POS',
                    'Excluded Medical Benefit',
                    'Excluded OTC',
                    'Excluded'],
                groups: [{
                    controls: ['coverageMiscNotCoveredMessageDescription'],
                    opts: { disabled: false, value: null, validators: [] }
                }],
            },
            {
                otherwise: {
                    groups: [
                        {
                            controls: ['coverageMiscNotCoveredMessageDescription'],
                            opts: { disabled: true, value: NA, validators: [] }
                        }
                    ]
                }
            }
        ],
        qlType: [
            {
                values: [undefined, NA, null, '0'],
                groups: [
                    { controls: ['qlAmount', 'qlUnit', 'qQPPD'], opts: { disabled: true, value: NA } }
                ],
            },
            {
                otherwise: {
                    groups: [
                        {
                            controls: ['qlAmount'],
                            opts: {
                                disabled: false,
                                value: '',
                                validators: [
                                    Validators.pattern(/^\d+(\.\d{1,4})?$/),
                                    Validators.required
                                ]
                            }
                        },
                        {
                            controls: ['qlUnit'],
                            opts: {
                                disabled: false,
                                value: '',
                                validators: [Validators.required]
                            }
                        },
                        {
                            controls: ['qQPPD'],
                            opts: {
                                disabled: false,
                                value: '',
                                validators: [
                                    Validators.pattern(/^[0-9]+$/),
                                    Validators.required
                                ]
                            }
                        }
                    ]
                }
            }
        ],
        qlTypeA: [
            {
                values: [undefined, NA, null, '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
                groups: [
                    { controls: ['qlTypeB', 'qlTypeC'], opts: { disabled: true, value: NA } },
                    {
                        controls: ['qlAmountA', 'qlUnitA', 'qQPPDA', 'qlAmountB', 'qlUnitB', 'qQPPDB', 'qlAmountC', 'qlUnitC', 'qQPPDC'],
                        opts: { disabled: true, value: NA }
                    }
                ]
            },
            {
                otherwise: {
                    groups: [
                        {
                            controls: ['qlTypeB'],
                            opts: { disabled: false, value: NA }
                        },
                        {
                            controls: ['qlAmountA'],
                            opts: {
                                disabled: false,
                                value: '',
                                validators: [
                                    Validators.pattern(/^\d+(\.\d{1,4})?$/),
                                    Validators.required
                                ]
                            }
                        },
                        {
                            controls: ['qlUnitA'],
                            opts: {
                                disabled: false,
                                value: '',
                                validators: [Validators.required]
                            }
                        },
                        {
                            controls: ['qQPPDA'],
                            opts: {
                                disabled: false,
                                value: '',
                                validators: [
                                    Validators.pattern(/^[0-9]+$/),
                                    Validators.required
                                ]
                            }
                        }
                    ]
                }
            }
        ],
        qlTypeB: [
            {
                values: [undefined, NA, null, '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
                groups: [
                    { controls: ['qlTypeC'], opts: { disabled: true, value: NA } },
                    {
                        controls: ['qlAmountB', 'qlUnitB', 'qQPPDB', 'qlAmountC', 'qlUnitC', 'qQPPDC'],
                        opts: { disabled: true, value: NA }
                    }
                ]
            },
            {
                otherwise: {
                    groups: [
                        { controls: ['qlTypeC'], opts: { disabled: false, value: NA } },
                        {
                            controls: ['qlAmountB'],
                            opts: {
                                disabled: false,
                                value: '',
                                validators: [
                                    Validators.pattern(/^\d+(\.\d{1,4})?$/),
                                    Validators.required
                                ]
                            }
                        },
                        {
                            controls: ['qlUnitB'],
                            opts: {
                                disabled: false,
                                value: '',
                                validators: [Validators.required]
                            }
                        },
                        {
                            controls: ['qQPPDB'],
                            opts: {
                                disabled: false,
                                value: '',
                                validators: [
                                    Validators.pattern(/^[0-9]+$/),
                                    Validators.required
                                ]
                            }
                        }
                    ]
                }
            }
        ],
        qlTypeC: [
            {
                values: [undefined, NA, null, '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
                groups: [
                    { controls: ['qlAmountC', 'qlUnitC', 'qQPPDC'], opts: { disabled: true, value: NA } }
                ]
            },
            {
                otherwise: {
                    groups: [
                        {
                            controls: ['qlAmountC'],
                            opts: {
                                disabled: false,
                                value: '',
                                validators: [
                                    Validators.pattern(/^\d+(\.\d{1,4})?$/),
                                    Validators.required
                                ]
                            }
                        },
                        {
                            controls: ['qlUnitC'],
                            opts: {
                                disabled: false,
                                value: '',
                                validators: [Validators.required]
                            }
                        },
                        {
                            controls: ['qQPPDC'],
                            opts: {
                                disabled: false,
                                value: '',
                                validators: [
                                    Validators.pattern(/^[0-9]+$/),
                                    Validators.required
                                ]
                            }
                        }
                    ]
                }
            }
        ],
        ageLimit: [
            {
                values: [undefined, NA, '4', '0'],
                groups: [
                    {
                        controls: ['ageLimitMin', 'ageLimitMax', 'ageLimitMinValue', 'ageLimitMaxValue'],
                        opts: { disabled: true, value: NA }
                    }
                ]
            },
            {
                values: ['1'],
                groups: [
                    {
                        controls: ['ageLimitMin', 'ageLimitMinValue'],
                        opts: { disabled: false, value: '', validators: [Validators.required] }
                    },
                    {
                        controls: ['ageLimitMax', 'ageLimitMaxValue'],
                        opts: { disabled: true, value: NA }
                    }
                ]
            },
            {
                values: ['2'],
                groups: [
                    {
                        controls: ['ageLimitMax', 'ageLimitMaxValue'],
                        opts: { disabled: false, value: '', validators: [Validators.required] }
                    },
                    {
                        controls: ['ageLimitMin', 'ageLimitMinValue'],
                        opts: { disabled: true, value: NA }
                    },
                ]
            },
            {
                otherwise: {
                    groups: [
                        {
                            controls: ['ageLimitMin', 'ageLimitMax', 'ageLimitMinValue', 'ageLimitMaxValue'],
                            opts: { disabled: false, value: '', validators: [Validators.required] }
                        }
                    ]
                }
            }
        ],
        stepType: [
            {
                values: blob === BUSINESS_LOB.MEDICARE ? [undefined, NA, '0'] : [undefined, NA, '2'],
                groups: [
                    {
                        controls: ['stepName'],
                        opts: { disabled: true, value: NA }
                    }
                ]
            },
            {
                values: blob === BUSINESS_LOB.MEDICARE ? ['1', '2'] : ['1'],
                groups: [
                    {
                        controls: ['stepName'],
                        opts: { disabled: false, value: '', validators: [Validators.maxLength(100)] }
                    }
                ]
            }
        ],
        paSelection: [{
            values: ['1'],
            groups: [
                { controls: ['paGroupName'], opts: { disabled: false, value: null ,
                    validators: [blob=== BUSINESS_LOB.MEDICARE ? Validators.required : Validators.nullValidator]} }
            ]
        },
        {
            values: [PA_SELECTION_MEDICARE.ONE.code, PA_SELECTION_MEDICARE.TWO.code,
                PA_SELECTION_MEDICARE.ONEPLUSTHREE.code, PA_SELECTION_MEDICARE.TWOPLUSTHREE.code],
            bLobs: [BUSINESS_LOB.MEDICARE],
            groups: [
                {
                    controls: ['paGroupName'],
                    opts: { disabled: false, value: null, validators: [Validators.required] }
                }]
        },
        {
            values: [PA_SELECTION_MEDICARE.THREE.code],
            groups: [
                {
                    controls: ['paGroupName'],
                    opts: { disabled: true, value: 'B vs D' }
                }
            ]
        },
        {
            otherwise: {
                groups: [
                    {
                        controls: ['paGroupName'],
                        opts: { disabled: true, value: NA, }
                    }
                ]
            }
        }
        ]
    };
}
